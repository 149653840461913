import React from "react";
import Header from "../Header/Header";
import THeader from "../THeader/THeader";

const Errorpage = () => {
  return (
    <div>
      <THeader />
      <Header />
      <div className="Coming_Soon_Container">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="90"
          height="55"
          viewBox="0 0 90 55"
          fill="none"
          className="wifi-symbol"
        >
          <path
            d="M53.8493 48.5889L45.2216 54.7806L36.5479 48.656C37.5245 47.273 38.8177 46.1436 40.3195 45.3619C41.8213 44.5802 43.4882 44.169 45.1813 44.1624C46.8743 44.1559 48.5444 44.5542 50.0523 45.3242C51.5601 46.0942 52.862 47.2136 53.8493 48.5889Z"
            fill="#0F0"
          />
          <path
            d="M45.2215 29.0757C41.1035 29.0759 37.046 30.0679 33.3925 31.9678C29.7389 33.8677 26.5966 36.6196 24.2314 39.9907L29.7088 43.8364C31.4526 41.3596 33.7642 39.3365 36.4503 37.9364C39.1364 36.5364 42.1187 35.8001 45.1478 35.7893C48.1768 35.7784 51.1645 36.4932 53.8605 37.874C56.5566 39.2547 58.8827 41.2612 60.6441 43.7254L66.0901 39.8127C63.716 36.4925 60.5843 33.7865 56.9549 31.9192C53.3255 30.0518 49.3031 29.077 45.2215 29.0757Z"
            fill="#0F0"
          />
          <path
            d="M45.2219 0.402474C26.871 0.402474 10.642 9.52241 0.805664 23.4704L6.28308 27.3161C10.6611 21.0971 16.4649 16.0171 23.2088 12.5012C29.9528 8.98521 37.4407 7.13559 45.0461 7.10709C52.6515 7.07858 60.1532 8.87201 66.9233 12.3373C73.6935 15.8026 79.5351 20.839 83.9596 27.025L89.3909 23.1122C84.3686 16.0837 77.7404 10.3561 70.0578 6.4061C62.3753 2.45606 53.8605 0.397768 45.2219 0.402474Z"
            fill="#0F0"
          />
          <path
            d="M45.2216 16.087C39.0186 16.0851 32.9055 17.5699 27.3944 20.4169C21.8834 23.2639 17.1349 27.3902 13.5469 32.4501L19.0243 36.2958C21.9871 32.1368 25.8974 28.7427 30.4318 26.3942C34.9661 24.0456 39.9944 22.81 45.1008 22.7895C50.2073 22.7691 55.2453 23.9643 59.7983 26.2765C64.3513 28.5886 68.2887 31.9512 71.2848 36.0864L76.7161 32.1967C73.119 27.2075 68.3874 23.1449 62.9115 20.344C57.4356 17.543 51.3723 16.0839 45.2216 16.087Z"
            fill="#0F0"
          />
        </svg>
        <h2>404!</h2>
        <p>Page Not Found</p>
      </div>
    </div>
  );
};

export default Errorpage;
