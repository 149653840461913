import { useState } from "react";
import { Helmet } from "react-helmet";
import "./Applypage.css";
import logo1 from "../assets/Techreadypic_1.svg";
import THeader from "../THeader/THeader";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import logo3 from "../assets/Popup.png";
import logo4 from "../assets/close.svg";
import request from "superagent";

const Applypage = () => {
  const [pin, setNum] = useState("");
  const [mob, setNum1] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [showPopup1, setShowPopup1] = useState(false);
  const [city, setCity] = useState("");
  const [state, setState] = useState("");

  const videoUrls = [
    "https://www.youtube.com/embed/77hhoNE0UK0",
    "https://www.youtube.com/embed/jvExiX2yMBc",
    "https://www.youtube.com/embed/34BaT1z8NXk",
    "https://www.youtube.com/embed/_ZByCYhKNeo",
    "https://www.youtube.com/embed/IJjpQNZQOSc",
    "https://www.youtube.com/embed/WiHFwhGoJm8",
    "https://www.youtube.com/embed/_biGfmuIld4",
    "https://www.youtube.com/embed/l3Ys8UekYWQ",
    "https://www.youtube.com/embed/b6mi9bRIV0o",
  ];

  const handleNumChangeP = (event) => {
    var numericValue = event.target.value.replace(/\D/g, "");
    const limit = 6;
    numericValue = numericValue.slice(0, limit);
    setNum(numericValue);
    const url = `https://api.postalpincode.in/pincode/${numericValue}`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (data[0].Status === "Success") {
          const city = data[0].PostOffice[0].District;
          const state = data[0].PostOffice[0].State;
          setCity(city);
          setState(state);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleNumChangeM = (event) => {
    const numericValue = event.target.value.replace(/\D/g, "");
    const limit1 = 10;
    setNum1(numericValue.slice(0, limit1));
  };

  const handleDownload = () => {
    const element = document.getElementById("Form_Block");
    setShowPopup1(true);
    setTimeout(() => {
      element.scrollIntoView({ behavior: "smooth" });
    }, 2000);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = {
      fname: event.target["firstName"].value,
      lname: event.target["lastName"].value,
      email: event.target["email"].value.toLowerCase(),
      mobile: mob,
      degree: event.target["degree"].value,
      college: event.target["college"].value,
      university: event.target["university"].value,
      stream: event.target["stream"].value,
      addressl1: event.target["addressLine1"].value,
      addressl2: event.target["addressLine2"].value,
      pincode: pin,
      city: event.target["city"].value,
      state: event.target["state"].value,
    };
    setTimeout(() => {
      setShowPopup(true);
      event.target.reset();
      setNum("");
      setNum1("");
      setCity("");
      setState("");
    }, 500);
    request
      .post("https://vigilar.in:4004/ApplyPage/Form/Catalog")
      .send(formData)
      .set("Accept", "application/json")
      .then((response) => {
        console.log(response.body.message);
      })
      .catch((error) => {
        console.error("Error sending email:", error);
      });
  };

  const [currentIndex1, setCurrentIndex1] = useState(0);

  const handlePrevClick = () => {
    setCurrentIndex1((prevIndex) => Math.max(prevIndex - 3, 0));
  };

  const handleNextClick = () => {
    setCurrentIndex1((prevIndex) => Math.min(prevIndex + 3, 6));
  };
  const [currentIndex2, setCurrentIndex2] = useState(0);

  const handlePrevClickM = () => {
    setCurrentIndex2((prevIndex) =>
      prevIndex === 0 ? videoUrls.length - 1 : prevIndex - 1
    );
  };

  const handleNextClickM = () => {
    setCurrentIndex2((prevIndex) =>
      prevIndex === videoUrls.length - 1 ? 0 : prevIndex + 1
    );
  };
  return (
    <div className="Main">
      <Helmet>
        <title>Learn, Grow, Succeed: Online Training Programs- Codingeni</title>
        <meta
          name="description"
          content="Join us today and experience the ease. Learn, grow, and succeed with our online training programs. Transform your career and personal development today.
          "
        />
        <meta
          name="keyword"
          content="Codingeni, online training course in India, online learning platform in India, online course in India, online training course, online learning platform, learning courses, best online coding courses, best online courses platform.
          "
        />
        <link rel="canonical" href="https://www.codingeni.com/apply" />
      </Helmet>
      <THeader />
      <Header />
      <div className="Main">
        <div className="Image_Top">
          <img src={logo1} alt="Class to Cubicle" />
          <h1>Graduated, but how many are Industry ready?</h1>
          <p>
            India has the highest number of engineering colleges, producing
            maximum number of engineers in the world. Estimates say more than
            63% of our young engineering graduates are yet to become work ready,
            primarily due to the lack of skillsets the industry wants.
          </p>
        </div>
        <div className="Mid_Section">
          <h1>&#123; Apply with Codingeni &#125;</h1>

          <h3>
            Hey! We’re happy to know that you wish to take up coding to scale
            the tech world. At Codingeni you’ll be building while you learn with
            our experienced developers with the comfort of online developer-led
            online sessions.
          </h3>
          <h3>
            Take a look at few of the projects our students have worked upon
            which you could also be part of. These are live commercial projects
            and few of them are mission critical, and we’re proud that our
            students could scale to this stage with their coding skills.
          </h3>
          <h3>
            Thanks for interest in applying for Codingeni training with us.
            Please fill in the form below, and one of our Codingeni rep will
            reach you soon.
          </h3>

          <div className="Safepro_Carousel">
            <h1>
              Take a look at few of exciting AI products and projects you’ll be
              part of.
            </h1>
            <div className="carousel-container">
              <div className="Button_Left" onClick={handlePrevClick}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  className={`left-button ${currentIndex1 === 0 ? "hidden" : ""
                    }`}
                >
                  <path
                    className="Arrow_Left"
                    d="M28 19C28.5523 19 29 19.4477 29 20C29 20.5523 28.5523 21 28 21V19ZM11.2929 20.7071C10.9024 20.3166 10.9024 19.6834 11.2929 19.2929L17.6569 12.9289C18.0474 12.5384 18.6805 12.5384 19.0711 12.9289C19.4616 13.3195 19.4616 13.9526 19.0711 14.3431L13.4142 20L19.0711 25.6569C19.4616 26.0474 19.4616 26.6805 19.0711 27.0711C18.6805 27.4616 18.0474 27.4616 17.6569 27.0711L11.2929 20.7071ZM28 21L12 21V19L28 19V21Z"
                    fill="white"
                  />
                  <circle
                    className="Circle"
                    cx="20"
                    cy="20"
                    r="19"
                    transform="matrix(-1 0 0 1 40 0)"
                    stroke="white"
                    stroke-width="2"
                  />
                </svg>
              </div>
              <div className="Image_carousel">
                <div
                  className="carousel-inner"
                  style={{
                    transform: `translateX(-${currentIndex1 * (101 / 9)}%)`,
                  }}
                >
                  <iframe
                    title="Facesense Demo Video"
                    src="https://www.youtube.com/embed/77hhoNE0UK0"
                  ></iframe>
                  <iframe
                    title="Facesense Demo Video"
                    src="https://www.youtube.com/embed/jvExiX2yMBc"
                  ></iframe>
                  <iframe
                    title="Facesense Demo Video"
                    src="https://www.youtube.com/embed/34BaT1z8NXk"
                  ></iframe>
                  <iframe
                    title="Facesense Demo Video"
                    src="https://www.youtube.com/embed/_ZByCYhKNeo"
                  ></iframe>
                  <iframe
                    title="Facesense Demo Video"
                    src="https://www.youtube.com/embed/IJjpQNZQOSc"
                  ></iframe>
                  <iframe
                    title="Facesense Demo Video"
                    src="https://www.youtube.com/embed/WiHFwhGoJm8"
                  ></iframe>
                  <iframe
                    title="Facesense Demo Video"
                    src="https://www.youtube.com/embed/_biGfmuIld4"
                  ></iframe>
                  <iframe
                    title="Facesense Demo Video"
                    src="https://www.youtube.com/embed/l3Ys8UekYWQ"
                  ></iframe>
                  <iframe
                    title="Facesense Demo Video"
                    src="https://www.youtube.com/embed/b6mi9bRIV0o"
                  ></iframe>
                </div>
              </div>
              <div className="Button_Right" onClick={handleNextClick}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  className={`right-button ${currentIndex1 === 6 ? "hidden" : ""
                    }`}
                >
                  <path
                    className="Arrow_Right"
                    d="M12 19C11.4477 19 11 19.4477 11 20C11 20.5523 11.4477 21 12 21V19ZM28.7071 20.7071C29.0976 20.3166 29.0976 19.6834 28.7071 19.2929L22.3431 12.9289C21.9526 12.5384 21.3195 12.5384 20.9289 12.9289C20.5384 13.3195 20.5384 13.9526 20.9289 14.3431L26.5858 20L20.9289 25.6569C20.5384 26.0474 20.5384 26.6805 20.9289 27.0711C21.3195 27.4616 21.9526 27.4616 22.3431 27.0711L28.7071 20.7071ZM12 21L28 21V19L12 19V21Z"
                    fill="white"
                  />
                  <circle
                    className="Circle"
                    cx="20"
                    cy="20"
                    r="19"
                    stroke="white"
                    stroke-width="2"
                  />
                </svg>
              </div>
            </div>
            <div className="carousel-container-mobile">
              <div className="Button_Left" onClick={handlePrevClickM}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  className={`left-button ${currentIndex1 === 0 ? "hidden" : ""
                    }`}
                >
                  <path
                    className="Arrow_Left"
                    d="M28 19C28.5523 19 29 19.4477 29 20C29 20.5523 28.5523 21 28 21V19ZM11.2929 20.7071C10.9024 20.3166 10.9024 19.6834 11.2929 19.2929L17.6569 12.9289C18.0474 12.5384 18.6805 12.5384 19.0711 12.9289C19.4616 13.3195 19.4616 13.9526 19.0711 14.3431L13.4142 20L19.0711 25.6569C19.4616 26.0474 19.4616 26.6805 19.0711 27.0711C18.6805 27.4616 18.0474 27.4616 17.6569 27.0711L11.2929 20.7071ZM28 21L12 21V19L28 19V21Z"
                    fill="white"
                  />
                  <circle
                    className="Circle"
                    cx="20"
                    cy="20"
                    r="19"
                    transform="matrix(-1 0 0 1 40 0)"
                    stroke="white"
                    stroke-width="2"
                  />
                </svg>
              </div>
              <div className="Image_carousel">
                <div
                  className="carousel-inner"
                  style={{
                    transform: `translateX(-${currentIndex2 * (104.5 / 9)}%)`,
                  }}
                >
                  {videoUrls.map((url, index) => (
                    <iframe
                      key={index}
                      title={`Facesense Demo Video ${index}`}
                      src={url}
                    ></iframe>
                  ))}
                </div>
              </div>
              <div className="Button_Right" onClick={handleNextClickM}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  className={`right-button ${currentIndex1 === 6 ? "hidden" : ""
                    }`}
                >
                  <path
                    className="Arrow_Right"
                    d="M12 19C11.4477 19 11 19.4477 11 20C11 20.5523 11.4477 21 12 21V19ZM28.7071 20.7071C29.0976 20.3166 29.0976 19.6834 28.7071 19.2929L22.3431 12.9289C21.9526 12.5384 21.3195 12.5384 20.9289 12.9289C20.5384 13.3195 20.5384 13.9526 20.9289 14.3431L26.5858 20L20.9289 25.6569C20.5384 26.0474 20.5384 26.6805 20.9289 27.0711C21.3195 27.4616 21.9526 27.4616 22.3431 27.0711L28.7071 20.7071ZM12 21L28 21V19L12 19V21Z"
                    fill="white"
                  />
                  <circle
                    className="Circle"
                    cx="20"
                    cy="20"
                    r="19"
                    stroke="white"
                    stroke-width="2"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div className="Brochure_Section">
          <div className="Brochure_Contents">
            <h1>Download our brochure!</h1>
            <button className="Brochure_Button" onClick={handleDownload}>
              Download brochure
            </button>
          </div>
        </div>
        {showPopup1 && (
          <>
            <div className="Overlay" />
            <div className="Popup">
              <img
                src={logo4}
                alt="Close"
                className="CloseButton"
                onClick={() => setShowPopup1(false)}
              />
              <img src={logo3} alt="Popup" />
              <span>
                <h1>We value your interest</h1>
                <br />
                <h3>
                  Please fill this form. The brochure will be mailed to you.
                </h3>
              </span>
            </div>
          </>
        )}
        <div className="Forms">
          <div id="Form_Block" className="Form_Block">
            <br />
            <h1>Student Registration Form</h1>
            <form id="Feedback" onSubmit={handleSubmit}>
              <div className="Inputfield">
                <h3>
                  First Name&nbsp;<span>*</span>
                </h3>
                <label>:</label>
                <div className="InputField_box">
                  <input
                    id="firstName"
                    type="text"
                    className="form-input"
                    required="required"
                  />
                </div>
              </div>
              <div className="Inputfield">
                <h3>
                  Last Name&nbsp;<span>*</span>
                </h3>
                <label>:</label>
                <div className="InputField_box">
                  <input
                    id="lastName"
                    type="text"
                    className="form-input"
                    required="required"
                  />
                </div>
              </div>
              <div className="Inputfield">
                <h3>
                  Email-ID&nbsp;<span>*</span>
                </h3>
                <label>:</label>
                <div className="InputField_box">
                  <input
                    id="email"
                    type="email"
                    className="form-input"
                    required="required"
                  />
                </div>
              </div>
              <div className="Inputfield">
                <h3>
                  Mobile Number&nbsp;<span>*</span>
                </h3>
                <label>:</label>
                <div className="InputField_box">
                  <input
                    id="mobile"
                    type="number"
                    value={mob}
                    minLength={10}
                    onChange={handleNumChangeM}
                    className="form-input"
                    required="required"
                  />
                </div>
              </div>
              <div className="Inputfield">
                <h3>
                  Degree&nbsp;<span>*</span>
                </h3>
                <label>:</label>
                <div className="InputField_box">
                  <input
                    id="degree"
                    type="text"
                    className="form-input"
                    required="required"
                  />
                </div>
              </div>
              <div className="Inputfield">
                <h3>
                  College&nbsp;<span>*</span>
                </h3>
                <label>:</label>
                <div className="InputField_box">
                  <input
                    id="college"
                    type="text"
                    className="form-input"
                    required="required"
                  />
                </div>
              </div>
              <div className="Inputfield">
                <h3>
                  University&nbsp;<span>*</span>
                </h3>
                <label>:</label>
                <div className="InputField_box">
                  <input
                    id="university"
                    type="text"
                    className="form-input"
                    required="required"
                  />
                </div>
              </div>
              <div className="Inputfield">
                <h3>
                  Choose the stream&nbsp;<span>*</span>
                </h3>
                <label>:</label>
                <div className="InputField_box">
                  <select id="stream" className="Selectbox" required="required">
                    <option value="Open to Suggestion" selected disabled hidden>
                      Select stream
                    </option>
                    <option value="Computer Vision Engineer">
                      Computer Vision Engineer
                    </option>
                    <option value="Full Stack Developer">
                      Full Stack Developer
                    </option>
                    <option value="Android Developer">Android Developer</option>
                    <option value="IOS Developer">IOS Developer</option>
                    <option value="UI/UX & Graphic Designer">
                      UI/UX & Graphic Designer
                    </option>
                  </select>
                </div>
              </div>
              <div className="Inputfield">
                <h3>
                  Address Line 1&nbsp;<span>*</span>
                </h3>
                <label>:</label>
                <div className="InputField_box">
                  <input
                    id="addressLine1"
                    type="text"
                    className="form-input"
                    required="required"
                  />
                </div>
              </div>
              <div className="Inputfield">
                <h3>
                  Address Line 2&nbsp;<span>*</span>
                </h3>
                <label>:</label>
                <div className="InputField_box">
                  <input
                    id="addressLine2"
                    type="text"
                    className="form-input"
                    required="required"
                  />
                </div>
              </div>
              <div className="Inputfield">
                <h3>
                  Pincode&nbsp;<span>*</span>
                </h3>
                <label>:</label>
                <div className="InputField_box">
                  <input
                    type="number"
                    value={pin}
                    minLength={6}
                    onChange={handleNumChangeP}
                    className="form-input"
                    required="required"
                  />
                </div>
              </div>
              <div className="Inputfield">
                <h3>
                  City&nbsp;<span>*</span>
                </h3>
                <label>:</label>
                <div className="InputField_box">
                  <input
                    id="city"
                    value={city}
                    readOnly
                    type="text"
                    className="form-input"
                    required="required"
                    disabled
                  />
                </div>
              </div>
              <div className="Inputfield">
                <h3>
                  State&nbsp;<span>*</span>
                </h3>
                <label>:</label>
                <div className="InputField_box">
                  <input
                    id="state"
                    value={state}
                    readOnly
                    type="text"
                    className="form-input"
                    required="required"
                    disabled
                  />
                </div>
              </div>
              <div className="buttonS">
                <button type="submit" className="Button">
                  Submit
                </button>
              </div>
            </form>
            {showPopup && (
              <>
                <div className="Overlay" />
                <div className="Popup">
                  <img
                    src={logo4}
                    alt="Close"
                    className="CloseButton"
                    onClick={() => setShowPopup(false)}
                  />
                  <img src={logo3} alt="Popup" />
                  <span>
                    <h1>Thank You</h1>
                    <br />
                    <h3>
                      Your submission has been received. We will be in touch
                      with you
                    </h3>
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Applypage;
