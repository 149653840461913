import "../../PythonLandingPages.css";
import { Helmet } from "react-helmet";
import Header from "../../../Header/Header";
import THeader from "../../../THeader/THeader";
import Form from "../../../Form/Form";
import Footer from "../../../Footer/Footer";
import image from "../../../assets/android1.svg";
import icon1 from "../../../assets/androids.svg";
import icon2 from "../../../assets/kotlin.svg";
import icon3 from "../../../assets/java.svg";
import image1 from "../../../assets/android-1.svg";
import { useRef } from "react";

const Androidpage = () => {
  const text1 = "{ Codingeni }";
  const formRef = useRef(null);
  const handleScrollInto = () => {
    formRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div className="Main">
      <Helmet>
        <title>Android App Development training Course online in Dubai</title>
        <meta
          name="description"
          content="Codingeni offer Android app development from anywhere with our online course in Dubai. Learn from industry experts and turn your app ideas into reality. Start today!
          "
        />
        <meta
          name="keyword"
          content="android developer online course in Dubai, android app development course for beginners in Dubai, best android app development course in Dubai, android development full course in Dubai
          "
        />
        <link
          rel="canonical"
          href="https://www.codingeni.com/online-android-development-training-course-Dubai"
        />
      </Helmet>
      <THeader />
      <Header />
      <section className="Section_1">
        <div className="Section_Contents">
          <div className="Mid_Text">
            <h1 className="gradient-animation">Bridge the _ gap;</h1>
            {/* <AnimatedText /> */}
            <h2>from graduates ------------- to job-ready</h2>
            <h3>
              With almost everything in the world relying on technology, wanting
              for techies, especially good coders, will never end. Coding is not
              theoretical; it’s practical. Do it to learn it.
            </h3>
          </div>
        </div>
      </section>
      <div className="Text_Holder section_2">
        <h2>{text1}</h2>
        <p>
          Welcome to Techready, a bridge program taking you to the career and
          industry you’re looking forward to with building some of the best and
          exciting products & projects, equipping you with real world,
          commercial product developments in artificial intelligence, deep
          learning, IoT and robotics. From training to fulltime commercial
          internships to jobs, techready gets you ready for the industry not
          just by writing on a board, but you will be working on live commercial
          projects, building disruptive products in deep learning or full stack
          or IoT embedded platform, whichever stream you choose
        </p>
        <p>
          Our approach to learning is <span>‘Build to Learn’</span> wherein
          every intern with us will be developing products in AI mentored,
          guided by our experienced developers and engineers. This gives you the
          edge to learn while to build with an unmatched experience of
          developing a full scale commercial product that’s sold to our
          customers.
        </p>
        <p>
          The difference is that you become an experienced AI developer during
          this Build to Learn Transform program with Techready and you’re
          industry ready matching the skills industry wants, thereby increasing
          the chances of you getting your dream AI job.
        </p>
      </div>
      <div className="Ai_Developement">
        <div className="Text_Holder">
          <h1>Android App Development</h1>
          <h3>In Dubai</h3>
          <p>
            With everything today managed on mobile phones, the need for a
            mobile app developer has increased manifolds in the industry.
            Whether its iOS or Android platforms, mobile apps are designed and
            developed simultaneously for both platforms together. Grab the
            opportunity and become a mobile app developer.{" "}
          </p>
          <div className="Apply_Now_button">
            <button className="Apply" onClick={handleScrollInto}>
              Click for Enquiry
            </button>
          </div>
        </div>
        <div className="Ai_Image">
          <img src={image1} alt="Ai_Develop" />
        </div>
      </div>
      <div className="Text_Holder">
        <h1>Android Developer</h1>
        <p>
          In 2022 Android users in India accounts to 95.26% in India alone with
          a massive hunger for incredible Android apps. Irrespective of the
          sector, there are apps on Android for everything from food ordering to
          critical and secure Banking sectors. This gives a huge market for
          Android developers in India and the world. Become an expert coder in
          Android to tap this growing need for skilled Android developers.
          Expert Android coders will led the way for you with Android studio,
          Kotlin, ML Kit, SDKs, Play Store deployments and more, learn Android
          coding from a scratch to becoming an expert coder to develop tour
          first app in Android.{" "}
        </p>
        <div className="Text_button_Holder">
          <h1>Few works of our Interns </h1>
          <div className="Apply_Now_button">
            <a
              href="https://www.youtube.com/playlist?list=PL6H5Bk4cZ3tDrXWaCB2aLzOmw2iyczLLl"
              target="_blank"
              rel="noreferrer"
            >
              <button className="Apply">Click Here</button>
            </a>
          </div>
        </div>
        <div className="Image_Holder_Landingpage">
          <div className="Animation_Holder_Landingpage">
            <div className="Circle_Animation_Landingpage"></div>
            <img src={image} alt="Computer Vision" />
          </div>
        </div>
      </div>
      <div className="Tools_Holder">
        <h1>Tools & Languages you will be trained on</h1>
        <div className="Tool_Container">
          <div className="Holder">
            <p>1.Android Studio</p>
            <img src={icon1} alt="Android" />
          </div>
          <div className="Holder">
            <p>2.Kotlin</p>
            <img src={icon2} alt="Kotlin" />
          </div>
          <div className="Holder">
            <p>3.Java</p>
            <img src={icon3} alt="Java" />
          </div>
        </div>
      </div>
      {/* <div className="Apply_button">
        <a href="/enroll">
          <button className="Apply">Enrol</button>
        </a>
      </div> */}

      <div ref={formRef} className="Form_1">
        <Form />
      </div>
      <Footer />
    </div>
  );
};

export default Androidpage;
