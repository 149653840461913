import "./Form.css";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import request from "superagent";
import logo3 from "../assets/Popup.png";
import logo4 from "../assets/close.svg";
import { BASE_URL } from "../constants";

function Form() {
  const { register, handleSubmit, reset } = useForm();
  const [mob, setNum1] = useState("");
  const handleNumChangeM = (event) => {
    const numericValue = event.target.value.replace(/\D/g, "");
    const limit1 = 10;
    setNum1(numericValue.slice(0, limit1));
  };
  const [showPopup, setShowPopup] = useState(false);
  const onSubmit = (data) => {
    if (
      [
        data.First_Name,
        data.Last_Name,
        data.Email,
        data.Phone_Number,
        data.Message,
      ].some((field) => field?.trim() !== "")
    ) {
      setShowPopup(true);
      request
        .post(`${BASE_URL}/ContactForm/Contact`)
        .send(data)
        .set("Accept", "application/json")
        .then((response) => {
          console.log(response.body.message);
          // console.log(data)
        })
        .catch((error) => {
          console.error("Error sending Email:", error);
        });
      reset();
      setNum1("");
    }
  };
  useEffect(() => {
    const Input = document.querySelector(".Input");
    const Input1 = document.querySelector(".Input1");
    const Input2 = document.querySelector(".Input2");
    const Input3 = document.querySelector(".Input3");
    const observer1 = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            Input.classList.remove("UnSeen");
            Input1.classList.remove("UnSeen");
            Input2.classList.remove("UnSeen");
            Input3.classList.remove("UnSeen");
            Input.classList.add("Seen");
            Input1.classList.add("Seen");
            Input2.classList.add("Seen");
            Input3.classList.add("Seen");
          } else {
            Input.classList.remove("Seen");
            Input1.classList.remove("Seen");
            Input2.classList.remove("Seen");
            Input3.classList.remove("Seen");
            Input.classList.add("UnSeen");
            Input1.classList.add("UnSeen");
            Input2.classList.add("UnSeen");
            Input3.classList.add("UnSeen");
          }
        });
      },
      { threshold: 0.5 }
    );
    const Form = document.querySelector(".Form_Container");
    observer1.observe(Form);
  });
  return (
    <section className="Section_7">
      <div className="Contact_Form">
        <h1>&lt; Reach us for a career in coding /&gt;</h1>
        <form
          className="Form_Container"
          onSubmit={handleSubmit(onSubmit)}
          autoFocus
          autoComplete="off"
        >
          <div className="Form_InputField_Container">
            <label>
              Full Name&nbsp;<span>*</span>
            </label>
            <label>:</label>
            <input
              className="Input"
              type="text"
              {...register("Full_Name")}
              autoComplete="off"
              required
            />
          </div>
          <div className="Form_InputField_Container">
            <label>
              Phone Number&nbsp;<span>*</span>
            </label>
            <label>:</label>
            <input
              className="Input1"
              type="number"
              {...register("Phone_Number")}
              value={mob}
              onChange={(event) => {
                handleNumChangeM(event);
              }}
              autoComplete="off"
              required
            />
          </div>
          <div className="Form_InputField_Container">
            <label>
              Email&nbsp;<span>*</span>
            </label>
            <label>:</label>
            <input
              className="Input2"
              type="email"
              {...register("Email")}
              autoComplete="off"
              required
            />
          </div>
          <div className="Form_InputField_Container">
            <label>
              Message&nbsp;<span>*</span>
            </label>
            <label>:</label>
            <input
              className="Input3"
              type="text"
              {...register("Message")}
              autoComplete="off"
              required
            />
          </div>
          <div className="Form_InputField_Container_A">
            <button type="submit">Enquire Now</button>
          </div>
          {showPopup && (
            <>
              <div className="Overlay" />
              <div className="Popup">
                <img
                  src={logo4}
                  alt="Close"
                  className="CloseButton"
                  onClick={() => setShowPopup(false)}
                />
                <img src={logo3} alt="Popup" />
                <span>
                  <h1>Thank You</h1>
                  <br />
                  <h3>
                    Your submission has been received. We will be in touch with
                    you
                  </h3>
                </span>
              </div>
            </>
          )}
        </form>
      </div>
    </section>
  );
}

export default Form;
