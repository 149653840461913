import React from "react";
import "../Reciept_Format/PdfTemplate.css";
import image from "../images/Codingeni.svg";

function PdfTemplate({ formData }) {
  return (
    <div className="pdf-container">
      <div className="PDF_Contents">
        <div className="Logo_Container">
          <img src={image} alt="Codingeni" />
        </div>
        <div className="From_Address">
          <h1>M/S SAFEPRO AI VIDEO RESEARCH LABS PVT LTD</h1>
          <p>
            122, Soundhamini 3rd Main, Gruhalakshmi Layout Chandra Layout
            Extension II Stage Basaweshwara Nagar, Bengaluru Karnataka - 560079
          </p>
        </div>
        <div className="Contact_Details">
          <div className="Detail">
            <h1>GSTIN:</h1>
            <p>29AACCN0983E1ZF</p>
          </div>
          <div className="Detail">
            <h1>Phone:</h1>
            <p>9876543210</p>
          </div>
          <div className="Detail">
            <h1>Email:</h1>
            <p>contact@codingeni.com</p>
          </div>
        </div>
        <div className="Dotted_Line"></div>
        <div className="Payment_Bill">
          <div className="Payment_Section">
            <h2>PAYMENT RECEIPT</h2>
            <div className="Detail">
              <h1>Receipt No:</h1>
              <p>2956</p>
            </div>
            <div className="Detail">
              <h1>Transaction ID:</h1>
              <p>{formData?.orderId.split("_")[1]}</p>
            </div>
            <div className="Detail">
              <h1>Date & Time:</h1>
              <p>
                {new Date(formData?.createdAt)
                  .toString()
                  .split(" ")
                  .slice(1, 4)
                  .join(" ")}
                &nbsp; & &nbsp;
                {new Date(formData?.createdAt).toString().split(" ")[4]}
              </p>
            </div>
            <div className="Detail">
              <h1>Transaction Amount:</h1>
              <p>₹53,100</p>
            </div>
          </div>
          <div className="Bill_Section">
            <h2>BILL TO</h2>
            <div className="Detail">
              <p>{formData.firstName}</p>
            </div>
            <div className="Detail">
              <p>{formData.addressLine2}</p>
            </div>
            <div className="Detail">
              <p>{formData.mobile}</p>
            </div>
            <div className="Detail">
              <p>{formData.email}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="Description_Section">
        <div className="Heading">
          <h1>#Item & Description</h1>
          <h2>Amount</h2>
        </div>
      </div>
      <div className="PDF_Contents">
        <div className="Fee-Content">
          <h1>Developer-led {formData.stream} Training Program</h1>
          <h2>₹45,000.00</h2>
        </div>
        <div className="Fee-Content">
          <h1>Discount</h1>
          <h2>₹0.00</h2>
        </div>
        <div className="Fee-Content">
          <h1>GST Charges(18%)</h1>
          <h2>₹8,100.00</h2>
        </div>
        <div className="Amount_Box">
          <div className="Amount-Content">
            <h1>Total Amount</h1>
            <h2>₹53,100.00</h2>
          </div>
        </div>
        <div className="Conclusion_Section">
          <h1>Received Amount : ₹53,100</h1>
          <h1>
            Amount Received in Words: Fifty Three Thousand One Hundred Rupees
            Only/-.
          </h1>
        </div>
      </div>
      <div className="Reciept_Footer">
        <p>
          Note: This is a computer generated pay receipt and does not require a
          signature
        </p>
      </div>
    </div>
  );
}

export default PdfTemplate;
