import "../Pythonpage/Python.css";
import { Helmet } from "react-helmet";
import Header from "../Header/Header";
import THeader from "../THeader/THeader";
import OHeader from "../OurProgramsHeader/OHeader";
import Form from "../Form/Form";
import Footer from "../Footer/Footer";
import image from "../assets/UI.svg";
import icon1 from "../assets/Figma.svg";
import icon2 from "../assets/XD.svg";
import icon3 from "../assets/Smiley.svg";
import icon4 from "../assets/AI.svg";
import icon5 from "../assets/PS.svg";

const UIUXpage = () => {
  return (
    <div className="Main">
      <Helmet>
        <title>UI/UX & Graphi Designing Course online - Codingeni</title>
        <meta
          name="description"
          content="Codingeni provide UI/UX and graphic designing with our online course. Learn to create intuitive interfaces and delightful user experiences. Enrol now
          "
        />
        <meta
          name="keyword"
          content="ui ux design course online,ux design course online, ui ux online course,ui ux certification course online
          "
        />
        <link
          rel="canonical"
          href="https://www.codingeni.com/online-UI-UX-graphic-designing-training-course"
        />
      </Helmet>
      <THeader />
      <Header />
      <OHeader />
      <div className="Image_Holder">
        <div className="Animation_Holder">
          <div className="Circle_Animation"></div>
          <img src={image} alt="Computer Vision" />
        </div>
      </div>
      <div className="Text_Holder">
        <h1>UI/UX & Graphic Designer</h1>
        <p>
          Design industry is growing at a rapid speed. Every new or old business
          is going for digital transformation, the designing comes hand in hand
          with it. Business houses know where their audience are and they make
          every effort to reach them. That is where designers get chance to
          showcase their skillset and help businesses to grow through Design
          Thinking, Market Research and Execution. In terms of scope, UI/UX
          design can be a pretty broad field, as it comprises many different
          aspects of design and user experience. UI UX designer demand has been
          on the rise across various industries such as Healthcare, Tech,
          Gaming, Finance, Education and Retail due to the growing importance of
          user-centric design.
        </p>
      </div>
      <div className="Tools_Holder">
        <h1>Tools & Languages you will be trained on</h1>
        <div className="Tool_Container">
          <div className="Holder">
            <p>1.Figma</p>
            <img src={icon1} alt="Figma" />
          </div>
          <div className="Holder">
            <p>2.Adobe XD</p>
            <img src={icon2} alt="XD" />
          </div>
          <div className="Holder">
            <p>3.Balsamiq</p>
            <img src={icon3} alt="Balsamiq" />
          </div>
          <div className="Holder">
            <p>4.Adobe Illustrator</p>
            <img src={icon4} alt="Illustrator" />
          </div>
          <div className="Holder">
            <p>5.Adobe Photoshop</p>
            <img src={icon5} alt="Photoshop" />
          </div>
        </div>
      </div>
      {/* <div className="Apply_button">
        <a href="/enroll">
          <button className="Apply">Enrol</button>
        </a>
      </div> */}
      <Form />
      <Footer />
    </div>
  );
};

export default UIUXpage;
