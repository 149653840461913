import "./Python.css";
import { Helmet } from "react-helmet";
import Header from "../Header/Header";
import THeader from "../THeader/THeader";
import OHeader from "../OurProgramsHeader/OHeader";
import Form from "../Form/Form";
import Footer from "../Footer/Footer";
import image from "../assets/CV.svg";
import icon1 from "../assets/py.svg";
import icon2 from "../assets/pyt.svg";
import icon3 from "../assets/tf.svg";
import icon4 from "../assets/Doc.svg";

const Pythonpage = () => {
  return (
    <div className="Main">
      <Helmet>
        <title>
          Learn Online Python Computer vision Development Course- Codingeni
        </title>
        <meta
          name="description"
          content="Codingeni provide the best online computer vision development training course is designed to help you succeed. Embrace the power of Python and enhance your skills.
          "
        />
        <meta
          name="keyword"
          content="python online course,learn python online, python course for beginners,python programming online, python online tutorial, python online course certification, ai python course,python artificial intelligence course, ai course online, artificial intelligence online course, courses on artificial intelligence,computer vision course online,best computer vision course online
          "
        />
        <link
          rel="canonical"
          href="https://www.codingeni.com/online-python-computer-vision-training-course"
        />
      </Helmet>
      <THeader />
      <Header />
      <OHeader />
      <div className="Image_Holder">
        <div className="Animation_Holder">
          <div className="Circle_Animation"></div>
          <img src={image} alt="Computer Vision" />
        </div>
      </div>
      <div className="Text_Holder">
        <h1>AI Developer</h1>
        <p>
          With the world moving towards artificial intelligence and automation,
          AI Developer or Computer Vision Engineer will play a pivotal role in
          furthering the technology, job market and economy of the country to a
          great extent. While it’s a fact that the future belongs to an AI
          Developer, it’s also true that our graduates are far from being ready
          for this challenging and exiting career. The building block of any
          computer vision coding is Python. Master the art of python coding from
          the makers of AI products such as face recognition, object detection &
          recognition, intrusion detection, and other such data & video
          analytics platform. Sr Python devs will be your instructor, mentor and
          path finder towards making you the perfect python developer enabling
          you to make a prospective career in AI.
        </p>
      </div>
      <div className="Tools_Holder">
        <h1>Tools & Languages you will be trained on</h1>
        <div className="Tool_Container">
          <div className="Holder">
            <p>1.Python</p>
            <img src={icon1} alt="Python" />
          </div>
          <div className="Holder">
            <p>2.PyTorch</p>
            <img src={icon2} alt="PyTorch" />
          </div>
          <div className="Holder">
            <p>3.TensorFlow</p>
            <img src={icon3} alt="TensorFlow" />
          </div>
          <div className="Holder">
            <p>4.Docker</p>
            <img src={icon4} alt="Docker" />
          </div>
        </div>
      </div>
      {/* <div className="Apply_button">
        <a href="/enroll">
        <button className="Apply">Enrol</button>
        </a>
      </div> */}
      <Form />
      <Footer />
    </div>
  );
};

export default Pythonpage;
