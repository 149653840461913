import "./Header.css";
import { useState, useEffect } from "react";
import logo from "../assets/Codingeni.svg";
import AOS from "aos";
import "aos/dist/aos.css";

function Header() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);
  const [isOpened, setIsOpened] = useState(false);
  const [showMenu, setMenu] = useState(false);

  const toggleMenu = () => {
    setIsOpened(!isOpened);
    setMenu(!showMenu);
  };
  useEffect(() => {
    const currentPath = window.location.pathname;
    const navLinks = document.querySelectorAll(".nav-bar");
    navLinks.forEach((link) => {
      if (link.getAttribute("href") === currentPath) {
        link.classList.add("active");
      }
    }, []);
  });

  return (
    <header>
      <div className="Header">
        <div className="Header_Elements">
          <div className="Image_container">
            <a href="/">
              <img src={logo} alt="Codingeni" />
            </a>
          </div>
          <div className="Navigation_Bar">
            <a className="nav-bar" href="/">
              Home
            </a>
            <a className="nav-bar" href="/online-training-courses">
              Our Programs
            </a>
            <a className="nav-bar" href="/online-training">
              Methodology
            </a>
            <a className="nav-bar" href="/Fees-and-FAQs">
              Fee & FAQ
            </a>
            {/* <a className="nav-bar" href="/enroll">
              Enrol
            </a> */}
            <a className="nav-bar" href="/about">
              About
            </a>
            <a className="nav-bar" href="/reach-us">
              Reach Us
            </a>
          </div>
        </div>
        <div className="Mobile_Header">
          <div
            className={`Horizontal_Menu ${isOpened ? "opened" : ""}`}
            onClick={toggleMenu}
          >
            <div
              className={`Horizontal_Menu`}
              onClick={(e) => {
                e.currentTarget.classList.toggle("opened");
                e.currentTarget.setAttribute(
                  "aria-expanded",
                  e.currentTarget.classList.contains("opened")
                );
              }}
              aria-label="Main Menu"
            >
              <svg width="40" height="40" viewBox="0 0 100 100">
                <path
                  className="line line1"
                  d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
                />
                <path className="line line2" d="M 20,50 H 80" />
                <path
                  className="line line3"
                  d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
                />
              </svg>
            </div>
          </div>
        </div>
        {showMenu && (
          <>
            <div className="Mobile_Menu" data-aos="fade-left">
              <div className="Menu">
                <a className="nav-bar" href="/">
                  Home
                </a>
                <a className="nav-bar" href="/online-training-courses">
                  Our Programs
                </a>
                <a className="nav-bar" href="/online-training">
                  Methodology
                </a>
                <a className="nav-bar" href="/Fees-and-FAQs">
                  Fee & FAQ
                </a>
                {/* <a className="nav-bar" href="/enroll">
                  Enrol
                </a> */}
                <a className="nav-bar" href="/about">
                  About
                </a>
                <a className="nav-bar" href="/reach-us">
                  Reach Us
                </a>
              </div>
            </div>
          </>
        )}
      </div>
    </header>
  );
}

export default Header;
