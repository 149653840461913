import "../Pythonpage/Python.css";
import { Helmet } from "react-helmet";
import Header from "../Header/Header";
import THeader from "../THeader/THeader";
import OHeader from "../OurProgramsHeader/OHeader";
import Form from "../Form/Form";
import Footer from "../Footer/Footer";
import image from "../assets/iOSDev.svg";
import icon1 from "../assets/xcode.svg";
import icon2 from "../assets/swift.svg";
import icon3 from "../assets/story.svg";

const IOSpage = () => {
  return (
    <div className="Main">
      <Helmet>
        <title>Learn IOS Development Course online -Codingeni</title>
        <meta
          name="description"
          content="Join our online iOS development course and gain the knowledge and hands-on experience you need for success in the app industry. Enrol now!
          "
        />
        <meta
          name="keyword"
          content="ios developer online course,best ios development course,online ios app development,best ios app development course,ios development course online
          "
        />
        <link
          rel="canonical"
          href="https://www.codingeni.com/online-ios-development-training-course"
        />
      </Helmet>
      <THeader />
      <Header />
      <OHeader />
      <div className="Image_Holder">
        <div className="Animation_Holder">
          <div className="Circle_Animation"></div>
          <img src={image} alt="Computer Vision" />
        </div>
      </div>
      <div className="Text_Holder">
        <h1>iOS Developer</h1>
        <p>
          With more than 100 crores mobile subscribers in India alone, and every
          aspect of our lives moving to mobile apps be it banking, insurance,
          sales, ecommerce or Govt services and food ordering, the need for apps
          for every sector is an urgent need and a necessity. Even though the
          percentage of Apple users in India is around 4% as of 2022, every app
          launched irrespective of the sectors, iPhone Apps is mandatory and a
          must. This clearly gives a good opportunity and growth for iOS
          developers in India. Learn iOS skillsets, the power of Xcode and the
          ease of Swig, Swig UI and the CoreML from experienced iOS developers
          with the knowledge and track record of launching multiple iOS Apps.
          Become an expert coder to developer amazing apps for iPhones.
        </p>
      </div>
      <div className="Tools_Holder">
        <h1>Tools & Languages you will be trained on</h1>
        <div className="Tool_Container">
          <div className="Holder">
            <p>1.Xcode</p>
            <img src={icon1} alt="XCode" />
          </div>
          <div className="Holder">
            <p>2.Swift</p>
            <img src={icon2} alt="Swift" />
          </div>
          <div className="Holder">
            <p>3.Storyboard</p>
            <img src={icon3} alt="Storyboard" />
          </div>
        </div>
      </div>
      {/* <div className="Apply_button">
        <a href="/enroll">
          <button className="Apply">Enrol</button>
        </a>
      </div> */}
      <Form />
      <Footer />
    </div>
  );
};

export default IOSpage;
