import React from "react";
import "./About.css";
import { Helmet } from "react-helmet";
import Header from "../Header/Header";
import THeader from "../THeader/THeader";
// import ComingSoon from "../ComingSoon/ComingSoon";
import Form from "../Form/Form";
import Footer from "../Footer/Footer";
import passion from "../assets/passion.png";
// import img1 from "../assets/sonu.png";
// import img2 from "../assets/img.png";
// import img3 from "../assets/mythili_rao.png";
// import img4 from "../assets/NITIN.png";
// import img5 from "../assets/sridhar.png";
// import img6 from "../assets/sujayraosir.png";
// import img7 from "../assets/Santosh.png";
// import img8 from "../assets/img.png";
// import img9 from "../assets/Suyash.png";
// import des1 from "../assets/img.png";
// import des2 from "../assets/img.png";
// import des3 from "../assets/des3.png";
// import des4 from "../assets/des4.png";
// import des5 from "../assets/des5.png";
// import des6 from "../assets/des6.png";
// import des7 from "../assets/des7.png";
// import des8 from "../assets/des8.png";
// import des9 from "../assets/des9.png";

import Deblina from "../assets/Deblina.jpg";
import Eeshan from "../assets/Eeshan.jpg";
import Karthik from "../assets/Karthik.jpg";
import Sridhar from "../assets/Sridhar.jpg";
import Manjunath from "../assets/Manjunath.jpg";
import Arjun from "../assets/Arjun.jpg";
import Santhosh from "../assets/Santhosh.jpg";
import Poonam from "../assets/Poonam.jpg";
import Rakshita from "../assets/Rakshita.jpg";

const Aboutpage = () => {
  return (
    <div className="Main">
      <Helmet>
        <title>Online Learning Platform - Codingeni</title>
        <meta
          name="description"
          content="Codingeni is the best online learning platfrom in India, acquire new knowledge and skills or advance their existing ones.
          "
        />
        <meta
          name="keyword"
          content="Codingeni, online training course in India, online learning platform in India, online course in India, online training course, online learning platform, learning courses, best online coding courses, best online courses platform.
          "
        />
        <link rel="canonical" href="https://www.codingeni.com/about" />
      </Helmet>
      <THeader />
      <Header />
      <div className="passion">
        <img src={passion} alt="passionleadushere" />
      </div>
      <div className="ourteam_cont">
        <div className="ourteams">
          <h1>&#123;&nbsp;Our Team&nbsp;&#125;</h1>
          <p>
            We’re a bunch of techies with over 19 years of experience in
            developing disruptive products in the field of AI. We come with
            strong background of producing the finest products in machine
            learning, robotics, IoT, edge video analytics, integrated dashboards
            and more.
            <br />
            <br />
            We’re not a training institute, but a deep tech company working on
            complex projects and products for Govts, large industries, retail,
            education, transport, traffic, heath care and defense sectors.
            <br />
            <br />
            Here are Codingeni, you will learn and implement with seasoned
            developers giving you an edge in the job market especially as
            freshers to clinch the jobs of your desire. Our coders don’t require
            a resume, but confident to show the interviewer your coding
            capabilities with projects you’ve built and accept coding challenge
            from them too.
            <br />
            <br />
            Join us to scale the world of tech.
          </p>
        </div>
      </div>
      <div className="photos_cont">
        <div className="photos_teams">
          <div className="first_row">
            <div className="img_cont1">
              <img className="img1" src={Deblina} alt="Deblina" />
              <div className="border">
                <div className="Text">Program Administrator</div>
              </div>
              {/* <div className="description">
                <img className="descrip_img" src={des1} alt="description" />
              </div> */}
            </div>
            <div className="img_cont1">
              <img className="img1" src={Eeshan} alt="Eeshan" />
              <div className="border">
                <div className="Text">Managing Partner</div>
              </div>
              {/* <div className="description">
                <img className="descrip_img" src={des2} alt="description" />
              </div> */}
            </div>
            <div className="img_cont1">
              <img className="img3" src={Karthik} alt="Karthik" />
              <div className="border">
                <div className="Text">Partner</div>
              </div>
              {/* <div className="description">
                <img className="descrip_img" src={des3} alt="description" />
              </div> */}
            </div>
          </div>
          <div className="second_row">
            <div className="img_cont1">
              <img className="img1" src={Sridhar} alt="Sridhar" />
              <div className="border">
                <div className="Text">Mentor, UI/UX Designer</div>
              </div>
              {/* <div className="description">
                <img className="descrip_img" src={des4} alt="description" />
              </div> */}
            </div>
            <div className="img_cont1">
              <img className="img1" src={Manjunath} alt="Manjunath" />
              <div className="border">
                <div className="Text">Mentor, Computer Vision Dev</div>
              </div>
              {/* <div className="description">
                <img className="descrip_img" src={des9} alt="description" />
              </div> */}
            </div>
            <div className="img_cont1">
              <img className="img1" src={Arjun} alt="Arjun" />
              <div className="border">
                <div className="Text">Mentor, Computer Vision Dev</div>
              </div>
              {/* <div className="description">
                <img className="descrip_img" src={des5} alt="description" />
              </div> */}
            </div>
          </div>
          <div className="third_row">
            <div className="img_cont1">
              <img className="img1" src={Santhosh} alt="Santhosh" />
              <div className="border">
                <div className="Text">Mentor, iOS Developer</div>
              </div>
              {/* <div className="description">
                <img className="descrip_img" src={des6} alt="description" />
              </div> */}
            </div>
            <div className="img_cont1">
              <img className="img1" src={Poonam} alt="Poonam" />
              <div className="border">
                <div className="Text">Mentor, Android Developer</div>
              </div>
              {/* <div className="description">
                <img className="descrip_img" src={des7} alt="description" />
              </div> */}
            </div>
            <div className="img_cont1">
              <img className="img1" src={Rakshita} alt="Rakshita" />
              <div className="border">
                <div className="Text">Mentor, Digital Marketing Analyst</div>
              </div>
              {/* <div className="description">
                <img className="descrip_img" src={des8} alt="description" />
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <Form />
      <Footer />
      {/* <ComingSoon /> */}
    </div>
  );
};

export default Aboutpage;
