import "../../PythonLandingPages.css";
import { Helmet } from "react-helmet";
import Header from "../../../Header/Header";
import THeader from "../../../THeader/THeader";
import Form from "../../../Form/Form";
import Footer from "../../../Footer/Footer";
import image from "../../../assets/CV.svg";
import icon1 from "../../../assets/py.svg";
import icon2 from "../../../assets/pyt.svg";
import icon3 from "../../../assets/tf.svg";
import icon4 from "../../../assets/Doc.svg";
import image1 from "../../../assets/CV 1.svg";
import { useRef } from "react";
// import { Link } from "react-router-dom"

const Pythonpage = () => {
  const text1 = "{ Codingeni }"
  const formRef = useRef(null);
  const handleScrollInto = () => {
    formRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="Main">
      <Helmet>
        <title>
          Learn Online Python Artificial Intelligence Development Course in Abu Dhabi
        </title>
        <meta
          name="description"
          content="Codingeni provide the best online artificial intelligence development training course in Abu Dhabi, designed to help you succeed. Embrace the power of Python and enhance your skills.
          "
        />
        <meta
          name="keyword"
          content="python online course in Abu Dhabi,learn python online in Abu Dhabi, python course for beginners in Abu Dhabi,python programming online in Abu Dhabi, python online tutorial in Abu Dhabi, python online course certification in Abu Dhabi, ai python course in Abu Dhabi, python artificial intelligence course in Abu Dhabi, ai course online in Abu Dhabi, artificial intelligence online course in Abu Dhabi, courses on artificial intelligence in Abu Dhabi, computer vision course online in Abu Dhabi, best computer vision course online in Abu Dhabi.
          "
        />
        <link
          rel="canonical"
          href="https://www.codingeni.com/online-python-artificial-intelligence-development-training-course-Abu-dhabi"
        />
      </Helmet>
      <THeader />
      <Header />
      <section className="Section_1">
        <div className="Section_Contents">
          <div className="Mid_Text">
            <h1 className="gradient-animation">Bridge the _ gap;</h1>
            {/* <AnimatedText /> */}
            <h2>from graduates ------------- to job-ready</h2>
            <h3>
              With almost everything in the world relying on technology,
              wanting for techies, especially good coders, will never end.
              Coding is not theoretical; it’s practical. Do it to learn it.
            </h3>
          </div>
        </div>
      </section>
      <div className="Text_Holder section_2">
        <h2>{text1}</h2>
        <p>
          Welcome to Techready, a bridge program taking you to the career and industry you’re looking
          forward to with building some of the best and exciting products & projects, equipping you with
          real world, commercial product developments in artificial intelligence, deep learning, IoT and
          robotics. From training to fulltime commercial internships to jobs, techready gets you ready for
          the industry not just by writing on a board, but you will be working on live commercial projects,
          building disruptive products in deep learning or full stack or IoT embedded platform, whichever
          stream you choose
        </p>
        <p>
          Our approach to learning is <span>‘Build to Learn’</span> wherein every intern with us will be developing
          products in AI mentored, guided by our experienced developers and engineers. This gives you
          the edge to learn while to build with an unmatched experience of developing a full scale
          commercial product that’s sold to our customers.
        </p>
        <p>
          The difference is that you become an experienced AI developer during this Build to Learn
          Transform program with Techready and you’re industry ready matching the skills industry wants,
          thereby increasing the chances of you getting your dream AI job.
        </p>
      </div>
      <div className="Ai_Developement">
        <div className="Text_Holder">
          <h1>Python AI Developement</h1>
          <h3>In Abu Dhabi</h3>
          <p>
           Learning Python today is a must for every aspiring coder as this is the most widely used tech for AI, Web & Desktop applications, Embedded electronics, Medical engineering, etc. This is sort of become a universal tech platform for every product and sector. Learning Python is essential if you want to be into the exploding field of AI.
          </p>
          <div className="Apply_Now_button">
            <button className="Apply" onClick={handleScrollInto}>Click for Enquiry</button>
          </div>
        </div>
        <div className="Ai_Image">
          <img src={image1} alt="Ai_Develop" />
        </div>
      </div>
      <div className="Text_Holder">
        <h1>Python AI</h1>
        <p>
          With the world moving towards artificial intelligence and automation,
          AI Developer or Computer Vision Engineer will play a pivotal role in
          furthering the technology, job market and economy of the country to a
          great extent. While it’s a fact that the future belongs to an AI
          Developer, it’s also true that our graduates are far from being ready
          for this challenging and exiting career. The building block of any
          computer vision coding is Python. Master the art of python coding from
          the makers of AI products such as face recognition, object detection &
          recognition, intrusion detection, and other such data & video
          analytics platform. Sr Python devs will be your instructor, mentor and
          path finder towards making you the perfect python developer enabling
          you to make a prospective career in AI.
        </p>
        <div className="Text_button_Holder">
          <h1>Few works of our Interns </h1>
          <div className="Apply_Now_button">
            <a href="https://www.youtube.com/playlist?list=PL6H5Bk4cZ3tDrXWaCB2aLzOmw2iyczLLl"
              target="_blank"
              rel="noreferrer">
              <button className="Apply">Click Here</button></a>
          </div>
        </div>
        <div className="Image_Holder_Landingpage">
          <div className="Animation_Holder_Landingpage">
            <div className="Circle_Animation_Landingpage"></div>
            <img src={image} alt="Computer Vision" />
          </div>
        </div >
      </div>
      <div className="Tools_Holder">
        <h1>Tools & Languages you will be trained on</h1>
        <div className="Tool_Container">
          <div className="Holder">
            <p>1.Python</p>
            <img src={icon1} alt="Python" />
          </div>
          <div className="Holder">
            <p>2.PyTorch</p>
            <img src={icon2} alt="PyTorch" />
          </div>
          <div className="Holder">
            <p>3.TensorFlow</p>
            <img src={icon3} alt="TensorFlow" />
          </div>
          <div className="Holder">
            <p>4.Docker</p>
            <img src={icon4} alt="Docker" />
          </div>
        </div>
      </div>
      {/* <div className="Apply_button" ref={formRef}>
        <a href="/enroll">
          <button className="Apply">Apply Now</button>
        </a>
      </div> */}

      <div ref={formRef} className="Form_1">
        <Form />
      </div>
      <Footer />
    </div>
  );
};

export default Pythonpage;
