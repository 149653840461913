import "../../PythonLandingPages.css";
import { Helmet } from "react-helmet";
import Header from "../../../Header/Header";
import THeader from "../../../THeader/THeader";
import Form from "../../../Form/Form";
import Footer from "../../../Footer/Footer";
import image from "../../../assets/iOSDev.svg";
import icon1 from "../../../assets/xcode.svg";
import icon2 from "../../../assets/swift.svg";
import icon3 from "../../../assets/story.svg";
import image1 from "../../../assets/ios-1.svg";
import { useRef } from "react";

const IOSpage = () => {
  const text1 = "{ Codingeni }";
  const formRef = useRef(null);
  const handleScrollInto = () => {
    formRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div className="Main">
      <Helmet>
        <title>Learn IOS Development Course online in Chandigarh</title>
        <meta
          name="description"
          content="Join our online iOS development course in Chandigarh and gain the knowledge and hands-on experience you need for success in the app industry. Enroll now!
          "
        />
        <meta
          name="keyword"
          content="ios developer online course in Chandigarh, best ios development course in Chandigarh, online ios app development in Chandigarh, best ios app development course in Chandigarh, ios development course online in Chandigarh
          "
        />
        <link
          rel="canonical"
          href="https://www.codingeni.com/online-ios-development-training-course-Chandigarh"
        />
      </Helmet>
      <THeader />
      <Header />
      <section className="Section_1">
        <div className="Section_Contents">
          <div className="Mid_Text">
            <h1 className="gradient-animation">Bridge the _ gap;</h1>
            {/* <AnimatedText /> */}
            <h2>from graduates ------------- to job-ready</h2>
            <h3>
              With almost everything in the world relying on technology, wanting
              for techies, especially good coders, will never end. Coding is not
              theoretical; it’s practical. Do it to learn it.
            </h3>
          </div>
        </div>
      </section>
      <div className="Text_Holder section_2">
        <h2>{text1}</h2>
        <p>
          Welcome to Techready, a bridge program taking you to the career and
          industry you’re looking forward to with building some of the best and
          exciting products & projects, equipping you with real world,
          commercial product developments in artificial intelligence, deep
          learning, IoT and robotics. From training to fulltime commercial
          internships to jobs, techready gets you ready for the industry not
          just by writing on a board, but you will be working on live commercial
          projects, building disruptive products in deep learning or full stack
          or IoT embedded platform, whichever stream you choose
        </p>
        <p>
          Our approach to learning is <span>‘Build to Learn’</span> wherein
          every intern with us will be developing products in AI mentored,
          guided by our experienced developers and engineers. This gives you the
          edge to learn while to build with an unmatched experience of
          developing a full scale commercial product that’s sold to our
          customers.
        </p>
        <p>
          The difference is that you become an experienced AI developer during
          this Build to Learn Transform program with Techready and you’re
          industry ready matching the skills industry wants, thereby increasing
          the chances of you getting your dream AI job.
        </p>
      </div>
      <div className="Ai_Developement">
        <div className="Text_Holder">
          <h1>iOS App Development</h1>
          <h3>In Chandigarh</h3>
          <p>
            With everything today managed on mobile phones, the need for a
            mobile app developer has increased manifolds in the industry.
            Whether its iOS or Android platforms, mobile apps are designed and
            developed simultaneously for both platforms together. Grab the
            opportunity and become a mobile app developer.
          </p>
          <div className="Apply_Now_button">
            <button className="Apply" onClick={handleScrollInto}>
              Click for Enquiry
            </button>
          </div>
        </div>
        <div className="Ai_Image">
          <img src={image1} alt="Ai_Develop" />
        </div>
      </div>
      <div className="Text_Holder">
        <h1>iOS Developer</h1>
        <p>
          With more than 100 crores mobile subscribers in India alone, and every
          aspect of our lives moving to mobile apps be it banking, insurance,
          sales, ecommerce or Govt services and food ordering, the need for apps
          for every sector is an urgent need and a necessity. Even though the
          percentage of Apple users in India is around 4% as of 2022, every app
          launched irrespective of the sectors, iPhone Apps is mandatory and a
          must. This clearly gives a good opportunity and growth for iOS
          developers in India. Learn iOS skillsets, the power of Xcode and the
          ease of Swig, Swig UI and the CoreML from experienced iOS developers
          with the knowledge and track record of launching multiple iOS Apps.
          Become an expert coder to developer amazing apps for iPhones.
        </p>
        <div className="Text_button_Holder">
          <h1>Few works of our Interns </h1>
          <div className="Apply_Now_button">
            <a
              href="https://www.youtube.com/playlist?list=PL6H5Bk4cZ3tDrXWaCB2aLzOmw2iyczLLl"
              target="_blank"
              rel="noreferrer"
            >
              <button className="Apply">Click Here</button>
            </a>
          </div>
        </div>
        <div className="Image_Holder_Landingpage">
          <div className="Animation_Holder_Landingpage">
            <div className="Circle_Animation_Landingpage"></div>
            <img src={image} alt="Computer Vision" />
          </div>
        </div>
      </div>
      <div className="Tools_Holder">
        <h1>Tools & Languages you will be trained on</h1>
        <div className="Tool_Container">
          <div className="Holder">
            <p>1.Xcode</p>
            <img src={icon1} alt="XCode" />
          </div>
          <div className="Holder">
            <p>2.Swift</p>
            <img src={icon2} alt="Swift" />
          </div>
          <div className="Holder">
            <p>3.Storyboard</p>
            <img src={icon3} alt="Storyboard" />
          </div>
        </div>
      </div>
      {/* <div className="Apply_button">
        <a href="/enroll">
          <button className="Apply">Enrol</button>
        </a>
      </div> */}

      <div ref={formRef} className="Form_1">
        <Form />
      </div>
      <Footer />
    </div>
  );
};

export default IOSpage;
