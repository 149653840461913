import React from "react";
import "./Reachus.css";
import { Helmet } from "react-helmet";
import linkedin from "../assets/Linkedin.png";
import message from "../assets//message.png";
import location from "../assets/location.png";
import THeader from "../THeader/THeader";
import Header from "../Header/Header";
import Form from "../Form/Form";
import Footer from "../Footer/Footer";

const Reachus = () => {
  return (
    <div className="Main">
      <Helmet>
        <title>Reach Us - Codingeni</title>
        <meta
          name="description"
          content="Join us today and experience the ease and convenience of online training. Discover a smarter way to learn.
          "
        />
        <meta
          name="keyword"
          content="Reach us, Contact us ,  codingenie, Call us, Mail us
          "
        />
        <link rel="canonical" href="https://www.codingeni.com/reach-us" />
      </Helmet>
      <THeader />
      <Header />
      <section className="firstsec_cont">
        <div className="firstsecleft">
          <p className="getintouch">&#123;&nbsp;Get in Touch&nbsp;&#125;</p>
          <p className="parafirst">
            <span>&lt;</span>&emsp;Reach us if you need to know more about being
            a coder and choosing a career in tech.&emsp;<span>&gt;</span>
          </p>
          <div className="contacts_cont">
            <div className="contacts">
              <img src={linkedin} alt="linkedin" />
              <a href="tel:+91 682174 02144 | 80 2323 0607">
                <p>+91 82174 02144 | 80 2323 0607</p>
              </a>
            </div>
            <div className="contacts">
              <img src={message} alt="message" />
              <a href="mailto:contact@codingeni.com">
                <p>contact@codingeni.com</p>
              </a>
            </div>
            <div className="contacts">
              <img src={location} alt="location" />
              <a
                href="https://maps.app.goo.gl/Hzbf9pho5WSdX39v6"
                target="_blank"
                rel="noreferrer"
              >
                <p> Bengaluru, Karnataka 560010 </p>
              </a>
            </div>
          </div>
        </div>
        <div className="firstsecright">
          <div className="map"></div>
          <iframe
            title="codinggeni_map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.646023246381!2d77.532335!3d12.9944776!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa8cc69670d7df1bd%3A0xc86a75010ee0324c!2sOkulr%20Techminds!5e0!3m2!1sen!2sin!4v1722578784740!5m2!1sen!2sin"
            style={{ border: "2px solid #AAA" }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </section>
      <Form />
      <Footer />
    </div>
  );
};

export default Reachus;
{/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.646023246381!2d77.532335!3d12.9944776!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa8cc69670d7df1bd%3A0xc86a75010ee0324c!2sOkulr%20Techminds!5e0!3m2!1sen!2sin!4v1722578784740!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */ }