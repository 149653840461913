import "./Our.css";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "../Header/Header";
import THeader from "../THeader/THeader";
import Form from "../Form/Form";
import Footer from "../Footer/Footer";
import icon1 from "../assets/Python.svg";
import icon2 from "../assets/FullStack.svg";
import icon3 from "../assets/IOS.svg";
import icon4 from "../assets/Android.svg";
import icon5 from "../assets/UIUX.svg";

const OurProgrampage = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);
  return (
    <div className="Main">
      <Helmet>
        <title>
          Online Courses - Python, Computer vision, IOS, Android, UI/UX
          Designing
        </title>
        <meta
          name="description"
          content=" Explore Codingeni online course, learn full stack development, android, iOS python, computer vision, UI/UX designing and also work on live commercial projects.
          "
        />
        <meta
          name="keyword"
          content="Codingeni, python online course, python programming online, computer vision course online, full stack developer course online, best android app development course, ios developer online course, ui ux design course online
          "
        />
        <link
          rel="canonical"
          href="https://www.codingeni.com/online-training-courses"
        />
      </Helmet>
      <THeader />
      <Header />

      <div className="OurPrograms">
        <h1>&#123;&nbsp;Choose the coding stream you want&nbsp;&#125;</h1>
        <h2>
          Want to be coder? Choose the stream you’re interested and want to
          pursue a career in it. We’re good at these streams, and we know we can
          make you a damn good coder in these. Go through, understand, and
          decide the one you want and go with it.
        </h2>
        <div className="Program_Container">
          <a href="/online-python-computer-vision-training-course">
            <div className="BOX" data-aos="fade-right">
              <div className="BOX_Container">
                <img src={icon1} alt="Python" />
                <h1>AI Developer</h1>
              </div>
            </div>
          </a>
          <a href="/online-full-stack-development-training-course">
            <div className="BOX" data-aos="fade-left">
              <div className="BOX_Container">
                <img src={icon2} alt="Full Stack" />
                <h1>Full Stack Developer</h1>
              </div>
            </div>
          </a>
          <a href="/online-ios-development-training-course">
            <div className="BOX" data-aos="fade-right">
              <div className="BOX_Container">
                <img src={icon3} alt="IOS" />
                <h1>iOS Developer</h1>
              </div>
            </div>
          </a>
          <a href="/online-android-development-training-course">
            <div className="BOX" data-aos="fade-left">
              <div className="BOX_Container">
                <img src={icon4} alt="Android" />
                <h1>Android Developer</h1>
              </div>
            </div>
          </a>

          <a href="/online-UI-UX-graphic-designing-training-course">
            <div className="BOX" data-aos="fade-up">
              <div className="BOX_Container">
                <img src={icon5} alt="UI UX" />
                <h1>UI/UX & Graphic Designer</h1>
              </div>
            </div>
          </a>
        </div>
      </div>
      <Form />
      <Footer />
    </div>
  );
};

export default OurProgrampage;
