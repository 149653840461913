import "../Pythonpage/Python.css";
import { Helmet } from "react-helmet";
import Header from "../Header/Header";
import THeader from "../THeader/THeader";
import OHeader from "../OurProgramsHeader/OHeader";
import Form from "../Form/Form";
import Footer from "../Footer/Footer";
import image from "../assets/FS.svg";
import icon1 from "../assets/vscode.svg";
import icon2 from "../assets/html.svg";
import icon3 from "../assets/css.svg";
import icon4 from "../assets/js.svg";
import icon5 from "../assets/a.svg";
import icon6 from "../assets/react.svg";
import icon7 from "../assets/node.svg";
import icon8 from "../assets/express.svg";
import icon9 from "../assets/mongo.svg";
import icon10 from "../assets/sql.svg";

const FullStackpage = () => {
  return (
    <div className="Main">
      <Helmet>
        <title>Full Stack Development Online Training Course - Codingeni</title>
        <meta
          name="description"
          content="Codingeni offer full stack development course to enhance your knowledge and career prospects with our online training course. Join today and invest in your success.
          "
        />
        <meta
          name="keyword"
          content="full stack developer course online,best full stack developer course, best full stack developer course online, full stack online course
          "
        />
        <link
          rel="canonical"
          href="https://www.codingeni.com/online-full-stack-development-training-course"
        />
      </Helmet>
      <THeader />
      <Header />
      <OHeader />
      <div className="Image_Holder">
        <div className="Animation_Holder">
          <div className="Circle_Animation"></div>
          <img src={image} alt="Computer Vision" />
        </div>
      </div>
      <div className="Text_Holder">
        <h1>Full Stack Developer</h1>
        <p>
          Every enterprise and mission critical applications today are working
          with heterogeneous tech stack working together as full stack
          applications integrating multiple platforms, devices, and third-party
          apps together. The need for full stack applications have tremendously
          increased with these requirements. MERN stack, Angular, Node,
          PostgreSQL, Mongo, Microservices, APIs, and more are few of the
          essential tools for becoming a successful developer in the field of
          full stack. You will learn to code front end and backend making you a
          complete developer today’s companies wants. Learn from basics of
          coding to advance coding stages in full stack from experienced full
          stack developers with a track record of design, development and
          executing complex and mission critical projects.
        </p>
      </div>
      <div className="Tools_Holder">
        <h1>Tools & Languages you will be trained on</h1>
        <div className="Tool_Container">
          <div className="Holder">
            <p>1.Visual Studio</p>
            <img src={icon1} alt="VS Code" />
          </div>
          <div className="Holder">
            <p>2.HTML</p>
            <img src={icon2} alt="HTML" />
          </div>
          <div className="Holder">
            <p>3.CSS</p>
            <img src={icon3} alt="CSS" />
          </div>
          <div className="Holder">
            <p>4.JavaScript</p>
            <img src={icon4} alt="JS" />
          </div>
          <div className="Holder">
            <p>5.Angular</p>
            <img src={icon5} alt="Angular" />
          </div>
          <div className="Holder">
            <p>6.REACT</p>
            <img src={icon6} alt="REACT" />
          </div>
          <div className="Holder">
            <p>7.NodeJS</p>
            <img src={icon7} alt="Node" />
          </div>
          <div className="Holder">
            <p>8.Express</p>
            <img src={icon8} alt="Express" />
          </div>
          <div className="Holder">
            <p>9.MongoDB</p>
            <img src={icon9} alt="Mongo" />
          </div>
          <div className="Holder">
            <p>10.PostgreSQL</p>
            <img src={icon10} alt="PostgreSQL" />
          </div>
        </div>
      </div>
      {/* <div className="Apply_button">
        <a href="/enroll">
          <button className="Apply">Enrol</button>
        </a>
      </div> */}
      <Form />
      <Footer />
    </div>
  );
};

export default FullStackpage;
