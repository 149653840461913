import React, { useEffect, useRef } from "react";
import "./OHeader.css";
// import icon1 from "../assets/image/LEFT_ARROW.png";
// import icon2 from "../assets/image/Right_ARROW.png";

const OHeader = () => {
  const widgetsContentRef = useRef(null);

  // const scrollRight = () => {
  //   const currentScrollLeft = widgetsContentRef.current.scrollLeft;
  //   widgetsContentRef.current.scrollTo({
  //     left: currentScrollLeft + 200,
  //     behavior: "smooth",
  //   });
  // };

  // const scrollLeft = () => {
  //   const currentScrollLeft = widgetsContentRef.current.scrollLeft;
  //   widgetsContentRef.current.scrollTo({
  //     left: currentScrollLeft - 200,
  //     behavior: "smooth",
  //   });
  // };

  useEffect(() => {
    const currentPath = window.location.pathname;
    const navLinks = document.querySelectorAll(".Active");
    navLinks.forEach((link) => {
      if (link.getAttribute("href") === currentPath) {
        link.classList.add("active");
      }
    }, []);
  });

  return (
    <div>
      <header>
        <div className="container">
          <div className="Header_Menu">
            {/* <div className="Scroll-Button-mobile-left">
              <button onClick={scrollLeft}>
                <img src={icon1} alt="Left Arrow" />
              </button>
            </div> */}
            <div className="wrapper" ref={widgetsContentRef}>
              <div className="Menu_Box">
                <span>
                  <a
                    href="/online-python-computer-vision-training-course"
                    className="Active"
                  >
                    AI Developer
                  </a>
                </span>
              </div>
              <div className="Menu_Box">
                <span>
                  <a
                    href="/online-full-stack-development-training-course"
                    className="Active"
                  >
                    Full Stack Developer
                  </a>
                </span>
              </div>
              <div className="Menu_Box">
                <span>
                  <a
                    href="/online-ios-development-training-course"
                    className="Active"
                  >
                    iOS Developer
                  </a>
                </span>
              </div>
              <div className="Menu_Box">
                <span>
                  <a
                    href="/online-android-development-training-course"
                    className="Active"
                  >
                    Android Developer
                  </a>
                </span>
              </div>
              <div className="Menu_Box">
                <span>
                  <a
                    href="/online-UI-UX-graphic-designing-training-course"
                    className="Active"
                  >
                    UI/UX & Graphic Designer
                  </a>
                </span>
              </div>
            </div>
            {/* <div className="Scroll-Button-mobile-Right">
              <button onClick={scrollRight}>
                <img src={icon2} alt="Right Arrow" />
              </button>
            </div> */}
          </div>
        </div>
      </header>
    </div>
  );
};

export default OHeader;
