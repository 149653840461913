import "./THeader.css";
import icon1 from "../assets/facebook.svg";
import icon2 from "../assets/instagram.svg";
import icon3 from "../assets/Twitter.svg";
import icon4 from "../assets/Linkedin.svg";
import icon5 from "../assets/yt.svg";

const THeader = () => {
  return (
    <header>
      <div className="Hire_from_us">
        <div className="H_F_U_Contents">
          <div className="Icons">
            <a
              href="https://www.facebook.com/codingeni"
              target="_blank"
              rel="noreferrer"
            >
              <img src={icon1} alt="Facebook" />
            </a>
            <a
              href=" https://www.instagram.com/codingeni"
              target="_blank"
              rel="noreferrer"
            >
              <img src={icon2} alt="Instagram" />
            </a>
            <a
              href="https://twitter.com/codingeni"
              target="_blank"
              rel="noreferrer"
            >
              <img src={icon3} alt="Twitter" />
            </a>
            <a
              href="https://www.linkedin.com/company/codingeni1/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={icon4} alt="LinkedIn" />
            </a>
            <a
              href="https://www.youtube.com/@codingeni"
              target="_blank"
          
              rel="noreferrer"
            >
              <img src={icon5} alt="YouTube" />
            </a>
          </div>
        </div>
      </div>
    </header>
  );
};

export default THeader;
