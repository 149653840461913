import React from "react";
import "./Animation.css";
import icon1 from "../assets/1.svg";
import icon2 from "../assets/2.svg";
import icon3 from "../assets/3.svg";
import icon4 from "../assets/4.svg";
import icon5 from "../assets/5.svg";
import icon6 from "../assets/6.svg";
import icon7 from "../assets/7.svg";

function Animation() {
  return (
    <div className="SVG_Container">
      <div className="UP">
        <p>Apply</p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="201"
          height="159"
          viewBox="0 0 201 159"
          fill="none"
          className="PATHUP path-animation-UP"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M158.845 0.862008L158.845 84.2679L162.424 84.2679L162.424 84.2218C168.845 84.4528 175.103 86.4397 180.461 90.044C186.142 93.8562 190.576 99.2625 193.209 105.57C195.819 111.9 196.512 118.855 195.172 125.555C193.833 132.278 190.53 138.447 185.704 143.276C180.877 148.128 174.711 151.409 167.99 152.749C161.27 154.089 154.318 153.396 147.99 150.785C141.663 148.174 136.259 143.738 132.471 138.031C128.661 132.348 126.651 125.647 126.651 118.809L126.651 115.227L0.741209 115.227L0.741209 118.809L121.94 118.809C121.94 126.595 124.25 134.173 128.568 140.642C132.887 147.111 139.03 152.148 146.212 155.128C153.395 158.109 161.293 158.871 168.914 157.369C176.535 155.845 183.533 152.102 189.029 146.603C194.526 141.104 198.267 134.104 199.791 126.479C201.315 118.855 200.53 110.953 197.551 103.768C194.572 96.5825 189.537 90.4367 183.071 86.1162C176.928 82.0268 169.792 79.7395 162.424 79.5085L162.424 0.862008L158.845 0.862008Z"
            fill="transparent"
            stroke="#AAA"
            strokeWidth="3"
          />
        </svg>
        <img className="Frst" src={icon1} alt="Icon1" />
      </div>
      <div className="DOWN">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="201"
          height="159"
          viewBox="0 0 201 159"
          fill="none"
          className="PATHDOWN path-animation-DOWN"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M158.845 0.862008L158.845 84.2679L162.424 84.2679L162.424 84.2218C168.845 84.4528 175.103 86.4397 180.461 90.044C186.142 93.8562 190.576 99.2625 193.209 105.57C195.819 111.9 196.512 118.855 195.172 125.555C193.833 132.278 190.53 138.447 185.704 143.276C180.877 148.128 174.711 151.409 167.99 152.749C161.27 154.089 154.318 153.396 147.99 150.785C141.663 148.174 136.259 143.738 132.471 138.031C128.661 132.348 126.651 125.647 126.651 118.809L126.651 115.227L0.741209 115.227L0.741209 118.809L121.94 118.809C121.94 126.595 124.25 134.173 128.568 140.642C132.887 147.111 139.03 152.148 146.212 155.128C153.395 158.109 161.293 158.871 168.914 157.369C176.535 155.845 183.533 152.102 189.029 146.603C194.526 141.104 198.267 134.104 199.791 126.479C201.315 118.855 200.53 110.953 197.551 103.768C194.572 96.5825 189.537 90.4367 183.071 86.1162C176.928 82.0268 169.792 79.7395 162.424 79.5085L162.424 0.862008L158.845 0.862008Z"
            fill="transparent"
            stroke="#AAA"
            strokeWidth="3"
          />
        </svg>
        <img className="Sec" src={icon2} alt="Icon1" />
        <p>Test</p>
      </div>
      <div className="UP">
        <p>Interview</p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="201"
          height="159"
          viewBox="0 0 201 159"
          fill="none"
          className="PATHUP path-animation-UP1"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M158.845 0.862008L158.845 84.2679L162.424 84.2679L162.424 84.2218C168.845 84.4528 175.103 86.4397 180.461 90.044C186.142 93.8562 190.576 99.2625 193.209 105.57C195.819 111.9 196.512 118.855 195.172 125.555C193.833 132.278 190.53 138.447 185.704 143.276C180.877 148.128 174.711 151.409 167.99 152.749C161.27 154.089 154.318 153.396 147.99 150.785C141.663 148.174 136.259 143.738 132.471 138.031C128.661 132.348 126.651 125.647 126.651 118.809L126.651 115.227L0.741209 115.227L0.741209 118.809L121.94 118.809C121.94 126.595 124.25 134.173 128.568 140.642C132.887 147.111 139.03 152.148 146.212 155.128C153.395 158.109 161.293 158.871 168.914 157.369C176.535 155.845 183.533 152.102 189.029 146.603C194.526 141.104 198.267 134.104 199.791 126.479C201.315 118.855 200.53 110.953 197.551 103.768C194.572 96.5825 189.537 90.4367 183.071 86.1162C176.928 82.0268 169.792 79.7395 162.424 79.5085L162.424 0.862008L158.845 0.862008Z"
            fill="transparent"
            strokeWidth="3"
          />
        </svg>
        <img className="Tri" src={icon3} alt="Icon1" />
      </div>
      <div className="DOWN">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="201"
          height="159"
          viewBox="0 0 201 159"
          fill="none"
          className="PATHDOWN path-animation-DOWN1"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M158.845 0.862008L158.845 84.2679L162.424 84.2679L162.424 84.2218C168.845 84.4528 175.103 86.4397 180.461 90.044C186.142 93.8562 190.576 99.2625 193.209 105.57C195.819 111.9 196.512 118.855 195.172 125.555C193.833 132.278 190.53 138.447 185.704 143.276C180.877 148.128 174.711 151.409 167.99 152.749C161.27 154.089 154.318 153.396 147.99 150.785C141.663 148.174 136.259 143.738 132.471 138.031C128.661 132.348 126.651 125.647 126.651 118.809L126.651 115.227L0.741209 115.227L0.741209 118.809L121.94 118.809C121.94 126.595 124.25 134.173 128.568 140.642C132.887 147.111 139.03 152.148 146.212 155.128C153.395 158.109 161.293 158.871 168.914 157.369C176.535 155.845 183.533 152.102 189.029 146.603C194.526 141.104 198.267 134.104 199.791 126.479C201.315 118.855 200.53 110.953 197.551 103.768C194.572 96.5825 189.537 90.4367 183.071 86.1162C176.928 82.0268 169.792 79.7395 162.424 79.5085L162.424 0.862008L158.845 0.862008Z"
            fill="transparent"
            stroke="#AAA"
            strokeWidth="3"
          />
        </svg>
        <img className="For" src={icon4} alt="Icon1" />
        <p>Selection</p>
      </div>
      <div className="UP">
        <p>Admission</p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="201"
          height="159"
          viewBox="0 0 201 159"
          fill="none"
          className="PATHUP path-animation-UP2"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M158.845 0.862008L158.845 84.2679L162.424 84.2679L162.424 84.2218C168.845 84.4528 175.103 86.4397 180.461 90.044C186.142 93.8562 190.576 99.2625 193.209 105.57C195.819 111.9 196.512 118.855 195.172 125.555C193.833 132.278 190.53 138.447 185.704 143.276C180.877 148.128 174.711 151.409 167.99 152.749C161.27 154.089 154.318 153.396 147.99 150.785C141.663 148.174 136.259 143.738 132.471 138.031C128.661 132.348 126.651 125.647 126.651 118.809L126.651 115.227L0.741209 115.227L0.741209 118.809L121.94 118.809C121.94 126.595 124.25 134.173 128.568 140.642C132.887 147.111 139.03 152.148 146.212 155.128C153.395 158.109 161.293 158.871 168.914 157.369C176.535 155.845 183.533 152.102 189.029 146.603C194.526 141.104 198.267 134.104 199.791 126.479C201.315 118.855 200.53 110.953 197.551 103.768C194.572 96.5825 189.537 90.4367 183.071 86.1162C176.928 82.0268 169.792 79.7395 162.424 79.5085L162.424 0.862008L158.845 0.862008Z"
            fill="transparent"
            strokeWidth="3"
          />
        </svg>
        <img className="Fiv" src={icon5} alt="Icon1" />
      </div>
      <div className="DOWN">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="201"
          height="159"
          viewBox="0 0 201 159"
          fill="none"
          className="PATHDOWN path-animation-DOWN2"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M158.845 0.862008L158.845 84.2679L162.424 84.2679L162.424 84.2218C168.845 84.4528 175.103 86.4397 180.461 90.044C186.142 93.8562 190.576 99.2625 193.209 105.57C195.819 111.9 196.512 118.855 195.172 125.555C193.833 132.278 190.53 138.447 185.704 143.276C180.877 148.128 174.711 151.409 167.99 152.749C161.27 154.089 154.318 153.396 147.99 150.785C141.663 148.174 136.259 143.738 132.471 138.031C128.661 132.348 126.651 125.647 126.651 118.809L126.651 115.227L0.741209 115.227L0.741209 118.809L121.94 118.809C121.94 126.595 124.25 134.173 128.568 140.642C132.887 147.111 139.03 152.148 146.212 155.128C153.395 158.109 161.293 158.871 168.914 157.369C176.535 155.845 183.533 152.102 189.029 146.603C194.526 141.104 198.267 134.104 199.791 126.479C201.315 118.855 200.53 110.953 197.551 103.768C194.572 96.5825 189.537 90.4367 183.071 86.1162C176.928 82.0268 169.792 79.7395 162.424 79.5085L162.424 0.862008L158.845 0.862008Z"
            fill="transparent"
            stroke="#AAA"
            strokeWidth="3"
          />
        </svg>
        <img className="Six" src={icon6} alt="Icon1" />
        <p>Batch Allocation</p>
      </div>
      <div className="UP">
        <p>Start the Program</p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="201"
          height="159"
          viewBox="0 0 201 159"
          fill="none"
          className="PATHUP path-animation-UP3"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M158.845 0.862008L158.845 84.2679L162.424 84.2679L162.424 84.2218C168.845 84.4528 175.103 86.4397 180.461 90.044C186.142 93.8562 190.576 99.2625 193.209 105.57C195.819 111.9 196.512 118.855 195.172 125.555C193.833 132.278 190.53 138.447 185.704 143.276C180.877 148.128 174.711 151.409 167.99 152.749C161.27 154.089 154.318 153.396 147.99 150.785C141.663 148.174 136.259 143.738 132.471 138.031C128.661 132.348 126.651 125.647 126.651 118.809L126.651 115.227L0.741209 115.227L0.741209 118.809L121.94 118.809C121.94 126.595 124.25 134.173 128.568 140.642C132.887 147.111 139.03 152.148 146.212 155.128C153.395 158.109 161.293 158.871 168.914 157.369C176.535 155.845 183.533 152.102 189.029 146.603C194.526 141.104 198.267 134.104 199.791 126.479C201.315 118.855 200.53 110.953 197.551 103.768C194.572 96.5825 189.537 90.4367 183.071 86.1162C176.928 82.0268 169.792 79.7395 162.424 79.5085L162.424 0.862008L158.845 0.862008Z"
            fill="transparent"
            strokeWidth="3"
          />
        </svg>
        <img className="Sev" src={icon7} alt="Icon1" />
      </div>
    </div>
  );
}

export default Animation;
