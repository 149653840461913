import React from "react";
import icon from "../images/Edit.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../constants";

const Preview = ({ formData, closeModel }) => {
  const navigate = useNavigate();
  const handleSubmitForm = async () => {
    try {

      const { data } = await axios.post(`${BASE_URL}/user`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (data) {
        navigate("/payment", { state: { _id: data._id } });
      } else {
        alert("Something went wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="Overlay" />
      <div className="Preview_Tab">
        <div className="Edit_Button">
          <div className="Icon" onClick={closeModel}>
            <img src={icon} alt="Edit" />
            <p>Edit</p>
          </div>
        </div>
        <div className="Desktop_Section">
          <div className="Lists">
            <div className="List_1">
              <div className="Preview_Text">
                <h1>First Name</h1>
                <label>:</label>
                <p>{formData.firstName}</p>
              </div>
              <div className="Preview_Text">
                <h1>Last Name</h1>
                <label>:</label>
                <p>{formData.lastName}</p>
              </div>
              <div className="Preview_Text">
                <h1>Mother Name</h1>
                <label>:</label>
                <p>{formData.motherName}</p>
              </div>
              <div className="Preview_Text">
                <h1>Father Name</h1>
                <label>:</label>
                <p>{formData.fatherName}</p>
              </div>
              <div className="Preview_Text">
                <h1>Email-ID</h1>
                <label>:</label>
                <p>{formData.email}</p>
              </div>
              <div className="Preview_Text">
                <h1>Mobile Number</h1>
                <label>:</label>
                <p>{formData.mobile}</p>
              </div>
              <div className="Preview_Text">
                <h1>Degree</h1>
                <label>:</label>
                <p>{formData.degree}</p>
              </div>
              <div className="Preview_Text">
                <h1>College</h1>
                <label>:</label>
                <p>{formData.college}</p>
              </div>
              <div className="Preview_Text">
                <h1>University</h1>
                <label>:</label>
                <p>{formData.university}</p>
              </div>
              <div className="Preview_Text">
                <h1>Chosen track</h1>
                <label>:</label>
                <p>{formData.stream}</p>
              </div>
            </div>
            <div className="List_1">
              <div className="Preview_Text">
                <h1>Student Aadhar Number</h1>
                <label>:</label>
                <p>{formData.aadhar}</p>
              </div>
              <div className="Preview_Text">
                <h1>Student Photograph</h1>
                <label>:</label>
                <p>Yes</p>
              </div>
              <div className="Preview_Text">
                <h1>Student Aadhar Card</h1>
                <label>:</label>
                <p>Yes</p>
              </div>

              <div className="Preview_Text">
                <h1>College ID Card</h1>
                <label>:</label>
                <p>Yes</p>
              </div>
              <div className="Preview_Text">
                <h1>Address Line 1</h1>
                <label>:</label>
                <p>{formData.addressLine1}</p>
              </div>
              <div className="Preview_Text">
                <h1>Address Line 2</h1>
                <label>:</label>
                <p>{formData.addressLine2}</p>
              </div>
              <div className="Preview_Text">
                <h1>Pincode</h1>
                <label>:</label>
                <p>{formData.pincode}</p>
              </div>
              <div className="Preview_Text">
                <h1>City</h1>
                <label>:</label>
                <p>{formData.city}</p>
              </div>
              <div className="Preview_Text">
                <h1>State</h1>
                <label>:</label>
                <p>{formData.state}</p>
              </div>
              <div className="Preview_Text">
                <h1>Country</h1>
                <label>:</label>
                <p>{formData.country}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="Mobile_Section">
          <div className="Lists">
            <div className="List_1">
              <div className="Preview_Text">
                <h1>First Name</h1>
                <label>:</label>
                <p>{formData.firstName}</p>
              </div>
              <div className="Preview_Text">
                <h1>Last Name</h1>
                <label>:</label>
                <p>{formData.lastName}</p>
              </div>
              <div className="Preview_Text">
                <h1>Mother Name</h1>
                <label>:</label>
                <p>{formData.motherName}</p>
              </div>
              <div className="Preview_Text">
                <h1>Father Name</h1>
                <label>:</label>
                <p>{formData.fatherName}</p>
              </div>
              <div className="Preview_Text">
                <h1>Email-ID</h1>
                <label>:</label>
                <p>{formData.email}</p>
              </div>
              <div className="Preview_Text">
                <h1>Mobile Number</h1>
                <label>:</label>
                <p>{formData.mobile}</p>
              </div>
              <div className="Preview_Text">
                <h1>Degree</h1>
                <label>:</label>
                <p>{formData.degree}</p>
              </div>
              <div className="Preview_Text">
                <h1>College</h1>
                <label>:</label>
                <p>{formData.college}</p>
              </div>
              <div className="Preview_Text">
                <h1>University</h1>
                <label>:</label>
                <p>{formData.university}</p>
              </div>
              <div className="Preview_Text">
                <h1>Chosen track</h1>
                <label>:</label>
                <p>{formData.stream}</p>
              </div>
              <div className="Preview_Text">
                <h1>Student Aadhar Number</h1>
                <label>:</label>
                <p>{formData.aadhar}</p>
              </div>
              <div className="Preview_Text">
                <h1>Student Photograph</h1>
                <label>:</label>
                <p>Yes</p>
              </div>
              <div className="Preview_Text">
                <h1>Student Aadhar Card</h1>
                <label>:</label>
                <p>Yes</p>
              </div>

              <div className="Preview_Text">
                <h1>College ID Card</h1>
                <label>:</label>
                <p>Yes</p>
              </div>
              <div className="Preview_Text">
                <h1>Address Line 1</h1>
                <label>:</label>
                <p>{formData.addressLine1}</p>
              </div>
              <div className="Preview_Text">
                <h1>Address Line 2</h1>
                <label>:</label>
                <p>{formData.addressLine2}</p>
              </div>
              <div className="Preview_Text">
                <h1>Pincode</h1>
                <label>:</label>
                <p>{formData.pincode}</p>
              </div>
              <div className="Preview_Text">
                <h1>City</h1>
                <label>:</label>
                <p>{formData.city}</p>
              </div>
              <div className="Preview_Text">
                <h1>State</h1>
                <label>:</label>
                <p>{formData.state}</p>
              </div>
              <div className="Preview_Text">
                <h1>Country</h1>
                <label>:</label>
                <p>{formData.country}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="Price_Section">
          <h1>Total Program Fee Payable: ₹45000 + ₹8100 (18% GST) = ₹53100</h1>
        </div>
        <div className="Button_Section">
          <button
            type="button"
            className="ButtoN"
            onClick={() => handleSubmitForm()}
          >
            Make Payment
          </button>
        </div>
      </div>
    </div>
  );
};

export default Preview;
