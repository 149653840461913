import "../Pythonpage/Python.css";
import { Helmet } from "react-helmet";
import Header from "../Header/Header";
import THeader from "../THeader/THeader";
import OHeader from "../OurProgramsHeader/OHeader";
import Form from "../Form/Form";
import Footer from "../Footer/Footer";
import image from "../assets/android1.svg";
import icon1 from "../assets/androids.svg";
import icon2 from "../assets/kotlin.svg";
import icon3 from "../assets/java.svg";

const Androidpage = () => {
  return (
    <div className="Main">
      <Helmet>
        <title>
          Android App Development training Course online - Codingeni
        </title>
        <meta
          name="description"
          content="Codingeni offer Android app development from anywhere with our online course. Learn from industry experts and turn your app ideas into reality. Start today!
          "
        />
        <meta
          name="keyword"
          content="android developer online course,android app development course for beginners, best android app development course, android development full course
          "
        />
        <link
          rel="canonical"
          href="https://www.codingeni.com/online-android-development-training-course"
        />
      </Helmet>
      <THeader />
      <Header />
      <OHeader />
      <div className="Image_Holder">
        <div className="Animation_Holder">
          <div className="Circle_Animation"></div>
          <img src={image} alt="Computer Vision" />
        </div>
      </div>
      <div className="Text_Holder">
        <h1>Android Developer</h1>
        <p>
          In 2022 Android users in India accounts to 95.26% in India alone with
          a massive hunger for incredible Android apps. Irrespective of the
          sector, there are apps on Android for everything from food ordering to
          critical and secure Banking sectors. This gives a huge market for
          Android developers in India and the world. Become an expert coder in
          Android to tap this growing need for skilled Android developers.
          Expert Android coders will led the way for you with Android studio,
          Kotlin, ML Kit, SDKs, Play Store deployments and more, learn Android
          coding from a scratch to becoming an expert coder to develop your
          first app in Android.
        </p>
      </div>
      <div className="Tools_Holder">
        <h1>Tools & Languages you will be trained on</h1>
        <div className="Tool_Container">
          <div className="Holder">
            <p>1.Android Studio</p>
            <img src={icon1} alt="Android" />
          </div>
          <div className="Holder">
            <p>2.Kotlin</p>
            <img src={icon2} alt="Kotlin" />
          </div>
          <div className="Holder">
            <p>3.Java</p>
            <img src={icon3} alt="Java" />
          </div>
        </div>
      </div>
      {/* <div className="Apply_button">
        <a href="/enroll">
          <button className="Apply">Enrol</button>
        </a>
      </div> */}
      <Form />
      <Footer />
    </div>
  );
};

export default Androidpage;
