import React, { useState } from "react";
import icon1 from "..//images/Upload.svg";
import icon2 from "..//images/Trash.svg";
import icon3 from "..//images/Done.svg";

const FileUpload = ({
  label,
  htmlFor,
  fileLabel,
  accept,
  updateLabel,
  handleChangeFile,
}) => {
  const [fileUploaded, setFileUploaded] = useState(false);

  const clearFile = () => {
    setFileUploaded(false);
  };
  return (
    <div className="Inputfield">
      <h3>
        {label} <span>*</span>
      </h3>
      <label>:</label>
      <label>
        <div className="InputField_box_1">
          <label htmlFor={htmlFor} className="custom-file-label">
            {fileLabel}
          </label>
          {fileUploaded ? (
            <img
              src={icon3}
              alt="Check"
              style={{ marginRight: "20px", cursor: "default" }}
              className="custom-file-icon"
            />
          ) : (
            <img
              src={icon1}
              alt={`Upload ${label}`}
              className="custom-file-icon"
            />
          )}
          {fileUploaded && (
            <img
              src={icon2}
              alt="Delete"
              onClick={clearFile}
              style={{ cursor: "pointer" }}
            />
          )}
          <input
            id={htmlFor}
            type="file"
            name={htmlFor}
            className="form-input"
            accept={accept}
            onChange={(e) => {
              setFileUploaded(true);
              updateLabel(e, htmlFor);
              handleChangeFile(e);
            }}
          />
        </div>
      </label>
      <h6>
        {accept.includes("jpg")
          ? "(Please upload front side of Aadhar containing your photo)"
          : "(Maximum size 2MB .pdf and .jpeg only)"}
      </h6>
    </div>
  );
};

export default FileUpload;
