import '../MasterClass/MasterClass.css'
import image3 from '../assets/image3.jpg'
import logo from "../assets/Codingeni.svg";
import React, { useEffect, useState } from 'react';
import logo3 from "../assets/Popup.png";
import logo4 from "../assets/close.svg";
import axios from "axios";
import { BASE_URL } from '../constants';

const MasterClassAI = () => {


    const [showPopup, setShowPopup] = useState(false);
    const [selectedSemester, setSelectedSemester] = useState('');
    const [pin, setNum] = useState("")
    const [city, setCity] = useState("")
    const [mobileNumber, setNum1] = useState("")
    const [mobileError, setMobileError] = useState('');
    const [otpVisible, setOtpVisible] = useState(false);
    const [otp, setOtp] = useState(new Array(6).fill(""));
    const [seconds, setSeconds] = useState(58);
    const [actualotp, setActualOTP] = useState("");
    const [buttonText, setButtonText] = useState("Get OTP");
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [emailError, setEmailError] = useState('')
    const [isOtpVerified, setIsOtpVerified] = useState(false);
    const [isTimeVisible, setIsTimeVisible] = useState(true);
    const [otpError, setOtpError] = useState('');
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        mobileNumber: mobileNumber,
        college: '',
        branch: '',
        semester: selectedSemester,
        pincode: pin,
        city: city,
        message: '',
        course: 'AI Developer'
    });

    const validateForm = () => {
        if (
            formData.firstName.trim() !== '' &&
            formData.lastName.trim() !== '' &&
            formData.email.trim() !== '' &&
            formData.mobileNumber.trim() !== '' &&
            formData.college.trim() !== '' &&
            formData.branch.trim() !== '' &&
            formData.semester.trim() !== '' &&
            formData.pincode.trim() !== '' &&
            formData.city.trim() !== ''
            // formData.message.trim() !== ''
        ) {
            return true
        }
        else {
            return false
        }
    };

    const handleNumChangeM = (event) => {
        const numericValue = event.target.value.replace(/\D/g, "");
        const limit1 = 10;
        setNum1(numericValue.slice(0, limit1));

        if (numericValue.length !== 10) {
            setMobileError('Please enter a valid 10-digit mobile number');
        } else {
            setMobileError('');
        }
    };

    useEffect(() => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            city: city
        }))
    }, [city])
    useEffect(() => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            pincode: pin
        }))
    }, [pin])
    useEffect(() => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            semester: selectedSemester
        }))
    }, [selectedSemester])

    useEffect(() => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            mobileNumber: mobileNumber
        }))
    }, [mobileNumber])


    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!email) {
            setEmailError('Email is required');
            setIsEmailValid(false);
        } else if (!emailRegex.test(email)) {
            setEmailError('Please enter a valid email address');
            setIsEmailValid(false);
        } else {
            setEmailError('');
            setIsEmailValid(true);
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));

        if (name === 'email') {
            validateEmail(value);
        }
    };

    const OnSubmit = async () => {
        const V = validateForm()
        console.log(V);
        if (isOtpVerified && V) {
            const response = await axios.post(`${BASE_URL}/student`, formData, {
                headers: {
                    "Content-Type": "application/json"
                }

            })
            console.log("done");
            console.log(response);
            setShowPopup(true)
            setFormData({
                firstName: '',
                lastName: '',
                email: '',
                mobileNumber: mobileNumber,
                college: '',
                branch: '',
                semester: selectedSemester,
                pincode: pin,
                city: city,
                message: '',
                course: ''
            })
            setNum1('')
            setNum('')
            setCity('')
            setSelectedSemester('')
            setButtonText('Get OTP')

        }
        else if (V === false) {
            alert("Fill all fields")
        }
        else {
            alert("Verify Email")
        }

    }

    const handleGetOtpClick = async () => {
        if (buttonText === "Verify OTP") {
            console.log(actualotp, otp);
            let otpString = otp.join('');

            if (actualotp === otpString) {
                console.log("successfull");
                setIsOtpVerified(true);
                setOtpVisible(false);
                setOtpError('')
                setSeconds('')
                setIsTimeVisible(false)
            } else {
                console.log("not successfull");
                setOtpError('Wrong OTP. Please try again.')
            }
        }
        else {
            setOtpVisible(true);
            setButtonText("Verify OTP");
            try {
                const response = await axios.post(`${BASE_URL}/getotp`, { email: formData.email }, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                console.log(response.data);
                setActualOTP(response.data.otp)
                setOtpError('')
                const intervalId = setInterval(() => {
                    setSeconds((prevSeconds) => (prevSeconds > 0 ? prevSeconds - 1 : 0))
                }, 1000);

                return () => clearInterval(intervalId)


            } catch (error) {
                console.error("Error fetching OTP:", error)
            }
        }
    };

    const handleResendOtpClick = async () => {
        try {
            const response = await axios.post(`${BASE_URL}/getotp`, { email: formData.email }, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
            console.log(response.data);
            setActualOTP(response.data.otp)
            setOtpError('')
            setSeconds(60)
            const intervalId = setInterval(() => {
                setSeconds((prevSeconds) => (prevSeconds > 0 ? prevSeconds - 1 : 0))
            }, 1000);

            return () => clearInterval(intervalId)
        } catch (error) {
            console.log(error);
        }
    }

    const handleOtpChange = (element, index) => {
        if (isNaN(element.value)) return false;

        setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

        if (element.nextSibling && element.value !== "") {
            element.nextSibling.focus();
        }
    };

    const handleKeyDown = (element, index, event) => {
        if (event.key === 'Backspace') {
            if (element.value === '' && element.previousSibling) {
                element.previousSibling.focus();
            }
        }
    };

    const handleSemesterChange = (event) => {
        setSelectedSemester(event.target.value);
    };

    const handlePinChange = (event) => {
        var numericValue = event.target.value.replace(/\D/g, "");
        const limit = 6;
        numericValue = numericValue.slice(0, limit);
        setNum(numericValue);
        const url = `https://api.postalpincode.in/pincode/${numericValue}`;
        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                if (data[0].Status === "Success") {
                    const city = data[0].PostOffice[0].District;
                    setCity(city);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const handleScrollInto = () => {
        const targetElement = document.querySelector(".sample");

        if (targetElement) {
            targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    };


    return (
        <>
            <div className="Main1">
                <header>
                    <div className="Headerm">

                        <div className="Image_containerm">
                            <a>
                                <img src={logo} alt="Codingeni" />
                            </a>
                        </div>

                    </div>
                </header>
                {/* <div className='video_container'>
                    <iframe src=" https://www.youtube.com/embed/fHY8yOda9KM?si=QF2Eqx6NxJloyWUj" alt="video" />
                </div> */}
                <section className="Section_1">
                    <div className="Section_Contents">
                        <div className="Mid_Text">
                            <h1 className="gradient-animation">Bridge the _ gap;</h1>
                            {/* <AnimatedText /> */}
                            <h2>from graduates ------------- to job-ready</h2>
                            <h3>
                                With almost everything in the world relying on technology,
                                wanting for techies, especially good coders, will never end.
                                Coding is not theoretical; it’s practical. Do it to learn it.
                            </h3>
                        </div>
                        <div className="SVG_Container">
                            <div className="ScrollButton" onClick={handleScrollInto}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="80"
                                    height="80"
                                    viewBox="0 0 80 80"
                                    fill="none"
                                >
                                    <path
                                        className="Arrow_Down"
                                        d="M41.5 24C41.5 23.1716 40.8284 22.5 40 22.5C39.1716 22.5 38.5 23.1716 38.5 24H41.5ZM38.9393 57.0607C39.5251 57.6464 40.4749 57.6464 41.0607 57.0607L50.6066 47.5147C51.1924 46.9289 51.1924 45.9792 50.6066 45.3934C50.0208 44.8076 49.0711 44.8076 48.4853 45.3934L40 53.8787L31.5147 45.3934C30.9289 44.8076 29.9792 44.8076 29.3934 45.3934C28.8076 45.9792 28.8076 46.9289 29.3934 47.5147L38.9393 57.0607ZM38.5 24L38.5 56H41.5L41.5 24H38.5Z"
                                        fill="white"
                                    />
                                    <circle
                                        className="Circle"
                                        cx="40"
                                        cy="40"
                                        r="38.5"
                                        stroke="white"
                                        strokeWidth="3"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>
                </section>
                <div className='sample'>
                    <p>Enroll for the free webinar program on</p>
                    <h3>Python AI Developer</h3>
                </div>
                <div className='enroll'>
                    <p>Enroll for the free webinar program on</p>
                    <h3>AI Development</h3>
                </div>
                <div className='content'>
                    We all live in a world of AI, and this is going to stay for long. Every industry and sector is welcoming AI for their workloads to increase efficiency, and speed. AI is growing at a rapid pace and so is its adoption. This brings a whole lot of opportunities for techies to develop these AI products and systems the world is wanting. Today, there is an acute shortage of AI developers in the IT industry and the need for skilled AI developers is multiplying at a rapid pace. Our graduates are far from these skillsets even though they're graduated, with a wide gap between Graduated and Job-ready. Enrol for the Masterclass and explore the world of AI and how it can shape your future as an AI developer.

                </div>
                <div className='form-container-mc'
                // onClick={handleSubmit(OnSubmit)}
                // autoFocus
                // autoComplete="off"
                >
                    <div className='form-mc'>
                        <div className='from' >
                            <div className="Inputfield-mc">
                                <h3>
                                    First Name&nbsp;<span>*</span>
                                </h3>
                                <label>:</label>
                                <div className="InputField_box-mc">
                                    <input
                                        id="firstName"
                                        type="text"
                                        name="firstName"
                                        // {...register('firstName')}
                                        value={formData.firstName}
                                        onChange={handleChange}
                                        className="form-input-mc"
                                        autoComplete="off"
                                        required
                                    />
                                    {/* {errors.firstName && <p className="error-message">{errors.firstName.message}</p>} */}
                                </div>
                            </div>
                            <div className="Inputfield-mc">
                                <h3>
                                    Last Name&nbsp;<span>*</span>
                                </h3>
                                <label>:</label>
                                <div className="InputField_box-mc">
                                    <input
                                        id="lastName"
                                        type="text"
                                        name="lastName"
                                        // {...register('lastName')}
                                        value={formData.lastName}
                                        onChange={handleChange}
                                        className="form-input-mc"
                                        autoComplete="off"
                                        required
                                    />
                                    {/* {errors.lastName && <p className="error-message">{errors.lastName.message}</p>} */}

                                </div>
                            </div>
                            <div className="Inputfield-mc">
                                <h3>
                                    Email-ID&nbsp;<span>*</span>
                                </h3>
                                <label>:</label>
                                <div className="InputField_box_email-mc">
                                    <input id="email" type="email" className="form-input-mc" autoComplete="off"
                                        required name="email"
                                        // {...register('email')}
                                        value={formData.email}
                                        onChange={handleChange} />
                                    <button onClick={handleGetOtpClick} disabled={!isEmailValid || seconds === 0} className="inside-button-mc">{buttonText}</button>
                                    {!isOtpVerified && otpVisible && <div className='containter1_otp'>
                                        <p>Time Remaining : {seconds}sec</p>
                                        <p onClick={handleResendOtpClick} >Resend OTP</p>
                                    </div>}
                                </div>
                            </div>
                            <div className='warning '>
                                {emailError && <p style={{ color: 'red', 'textAlign': 'end', 'fontSize': '10px', 'background': '#1E1E1E' }}>{emailError}</p>}
                                {otpVisible && !isOtpVerified && (
                                    <div className="otp-field-container-mc">
                                        <div className="otp-inputs-mc">
                                            {otp.map((data, index) => (
                                                <input
                                                    key={index}
                                                    className="otp-field-mc"
                                                    type="text"
                                                    maxLength="1"
                                                    value={data}
                                                    onChange={e => handleOtpChange(e.target, index)}
                                                    onKeyDown={e => handleKeyDown(e.target, index, e)}
                                                    onFocus={e => e.target.select()}
                                                />
                                            ))}

                                        </div>
                                        {otpError && <p style={{ color: 'red', 'textAlign': 'end', 'fontSize': '10px', 'background': '#1E1E1E' }}>{otpError}</p>}
                                    </div>
                                )}
                                {isOtpVerified && <p style={{ 'textAlign': 'end', 'fontSize': '10px', 'background': '#1E1E1E' }}>Email Verified!</p>}

                            </div>
                            <div className="Inputfield-mc">
                                <h3>
                                    Mobile Number&nbsp;<span>*</span>
                                </h3>
                                <label>:</label>
                                <div className="InputField_box-mc">
                                    <input
                                        id="mobile"
                                        type="tel"
                                        value={mobileNumber}
                                        name="mobileNumber"
                                        // {...register('mobileNumber')}
                                        // value={formData.mobileNumber}
                                        onChange={handleNumChangeM}
                                        maxLength={10}
                                        // onChange={handleNumChange}
                                        className="form-input-mc"
                                        autoComplete="off"
                                        required
                                    />
                                    <p style={{ color: 'red', 'textAlign': 'end', 'fontSize': '10px', 'background': '#1E1E1E' }}>{mobileError}</p>
                                    {/* {errors.mobileNumber && <p className="error-message">{errors.mobileNumber.message}</p>} */}
                                </div>
                            </div>
                            <div className="Inputfield-mc">
                                <h3>
                                    College&nbsp;<span>*</span>
                                </h3>
                                <label>:</label>
                                <div className="InputField_box-mc">
                                    <input
                                        id="college"
                                        type="text"
                                        name="college"
                                        // {...register('college')}
                                        value={formData.college}
                                        onChange={handleChange}
                                        className="form-input-mc"
                                        autoComplete="off"
                                        required
                                    />
                                    {/* {errors.college && <p className="error-message">{errors.college.message}</p>} */}
                                </div>
                            </div>
                            <div className="Inputfield-mc">
                                <h3>
                                    Branch / Stream&nbsp;<span>*</span>
                                </h3>
                                <label>:</label>
                                <div className="InputField_box-mc">
                                    <input
                                        id="branch"
                                        type="text"
                                        name="branch"
                                        // {...register('branch')}
                                        value={formData.branch}
                                        onChange={handleChange}
                                        className="form-input-mc"
                                        autoComplete="off"
                                        required
                                    />
                                    {/* {errors.branch && <p className="error-message">{errors.branch.message}</p>} */}
                                </div>
                            </div>
                            <div className="Inputfield-mc">
                                <h3>
                                    Semester&nbsp;<span>*</span>
                                </h3>
                                <label>:</label>
                                <div className="InputField_box-mc">
                                    <select id="semester" className="Selectbox-mc" autoComplete="off"
                                        required
                                        // {...register('selectedSemester')}
                                        value={selectedSemester}
                                        onChange={handleSemesterChange}>
                                        <option value="" disabled hidden>
                                            Select Semester
                                        </option>
                                        <option value="1_sem">I Semester</option>
                                        <option value="2_sem">II Semester</option>
                                        <option value="3_sem">III Semester</option>
                                        <option value="4_sem">IV Semester</option>
                                        <option value="5_sem">V Semester</option>
                                        <option value="6_sem">VI Semester</option>
                                        <option value="7_sem">VII Semester</option>
                                        <option value="8_sem">VIII Semester</option>
                                    </select>
                                    {/* {errors.selectedSemester && <p className="error-message">{errors.selectedSemester.message}</p>} */}
                                </div>
                            </div>
                            <div className="Inputfield-mc">
                                <h3>
                                    Pincode&nbsp;<span>*</span>
                                </h3>
                                <label>:</label>
                                <div className="InputField_box-mc">
                                    <input
                                        type="number"
                                        value={pin}
                                        minLength={6}
                                        // {...register('pin')}
                                        onChange={handlePinChange}
                                        className="form-input-mc"
                                        autoComplete="off"
                                        required
                                    />
                                    {/* {errors.pin && <p className="error-message">{errors.pin.message}</p>} */}
                                </div>
                            </div>
                            <div className="Inputfield-mc">
                                <h3>
                                    City&nbsp;<span>*</span>
                                </h3>
                                <label>:</label>
                                <div className="InputField_box-mc">
                                    <input
                                        id="city"
                                        // {...register.city}
                                        value={city}
                                        readOnly
                                        type="text"
                                        className="form-input-mc"
                                        required="required"
                                        disabled
                                    />
                                </div>
                            </div>
                            {/* <div className="Suggestion_area-mc">
                                <h3>
                                    Why you want to attend this Webinar?&nbsp;<span>*</span>
                                </h3>
                                <div className="TextArea_box-mc">
                                    <textarea
                                        className="form-input-mc"
                                        rows="7"
                                        name="message"
                                        value={formData.message}
                                        // cols="65"
                                        onChange={handleChange}
                                        required
                                    ></textarea>
                                </div>
                            </div> */}
                            <div className="buttonS-mc">
                                <button type="submit" onClick={OnSubmit} className="Button-mc" >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                    {showPopup && (
                        <>
                            <div className="Overlay" />
                            <div className="Popup">
                                <img
                                    src={logo4}
                                    alt="Close"
                                    className="CloseButton"
                                    onClick={() => setShowPopup(false)}
                                />
                                {/* <img src={logo3} alt="Popup" /> */}
                                <span>
                                    <h1>Hey! Thanks for Registering for Master Class on AI Developer.</h1>
                                    <br />
                                    <h3>
                                        One of them from Codingeni will reach you with details.
                                    </h3>
                                </span>
                                {/* <button className='close' >Close</button> */}
                            </div>
                        </>
                    )}
                </div>
            </div >
        </>
    )
}
export default MasterClassAI;