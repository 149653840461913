import "../../PythonLandingPages.css";
import { Helmet } from "react-helmet";
import Header from "../../../Header/Header";
import THeader from "../../../THeader/THeader";
import Form from "../../../Form/Form";
import Footer from "../../../Footer/Footer";
import image from "../../../assets/UI.svg";
import icon1 from "../../../assets/Figma.svg";
import icon2 from "../../../assets/XD.svg";
import icon3 from "../../../assets/Smiley.svg";
import icon4 from "../../../assets/AI.svg";
import icon5 from "../../../assets/PS.svg";
import image1 from "../../../assets/uiux-1.svg";
import { useRef } from "react";

const UIUXpage = () => {
  const text1 = "{ Codingeni }";
  const formRef = useRef(null);
  const handleScrollInto = () => {
    formRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div className="Main">
      <Helmet>
        <title>UI/UX & Graphic Designing Course online in Tumkuru</title>
        <meta
          name="description"
          content="Codingeni provide UI/UX and graphic designing with our online course in Tumkuru. Learn to create intuitive interfaces and delightful user experiences. Enroll now
          "
        />
        <meta
          name="keyword"
          content="ui ux design course online in Tumkuru,ux design course online in Tumkuru, ui ux online course in Tumkuru,ui ux certification course online in Tumkuru, Graphi designing course online in Tumkuru
          "
        />
        <link
          rel="canonical"
          href="https://www.codingeni.com/online-UI-UX-graphic-designing-development-training-course-Tumkuru"
        />
      </Helmet>
      <THeader />
      <Header />
      <section className="Section_1">
        <div className="Section_Contents">
          <div className="Mid_Text">
            <h1 className="gradient-animation">Bridge the _ gap;</h1>
            {/* <AnimatedText /> */}
            <h2>from graduates ------------- to job-ready</h2>
            <h3>
              With almost everything in the world relying on technology, wanting
              for techies, especially good coders, will never end. Coding is not
              theoretical; it’s practical. Do it to learn it.
            </h3>
          </div>
        </div>
      </section>
      <div className="Text_Holder section_2">
        <h2>{text1}</h2>
        <p>
          Welcome to Techready, a bridge program taking you to the career and
          industry you’re looking forward to with building some of the best and
          exciting products & projects, equipping you with real world,
          commercial product developments in artificial intelligence, deep
          learning, IoT and robotics. From training to fulltime commercial
          internships to jobs, techready gets you ready for the industry not
          just by writing on a board, but you will be working on live commercial
          projects, building disruptive products in deep learning or full stack
          or IoT embedded platform, whichever stream you choose
        </p>
        <p>
          Our approach to learning is <span>‘Build to Learn’</span> wherein
          every intern with us will be developing products in AI mentored,
          guided by our experienced developers and engineers. This gives you the
          edge to learn while to build with an unmatched experience of
          developing a full scale commercial product that’s sold to our
          customers.
        </p>
        <p>
          The difference is that you become an experienced AI developer during
          this Build to Learn Transform program with Techready and you’re
          industry ready matching the skills industry wants, thereby increasing
          the chances of you getting your dream AI job.
        </p>
      </div>
      <div className="Ai_Developement">
        <div className="Text_Holder">
          <h1>UIUX Design</h1>
          <h3>In Tumkuru</h3>
          <p>
            Without designers, there is no software or IT products in the world.
            Whether it’s website, applications, apps, IoT or Robotics, UI, UX
            and Graphic designers are a must in every company especially in the
            IT sector. Designers play a pivotal role in every product design and
            development.
          </p>
          <div className="Apply_Now_button">
            <button className="Apply" onClick={handleScrollInto}>
              Click for Enquiry
            </button>
          </div>
        </div>
        <div className="Ai_Image">
          <img src={image1} alt="Ai_Develop" />
        </div>
      </div>
      <div className="Text_Holder">
        <h1>UIUX Design</h1>
        <p>
          Design industry is growing at a rapid speed. Every new or old business
          is going for digital transformation, the designing comes hand in hand
          with it. Business houses know where their audience are and they make
          every effort to reach them. That is where designers get chance to
          showcase their skillset and help businesses to grow through Design
          Thinking, Market Research and Execution. In terms of scope, UI/UX
          design can be a pretty broad field, as it comprises many different
          aspects of design and user experience. UI UX designer demand has been
          on the rise across various industries such as Healthcare, Tech,
          Gaming, Finance, Education and Retail due to the growing importance of
          user-centric design.
        </p>
        <div className="Text_button_Holder">
          <h1>Few works of our Interns </h1>
          <div className="Apply_Now_button">
            <a
              href="https://www.youtube.com/playlist?list=PL6H5Bk4cZ3tDrXWaCB2aLzOmw2iyczLLl"
              target="_blank"
              rel="noreferrer"
            >
              <button className="Apply">Click Here</button>
            </a>
          </div>
        </div>
        <div className="Image_Holder_Landingpage">
          <div className="Animation_Holder_Landingpage">
            <div className="Circle_Animation_Landingpage"></div>
            <img src={image} alt="Computer Vision" />
          </div>
        </div>
      </div>
      <div className="Tools_Holder">
        <h1>Tools & Languages you will be trained on</h1>
        <div className="Tool_Container">
          <div className="Holder">
            <p>1.Figma</p>
            <img src={icon1} alt="Figma" />
          </div>
          <div className="Holder">
            <p>2.Adobe XD</p>
            <img src={icon2} alt="XD" />
          </div>
          <div className="Holder">
            <p>3.Balsamiq</p>
            <img src={icon3} alt="Balsamiq" />
          </div>
          <div className="Holder">
            <p>4.Adobe Illustrator</p>
            <img src={icon4} alt="Illustrator" />
          </div>
          <div className="Holder">
            <p>5.Adobe Photoshop</p>
            <img src={icon5} alt="Photoshop" />
          </div>
        </div>
      </div>
      {/* <div className="Apply_button">
        <a href="/enroll">
          <button className="Apply">Enrol</button>
        </a>
      </div> */}

      <div ref={formRef} className="Form_1">
        <Form />
      </div>
      <Footer />
    </div>
  );
};

export default UIUXpage;
