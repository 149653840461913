import React from "react";
import "./Methodolody.css";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import Header from "../Header/Header";
import THeader from "../THeader/THeader";
import Footer from "../Footer/Footer";
import Form from "../Form/Form";
import logo from "../assets/Mpage.svg";
import AOS from "aos";
import "aos/dist/aos.css";

const Methodology = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);
  return (
    <div className="Main">
      <Helmet>
        <title>
          Codingeni- Online Course, Learning Platform and Certifications
        </title>
        <meta
          name="description"
          content="At Codingeni, we have carved a unique path of training, mentoring and guiding freshers to coding experts. Learn, grow, and succeed with our online training programs. Transform your career and personal development today.
          "
        />
        <meta
          name="keyword"
          content="Codingeni, python online course, python programming online, computer vision course online, full stack developer course online, best android app development course, ios developer online course, ui ux design course online
          "
        />
        <link
          rel="canonical"
          href="https://www.codingeni.com/online-training"
        />
      </Helmet>
      <THeader />
      <Header />
      <section className="firstsec_cont">
        <div className="firstsec">
          <div className="Image_Container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="90"
              height="55"
              viewBox="0 0 90 55"
              fill="none"
              className="wifi-symbol"
            >
              <path
                d="M53.8493 48.5889L45.2216 54.7806L36.5479 48.656C37.5245 47.273 38.8177 46.1436 40.3195 45.3619C41.8213 44.5802 43.4882 44.169 45.1813 44.1624C46.8743 44.1559 48.5444 44.5542 50.0523 45.3242C51.5601 46.0942 52.862 47.2136 53.8493 48.5889Z"
                fill="#0F0"
              />
              <path
                d="M45.2215 29.0757C41.1035 29.0759 37.046 30.0679 33.3925 31.9678C29.7389 33.8677 26.5966 36.6196 24.2314 39.9907L29.7088 43.8364C31.4526 41.3596 33.7642 39.3365 36.4503 37.9364C39.1364 36.5364 42.1187 35.8001 45.1478 35.7893C48.1768 35.7784 51.1645 36.4932 53.8605 37.874C56.5566 39.2547 58.8827 41.2612 60.6441 43.7254L66.0901 39.8127C63.716 36.4925 60.5843 33.7865 56.9549 31.9192C53.3255 30.0518 49.3031 29.077 45.2215 29.0757Z"
                fill="#0F0"
              />
              <path
                d="M45.2219 0.402474C26.871 0.402474 10.642 9.52241 0.805664 23.4704L6.28308 27.3161C10.6611 21.0971 16.4649 16.0171 23.2088 12.5012C29.9528 8.98521 37.4407 7.13559 45.0461 7.10709C52.6515 7.07858 60.1532 8.87201 66.9233 12.3373C73.6935 15.8026 79.5351 20.839 83.9596 27.025L89.3909 23.1122C84.3686 16.0837 77.7404 10.3561 70.0578 6.4061C62.3753 2.45606 53.8605 0.397768 45.2219 0.402474Z"
                fill="#0F0"
              />
              <path
                d="M45.2216 16.087C39.0186 16.0851 32.9055 17.5699 27.3944 20.4169C21.8834 23.2639 17.1349 27.3902 13.5469 32.4501L19.0243 36.2958C21.9871 32.1368 25.8974 28.7427 30.4318 26.3942C34.9661 24.0456 39.9944 22.81 45.1008 22.7895C50.2073 22.7691 55.2453 23.9643 59.7983 26.2765C64.3513 28.5886 68.2887 31.9512 71.2848 36.0864L76.7161 32.1967C73.119 27.2075 68.3874 23.1449 62.9115 20.344C57.4356 17.543 51.3723 16.0839 45.2216 16.087Z"
                fill="#0F0"
              />
            </svg>
            <img src={logo} alt="Methodology" />
          </div>
          <div className="section_paras" data-aos="fade-up">
            <h1>Online, Developer-led program.</h1>
            <p>
              <span> &lt; </span>&emsp;Oh! There are lots of training
              institutes, and there are a lot of online classes. Fortunately,
              we’re not in that line. At Codingeni, we have carved a unique path
              of training, mentoring and guiding freshers to coding experts.
              <br />
              <br />
              Here we do online training, but on the other side is our
              experienced developer who is connected with you live, instructor
              led sessions. We don’t do recorded classes or bot driven
              classes.&emsp;<span>&gt;</span>
            </p>
          </div>
          <div className="section_paras_2" data-aos="fade-up">
            <h1>Learn from the experienced Coders.</h1>
            <p>
              <span> &lt; </span>&emsp;You can never learn swimming from a
              novice, but only from a swimming coach. You cannot learn to drive
              a car from a book or an online class, but learn from an
              experienced driver.
              <br />
              <br /> Similarly you cannot learn coding from a faculty because if
              the faculty was good at coding, he would be a developer and not a
              faculty.
              <br />
              <br /> Here you learn coding from our experienced developers
              involved in coding some of the most complex products and projects
              as fulltime developers at Okulr Techminds. So you’re in the best
              hands to become an expert coder.&emsp;<span>&gt;</span>
            </p>
          </div>
          <div className="section_paras_3" data-aos="fade-up">
            <h1>Coding live projects.</h1>
            <p>
              <span> &lt; </span>&emsp; Coding is something you’ll learn more
              while you code live projects and not just learn coding. The more
              you develop interesting and challenging projects, the better coder
              you become.
              <br />
              <br /> You’ll build to learn at Codingeni. We give an opportunity
              to code on some of our commercial live projects that’s sold to our
              customers which means you get to learn coding the best possible
              way of developing a project or a product.&emsp;<span>&gt;</span>
            </p>
          </div>
          <div className="section_paras_4" data-aos="fade-up">
            <h1>Certified Coder.</h1>
            <p>
              <span> &lt;</span>&emsp; You complete your term, clear
              evaluations, do up a project and you’re ready as a Codingeni
              Certified Coder (3C). You’ll take with you the skillsets as coding
              expert in the stream you chose and the confidence to take up any
              challenging projects as a coder.
              <br />
              <br />
              We will provide you the space to upload your video resume, 3C
              certificate and the project you’ve done, which our team of
              placement assistance will do all possible to showcase your skills
              to the tech companies to help you get your first job as a
              developer.&emsp;<span>&gt;</span>
            </p>
          </div>
        </div>
      </section>
      <section className="secondsec_cont" data-aos="zoom-in-up">
        <div className="Secondsec">
          <div className="section_questions">
            <h1>Why Codingeni? The Difference.</h1>
            <div className="Points">
              <p>1.</p>
              <p>
                You’re not taught by a faculty, but an experienced Developer.
              </p>
            </div>
            <div className="line"></div>
            <div className="Points">
              <p>2.</p>
              <p>
                You’ll not be working on some lab projects but will be coding on
                live commercial projects giving you the experience and edge of
                gaining core knowledge and not bookish.
              </p>
            </div>

            <div className="line"></div>
            <div className="Points">
              <p>3.</p>
              <p>
                You’ll not be learning from some recorded classes, but
                Developer-led live online sessions.
              </p>
            </div>

            <div className="line"></div>
            <div className="Points">
              <p>4.</p>
              <p>
                You’re evaluated rigorously in your coding capability at
                frequent intervals making you a thorough coder.
              </p>
            </div>

            <div className="line"></div>
            <div className="Points">
              <p>5.</p>
              <p>
                We focus on core coding skills minus the crap of ‘we’ll do this,
                we’ll do that’. All we do is to ensure you become a skilled
                Coder or in other words Codegeni that attracts the best
                employers to you.
              </p>
            </div>

            <div className="line"></div>
          </div>
        </div>
      </section>
      <Form />
      <Footer />
    </div>
  );
};

export default Methodology;
