import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import "../Applypage/Applypage.css";
import "./Enrollpage.css";
import logo1 from "../assets/Techreadypic_1.svg";
import WebFont from "webfontloader";
import FileUpload from "../components/FileUpload";
import Preview from "../components/Preview";
import { Outlet } from "react-router-dom";
import THeader from "../THeader/THeader";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [value, delay]);

  return debouncedValue;
}

const Enrollpage = () => {
  const [pin, setNum] = useState("");
  const [mob, setNum1] = useState("");
  const [a, setA] = useState("");
  const [preview, setPreview] = useState(false);
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");

  const [fileLabels, setFileLabels] = useState({
    StudentPhoto: "Upload your Photo",
    StudentAadharCard: "Upload your Aadhar",
    CollegeIDCard: "Upload College ID Card",
  });

  const clearFile = (field) => {
    setFileLabels((prevLabels) => ({
      ...prevLabels,
      [field]: "Upload a file",
    }));
  };
  const handleNumChangeM = (event) => {
    const numericValue = event.target.value.replace(/\D/g, "");
    const limit1 = 10;
    setNum1(numericValue.slice(0, limit1));
  };
  const handleNumChangeA = (event) => {
    const numericValue = event.target.value.replace(/\D/g, "");
    const limit1 = 12;
    setA(numericValue.slice(0, limit1));
  };

  const updateLabel = (event, inputId) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      if (selectedFile.size > 2097152 || selectedFile.size === 4096) {
        alert("File is too big!");
      } else {
        const newLabels = { ...fileLabels };
        newLabels[inputId] = selectedFile.name || "Please upload the file";
        setFileLabels(newLabels);
      }
    } else {
      alert("Please select a file");
    }
  };

  const apiKey = "3af4f7b81d014bdfb6aaa954ce3a6dfe";
  const apiUrl = `https://api.opencagedata.com/geocode/v1/json?q=${pin}&key=${apiKey}`;

  const debouncedNum = useDebounce(pin, 500);

  useEffect(() => {
    if (debouncedNum.trim() === "") {
      setCity("");
      setState("");
      setCountry("");
    } else {
      if (debouncedNum.length === 6) {
        fetch(apiUrl)
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then((data) => {
            if (data.status.code === 200) {
              const results = data.results[0];
              const city = results.components.city;
              const state = results.components.state;
              const country = results.components.country;
              setCity(city);
              setState(state);
              setCountry(country);
              setFormData((previousState) => {
                return {
                  ...previousState,
                  city: city,
                  state: state,
                  country: country,
                };
              });
            }
          })
          .catch((error) => {
            console.log(error.message);
          });
      }
    }
  }, [apiUrl, debouncedNum]);

  const handleNumChangeP = (event) => {
    var numericValue = event.target.value.replace(/\D/g, "");
    numericValue = numericValue.slice(0, 6);
    setNum(numericValue);
  };

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    motherName: "",
    fatherName: "",
    email: "",
    mobile: mob,
    degree: "",
    college: "",
    university: "",
    addressLine1: "",
    addressLine2: "",
    pincode: pin,
    city: city,
    state: state,
    country: country,
    stream: "",
    aadhar: a,
    StudentPhoto: "",
    StudentAadharCard: "",
    CollegeIDCard: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const handleChangeFile = (e) => {
    const { id, files } = e.target;
    // setFormData({
    //   ...formData,
    //   [id]: files[0],
    // });
    formData[id] = files[0];
    console.log(formData);
  };

  const handleBlur = (e) => {
    const { id, value } = e.target;
    let newErrors = { ...errors };

    switch (id) {
      case "firstName":
      case "lastName":
      case "motherName":
      case "fatherName":
      case "degree":
      case "college":
      case "university":
      case "addressLine1":
      case "addressLine2":
      case "city":
      case "state":
        if (value.trim() === "") {
          newErrors[id] = "This field is required";
        } else {
          newErrors[id] = "";
        }
        break;
      case "email":
        if (!value.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {
          newErrors[id] = "Invalid email address";
        } else {
          newErrors[id] = "";
        }
        break;
      case "mobile":
      case "aadhar":
      case "pincode":
        if (value.trim() === "" || isNaN(value)) {
          newErrors[id] = "This field must be a number";
        } else {
          newErrors[id] = "";
        }
        break;
      default:
        break;
    }

    setErrors(newErrors);
  };

  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Open Sans"],
      },
    });
  }, []);

  const handlePreview = () => {
    setPreview(true);
  };

  const closeModel = () => {
    setPreview(false);
  };

  const videoUrls = [
    "https://www.youtube.com/embed/77hhoNE0UK0",
    "https://www.youtube.com/embed/jvExiX2yMBc",
    "https://www.youtube.com/embed/34BaT1z8NXk",
    "https://www.youtube.com/embed/_ZByCYhKNeo",
    "https://www.youtube.com/embed/IJjpQNZQOSc",
    "https://www.youtube.com/embed/WiHFwhGoJm8",
    "https://www.youtube.com/embed/_biGfmuIld4",
    "https://www.youtube.com/embed/l3Ys8UekYWQ",
    "https://www.youtube.com/embed/b6mi9bRIV0o",
  ];
  const [currentIndex1, setCurrentIndex1] = useState(0);

  const handlePrevClick = () => {
    setCurrentIndex1((prevIndex) => Math.max(prevIndex - 3, 0));
  };

  const handleNextClick = () => {
    setCurrentIndex1((prevIndex) => Math.min(prevIndex + 3, 6));
  };
  const [currentIndex2, setCurrentIndex2] = useState(0);

  const handlePrevClickM = () => {
    setCurrentIndex2((prevIndex) =>
      prevIndex === 0 ? videoUrls.length - 1 : prevIndex - 1
    );
  };

  const handleNextClickM = () => {
    setCurrentIndex2((prevIndex) =>
      prevIndex === videoUrls.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleSubmitForm = () => {
    const isFormDataEmpty = Object.entries(formData).some(
      ([key, value]) => key !== "addressLine2" && value === ""
    );

    if (isFormDataEmpty) {
      alert("One or more fields are empty.");
      console.log(formData);
    } else {
      handlePreview();
    }
  };

  const [showPrice, setPrice] = useState(false);

  return (
    <div className="Main">
      <Helmet>
        <title>Learn, Grow, Succeed: Online Training Programs- Codingeni</title>
        <meta
          name="description"
          content="Join us today and experience the ease. Learn, grow, and succeed with our online training programs. Transform your career and personal development today.
          "
        />
        <meta
          name="keyword"
          content="Codingeni, online training course in India, online learning platform in India, online course in India, online training course, online learning platform, learning courses, best online coding courses, best online courses platform.
          "
        />
        <link rel="canonical" href="https://www.codingeni.com/apply" />
      </Helmet>
      <THeader />
      <Header />
      <div className="Main">
        <div className="Image_Top">
          <img src={logo1} alt="Class to Cubicle" />
          <h1>Graduated, but how many are Industry ready?</h1>
          <p>
            India has the highest number of engineering colleges, producing
            maximum number of engineers in the world. Estimates say more than
            63% of our young engineering graduates are yet to become work ready,
            primarily due to the lack of skillsets the industry wants.
          </p>
        </div>
        <div className="Mid_Section">
          <h1>&#123; Enrol with Codingeni &#125;</h1>

          <h3>
            Hey! We’re happy to know that you wish to take up coding to scale
            the tech world. At Codingeni you’ll be building while you learn with
            our experienced developers with the comfort of online developer-led
            online sessions.
          </h3>
          <h3>
            Take a look at few of the projects our students have worked upon
            which you could also be part of. These are live commercial projects
            and few of them are mission critical, and we’re proud that our
            students could scale to this stage with their coding skills.
          </h3>
          <div className="Safepro_Carousel">
            <h1>
              Take a look at few of exciting AI products and projects you’ll be
              part of.
            </h1>
            <div className="carousel-container">
              <div className="Button_Left" onClick={handlePrevClick}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  className={`left-button ${
                    currentIndex1 === 0 ? "hidden" : ""
                  }`}
                >
                  <path
                    className="Arrow_Left"
                    d="M28 19C28.5523 19 29 19.4477 29 20C29 20.5523 28.5523 21 28 21V19ZM11.2929 20.7071C10.9024 20.3166 10.9024 19.6834 11.2929 19.2929L17.6569 12.9289C18.0474 12.5384 18.6805 12.5384 19.0711 12.9289C19.4616 13.3195 19.4616 13.9526 19.0711 14.3431L13.4142 20L19.0711 25.6569C19.4616 26.0474 19.4616 26.6805 19.0711 27.0711C18.6805 27.4616 18.0474 27.4616 17.6569 27.0711L11.2929 20.7071ZM28 21L12 21V19L28 19V21Z"
                    fill="white"
                  />
                  <circle
                    className="Circle"
                    cx="20"
                    cy="20"
                    r="19"
                    transform="matrix(-1 0 0 1 40 0)"
                    stroke="white"
                    stroke-width="2"
                  />
                </svg>
              </div>
              <div className="Image_carousel">
                <div
                  className="carousel-inner"
                  style={{
                    transform: `translateX(-${currentIndex1 * (101 / 9)}%)`,
                  }}
                >
                  <iframe
                    title="Facesense Demo Video"
                    src="https://www.youtube.com/embed/77hhoNE0UK0"
                  ></iframe>
                  <iframe
                    title="Facesense Demo Video"
                    src="https://www.youtube.com/embed/jvExiX2yMBc"
                  ></iframe>
                  <iframe
                    title="Facesense Demo Video"
                    src="https://www.youtube.com/embed/34BaT1z8NXk"
                  ></iframe>
                  <iframe
                    title="Facesense Demo Video"
                    src="https://www.youtube.com/embed/_ZByCYhKNeo"
                  ></iframe>
                  <iframe
                    title="Facesense Demo Video"
                    src="https://www.youtube.com/embed/IJjpQNZQOSc"
                  ></iframe>
                  <iframe
                    title="Facesense Demo Video"
                    src="https://www.youtube.com/embed/WiHFwhGoJm8"
                  ></iframe>
                  <iframe
                    title="Facesense Demo Video"
                    src="https://www.youtube.com/embed/_biGfmuIld4"
                  ></iframe>
                  <iframe
                    title="Facesense Demo Video"
                    src="https://www.youtube.com/embed/l3Ys8UekYWQ"
                  ></iframe>
                  <iframe
                    title="Facesense Demo Video"
                    src="https://www.youtube.com/embed/b6mi9bRIV0o"
                  ></iframe>
                </div>
              </div>
              <div className="Button_Right" onClick={handleNextClick}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  className={`right-button ${
                    currentIndex1 === 6 ? "hidden" : ""
                  }`}
                >
                  <path
                    className="Arrow_Right"
                    d="M12 19C11.4477 19 11 19.4477 11 20C11 20.5523 11.4477 21 12 21V19ZM28.7071 20.7071C29.0976 20.3166 29.0976 19.6834 28.7071 19.2929L22.3431 12.9289C21.9526 12.5384 21.3195 12.5384 20.9289 12.9289C20.5384 13.3195 20.5384 13.9526 20.9289 14.3431L26.5858 20L20.9289 25.6569C20.5384 26.0474 20.5384 26.6805 20.9289 27.0711C21.3195 27.4616 21.9526 27.4616 22.3431 27.0711L28.7071 20.7071ZM12 21L28 21V19L12 19V21Z"
                    fill="white"
                  />
                  <circle
                    className="Circle"
                    cx="20"
                    cy="20"
                    r="19"
                    stroke="white"
                    stroke-width="2"
                  />
                </svg>
              </div>
            </div>
            <div className="carousel-container-mobile">
              <div className="Button_Left" onClick={handlePrevClickM}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  className={`left-button ${
                    currentIndex1 === 0 ? "hidden" : ""
                  }`}
                >
                  <path
                    className="Arrow_Left"
                    d="M28 19C28.5523 19 29 19.4477 29 20C29 20.5523 28.5523 21 28 21V19ZM11.2929 20.7071C10.9024 20.3166 10.9024 19.6834 11.2929 19.2929L17.6569 12.9289C18.0474 12.5384 18.6805 12.5384 19.0711 12.9289C19.4616 13.3195 19.4616 13.9526 19.0711 14.3431L13.4142 20L19.0711 25.6569C19.4616 26.0474 19.4616 26.6805 19.0711 27.0711C18.6805 27.4616 18.0474 27.4616 17.6569 27.0711L11.2929 20.7071ZM28 21L12 21V19L28 19V21Z"
                    fill="white"
                  />
                  <circle
                    className="Circle"
                    cx="20"
                    cy="20"
                    r="19"
                    transform="matrix(-1 0 0 1 40 0)"
                    stroke="white"
                    stroke-width="2"
                  />
                </svg>
              </div>
              <div className="Image_carousel">
                <div
                  className="carousel-inner"
                  style={{
                    transform: `translateX(-${currentIndex2 * (104.5 / 9)}%)`,
                  }}
                >
                  {videoUrls.map((url, index) => (
                    <iframe
                      key={index}
                      title={`Facesense Demo Video ${index}`}
                      src={url}
                    ></iframe>
                  ))}
                </div>
              </div>
              <div className="Button_Right" onClick={handleNextClickM}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  className={`right-button ${
                    currentIndex1 === 6 ? "hidden" : ""
                  }`}
                >
                  <path
                    className="Arrow_Right"
                    d="M12 19C11.4477 19 11 19.4477 11 20C11 20.5523 11.4477 21 12 21V19ZM28.7071 20.7071C29.0976 20.3166 29.0976 19.6834 28.7071 19.2929L22.3431 12.9289C21.9526 12.5384 21.3195 12.5384 20.9289 12.9289C20.5384 13.3195 20.5384 13.9526 20.9289 14.3431L26.5858 20L20.9289 25.6569C20.5384 26.0474 20.5384 26.6805 20.9289 27.0711C21.3195 27.4616 21.9526 27.4616 22.3431 27.0711L28.7071 20.7071ZM12 21L28 21V19L12 19V21Z"
                    fill="white"
                  />
                  <circle
                    className="Circle"
                    cx="20"
                    cy="20"
                    r="19"
                    stroke="white"
                    stroke-width="2"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div className="Brochure_Section">
          <div className="Brochure_Contents">
            <h1>
              <strong>
                Go ahead, Enrol & choose the program you want to excel & make a
                successful career as a Developer in the tech world.
              </strong>
            </h1>
          </div>
        </div>
        <div className="Forms">
          <div id="Form_Block" className="Form_Block">
            <br />
            <h1>Student Enrolment Form</h1>
            <h2>
              (You can enrol & make an online payment with multiple payment
              modes (UPI, Net banking, Debit/Credit cards) including
              interest-free EMI options available on your credit/debit cards)
            </h2>
            <form className="Form_Enroll" id="Feedback">
              <div className="Inputfield">
                <h3>
                  First Name <span>*</span>
                </h3>
                <label>:</label>
                <div className="InputField_box">
                  <input
                    id="firstName"
                    type="text"
                    className="form-input"
                    required
                    value={formData.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.firstName && (
                    <h6 className="error">{errors.firstName}</h6>
                  )}
                </div>
              </div>

              <div className="Inputfield">
                <h3>
                  Last Name <span>*</span>
                </h3>
                <label>:</label>
                <div className="InputField_box">
                  <input
                    id="lastName"
                    type="text"
                    className="form-input"
                    required
                    value={formData.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.lastName && (
                    <h6 className="error">{errors.lastName}</h6>
                  )}
                </div>
              </div>
              <div className="Inputfield">
                <h3>
                  Mother Name <span>*</span>
                </h3>
                <label>:</label>
                <div className="InputField_box">
                  <input
                    id="motherName"
                    type="text"
                    className="form-input"
                    required
                    value={formData.motherName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.motherName && (
                    <h6 className="error">{errors.motherName}</h6>
                  )}
                </div>
              </div>
              <div className="Inputfield">
                <h3>
                  Father Name <span>*</span>
                </h3>
                <label>:</label>
                <div className="InputField_box">
                  <input
                    id="fatherName"
                    type="text"
                    className="form-input"
                    required
                    value={formData.fatherName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.fatherName && (
                    <h6 className="error">{errors.fatherName}</h6>
                  )}
                </div>
              </div>
              <div className="Inputfield">
                <h3>
                  Email-ID <span>*</span>
                </h3>
                <label>:</label>
                <div className="InputField_box">
                  <input
                    id="email"
                    type="email"
                    className="form-input"
                    required
                    value={formData.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.email && <h6 className="error">{errors.email}</h6>}
                </div>
              </div>
              <div className="Inputfield">
                <h3>
                  Mobile Number <span>*</span>
                </h3>
                <label>:</label>
                <div className="InputField_box">
                  <input
                    id="mobile"
                    type="number"
                    minLength={10}
                    className="form-input"
                    required
                    value={mob}
                    onChange={(event) => {
                      handleChange(event);
                      handleNumChangeM(event);
                    }}
                    onBlur={handleBlur}
                  />
                  {errors.mobile && <h6 className="error">{errors.mobile}</h6>}
                </div>
              </div>
              <div className="Inputfield">
                <h3>
                  Studying / Degree<span>*</span>
                </h3>
                <label>:</label>
                <div className="InputField_box">
                  <input
                    id="degree"
                    type="text"
                    className="form-input"
                    required
                    value={formData.degree}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.degree && <h6 className="error">{errors.degree}</h6>}
                </div>
              </div>
              <div className="Inputfield">
                <h3>
                  College <span>*</span>
                </h3>
                <label>:</label>
                <div className="InputField_box">
                  <input
                    id="college"
                    type="text"
                    className="form-input"
                    required
                    value={formData.college}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.college && (
                    <h6 className="error">{errors.college}</h6>
                  )}
                </div>
              </div>
              <div className="Inputfield">
                <h3>
                  University <span>*</span>
                </h3>
                <label>:</label>
                <div className="InputField_box">
                  <input
                    id="university"
                    type="text"
                    className="form-input"
                    required
                    value={formData.university}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.university && (
                    <h6 className="error">{errors.university}</h6>
                  )}
                </div>
              </div>
              <div className="Inputfield">
                <h3>
                  Choose the track <span>*</span>
                </h3>
                <label>:</label>
                <div className="InputField_box">
                  <select
                    id="stream"
                    className="Selectbox"
                    required="required"
                    onChange={(event) => {
                      handleChange(event);
                      setPrice(true);
                    }}
                  >
                    <option value="Open to Suggestion" selected disabled hidden>
                      Select track
                    </option>
                    <option value="Python AI Development">
                      Python AI Development
                    </option>
                    <option value="Full Stack Development">
                      Full Stack Development
                    </option>
                    <option value="Android Development">
                      Android Development
                    </option>
                    <option value="iOS Development">iOS Development</option>
                    <option value="UI/UX & Graphic Design">
                      UI/UX & Graphic Design
                    </option>
                  </select>
                </div>
              </div>
              {showPrice && (
                <>
                  <div className="Inputfield">
                    <h3>
                      Total Fee <span>*</span>
                    </h3>
                    <label>:</label>
                    <div className="InputField_box">
                      <input
                        id="Amount"
                        type="text"
                        value="₹45000 + ₹8100 (18% GST) = ₹53100"
                        disabled
                      />
                    </div>
                  </div>
                </>
              )}

              <div className="Inputfield">
                <h3>
                  Student Aadhar Number <span>*</span>
                </h3>
                <label>:</label>
                <div className="InputField_box">
                  <input
                    id="aadhar"
                    type="number"
                    minLength={12}
                    value={a}
                    className="form-input"
                    required
                    onChange={(event) => {
                      handleChange(event);
                      handleNumChangeA(event);
                    }}
                    onBlur={handleBlur}
                  />
                  {errors.aadhar && <h6 className="error">{errors.aadhar}</h6>}
                </div>
              </div>
              <FileUpload
                id="StudentPhoto"
                label="Student Photograph"
                htmlFor="StudentPhoto"
                fileLabel={fileLabels.StudentPhoto}
                accept=".pdf, .jpeg"
                updateLabel={updateLabel}
                onDelete={() => clearFile("StudentPhoto")}
                handleChangeFile={handleChangeFile}
              />
              <FileUpload
                id="aadharPhoto"
                label="Student Aadhar Card"
                htmlFor="StudentAadharCard"
                fileLabel={fileLabels.StudentAadharCard}
                accept=".pdf, .jpeg, .jpg"
                updateLabel={updateLabel}
                onDelete={() => clearFile("StudentAadharCard")}
                handleChangeFile={handleChangeFile}
              />
              <FileUpload
                label="College ID Card"
                htmlFor="CollegeIDCard"
                fileLabel={fileLabels.CollegeIDCard}
                accept=".pdf, .jpeg"
                updateLabel={updateLabel}
                onDelete={() => clearFile("CollegeIDCard")}
                handleChangeFile={handleChangeFile}
              />
              <div className="Inputfield">
                <h3>
                  Address Line 1 <span>*</span>
                </h3>
                <label>:</label>
                <div className="InputField_box">
                  <input
                    id="addressLine1"
                    type="text"
                    className="form-input"
                    required
                    value={formData.addressLine1}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.addressLine1 && (
                    <h6 className="error">{errors.addressLine1}</h6>
                  )}
                </div>
              </div>
              <div className="Inputfield">
                <h3>Address Line 2</h3>
                <label>:</label>
                <div className="InputField_box">
                  <input
                    id="addressLine2"
                    type="text"
                    className="form-input"
                    value={formData.addressLine2}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.addressLine2 && (
                    <h6 className="error">{errors.addressLine2}</h6>
                  )}
                </div>
              </div>
              <div className="Inputfield">
                <h3>
                  Pincode <span>*</span>
                </h3>
                <label>:</label>
                <div className="InputField_box">
                  <input
                    id="pincode"
                    type="number"
                    minLength={6}
                    className="form-input"
                    required
                    value={pin}
                    onChange={(event) => {
                      handleChange(event);
                      handleNumChangeP(event);
                    }}
                    onBlur={handleBlur}
                  />
                  {errors.pincode && (
                    <h6 className="error">{errors.pincode}</h6>
                  )}
                </div>
              </div>
              <div className="Inputfield">
                <h3>
                  City <span>*</span>
                </h3>
                <label>:</label>
                <div className="InputField_box">
                  <input
                    id="City"
                    value={city}
                    readOnly
                    type="text"
                    className="form-input"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="Inputfield">
                <h3>
                  State <span>*</span>
                </h3>
                <label>:</label>
                <div className="InputField_box">
                  <input
                    id="State"
                    value={state}
                    readOnly
                    type="text"
                    className="form-input"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="Inputfield">
                <h3>
                  Country <span>*</span>
                </h3>
                <label>:</label>
                <div className="InputField_box">
                  <input
                    id="State"
                    value={country}
                    readOnly
                    type="text"
                    className="form-input"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <button
                type="button"
                className="Button"
                onClick={handleSubmitForm}
              >
                Preview
              </button>
              {preview && (
                <>
                  <Preview formData={formData} closeModel={closeModel} />
                </>
              )}
            </form>
            <Outlet formData={formData} />
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Enrollpage;
