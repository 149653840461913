import React, { useState } from "react";
import "./FAQ.css";

function FAQSection() {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAnswer = (index) => {
    if (openIndex === index) {
      setOpenIndex(null);
    } else {
      setOpenIndex(index);
    }
  };

  const faqs = [
    {
      question: "Who can enroll into this program?",
      answer:
        "BE, BTech, MCA, ME, BSc Comp/Information Science, BCA, any Semester/Year.",
      open: false,
    },
    {
      question: "Should I have prior coding experience?",
      answer:
        "Not required. You’ll be trained from the basics of coding depending on the streams you choose.",
      open: false,
    },
    {
      question: "I’m from BCom, MBA, BBA.  Can I enroll into this program",
      answer: "You can enroll if you can clear our selection criterion.",
      open: false,
    },
    {
      question: "If I don’t get select, can I reapply for this program again?",
      answer:
        "Yes, you can. But we would recommend you give a month or two to get prepped for taking up the test and interview again.",
      open: false,
    },
    {
      question: "Whats the selection criterion?",
      answer:
        "Aptitude test with Quant, you’ll attend an interview. Your selection will depending on the performance in these.",
      open: false,
    },
    {
      question: "What type of online program is this?",
      answer:
        "This is an online program wherein you can get coding skillsets from the comfort of your home, college or anywhere else. Our developers will be connected with you live to train you on the coding skills. This is not a recorded or a faculty led class, but an experienced developer on the other side.",
      open: false,
    },
    {
      question: "Can I choose my batch/session?",
      answer:
        "Yes, you can select depending on the availability of the batch/session.",
      open: false,
    },
    {
      question: "How will I be assessed during the term?",
      answer:
        "There are multiple formats of assessments:\n1. Code projects\n2. Code quality assessment by mentors\n3. Peer review\n4. Code challenge\n5. Coding logic assessment\n6. Final live project assessment",
      open: false,
    },
    {
      question: "What is the coding challenge as part of my assessment?",
      answer:
        "You’ll be coding an impromptu assignment along with other members of the batch/session within the given time frame, and evaluated by senior developers on quality of code, logic, best practices of coding and bug-free project.",
      open: false,
    },
    {
      question: "What will happen if I fail the assessment?",
      answer:
        "You’ll have in total three chances to take this up within 7days each from the actual assessment. If you fail all these three chances, maybe you’re not ready to be a coder after all.",
      open: false,
    },
    {
      question: "Whats the pre-requisite for this online program?",
      answer:
        "Internet connection, laptop with the camera & mic or desktop with a camera & mic.",
      open: false,
    },
    {
      question: "Can I get a refund after my training program starts?",
      answer:
        "Once you start with the program, no refunds are entertained nor possible.",
      open: false,
    },
    // {
    //   question: "Will I get EMIs to make the fee payment?",
    //   answer:
    //     "You can avail zero interest EMI option if your credit or debit card has this option or supports this feature.",
    //   open: false,
    // },
  ];
  return (
    <div className="faq-container">
      {faqs.map((faq, index) => (
        <div
          className="faq-item"
          key={index}
          onClick={() => toggleAnswer(index)}
          style={{ borderBottomColor: openIndex === index ? "#0F0" : "#AAA" }}
        >
          <div className="Query">
            <div className="question">{faq.question}</div>
            <div className={`answer ${openIndex === index ? "open" : "close"}`}>
              {faq.answer}
            </div>
          </div>
          <svg
            className={`arrow ${openIndex === index ? "open" : "close"}`}
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="46"
            viewBox="0 0 24 23"
            fill="none"
          >
            <path
              d="M12.2266 0.0637427C12.4125 0.0635084 12.5967 0.0999646 12.7686 0.171024C12.9404 0.242083 13.0966 0.34635 13.2281 0.477851C13.3596 0.609353 13.4639 0.765504 13.5349 0.937364C13.606 1.10922 13.6424 1.29341 13.6422 1.47938L13.6402 10.0632L22.224 10.0612C22.5995 10.0612 22.9595 10.2104 23.225 10.4758C23.4905 10.7413 23.6397 11.1014 23.6397 11.4768C23.6397 11.8523 23.4905 12.2124 23.225 12.4779C22.9595 12.7433 22.5995 12.8925 22.224 12.8925L13.6402 12.8905L13.6422 21.4743C13.6422 21.8498 13.4931 22.2098 13.2276 22.4753C12.9621 22.7408 12.602 22.8899 12.2266 22.8899C11.8511 22.8899 11.491 22.7408 11.2256 22.4753C10.9601 22.2098 10.8109 21.8498 10.8109 21.4743L10.8129 12.8905L2.2291 12.8925C2.0432 12.8925 1.85912 12.8559 1.68736 12.7847C1.51561 12.7136 1.35955 12.6093 1.2281 12.4779C1.09664 12.3464 0.992365 12.1903 0.921222 12.0186C0.85008 11.8468 0.813463 11.6627 0.813463 11.4768C0.813463 11.2909 0.850079 11.1069 0.921222 10.9351C0.992365 10.7633 1.09664 10.6073 1.22809 10.4758C1.35955 10.3444 1.51561 10.2401 1.68736 10.169C1.85912 10.0978 2.0432 10.0612 2.2291 10.0612L10.8129 10.0632L10.8109 1.47938C10.8107 1.29341 10.8471 1.10922 10.9182 0.937362C10.9893 0.765503 11.0935 0.609352 11.225 0.477851C11.3565 0.34635 11.5127 0.242084 11.6845 0.171025C11.8564 0.099965 12.0406 0.0635086 12.2266 0.0637427Z"
              fill="#AAA"
            />
          </svg>
        </div>
      ))}
    </div>
  );
}

export default FAQSection;
