import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import Homepage from "./Homepage/Homepage";
import OurProgrampage from "./OurProgramspage/Our";
import Pythonpage from "./Pythonpage/Python";
import FullStackpage from "./FullStackpage/FullStack";
import IOSpage from "./iOSpage/IOS";
import Androidpage from "./Androidpage/Android";
import UIUXpage from "./UIUXpage/UIUX";
import Methodology from "./Methodologypage/Methodology";
import Enrollpage from "./Enrollpage/Enrollpage";
import Aboutpage from "./Aboutpage/About";
import Reachus from "./Reachuspage/Reachus";
import Refundpage from "./Refungpage/Refundpage";
import Pay from "./components/Pay";
import SuccessPayment from "./components/SuccessPayment";
import PdfTemplate from "./Reciept_Format/PdfTemplate";
// import Termspage from "./Terms_Conditonspage/Termspage";
import Feepage from "./Fee&FAQpage/Fee";

import Applypage from "./Applypage/Applypage";
//aiml page 31-07-24
import AiMlpageAustralia from "./LandingPages/PythonLandingPages/AiMlpageAustralia/AiMlpageAustralia"
import AiMlpageSingapore from "./LandingPages/PythonLandingPages/AiMlpageSingapore/AiMlpageSingapore"
import AiMlpageBangladesh from "./LandingPages/PythonLandingPages/AiMlpageBangladesh/AiMlpageBangladesh"
import AiMlpageKenya from "./LandingPages/PythonLandingPages/AiMlpageKenya/AiMlpageKenya";
import AiMlpageMuscat from "./LandingPages/PythonLandingPages/AiMlpageMuscat/AiMlpageMuscat";



// Pradeep-01/02/24
import PythonpageBangalore from "./LandingPages/PythonLandingPages/PythonpageBangalore/Python";
import PythonpageDelhi from "./LandingPages/PythonLandingPages/PythonpageDelhi/Python";
import PythonpageMumbai from "./LandingPages/PythonLandingPages/PythonpageMumbai/Python";
import PythonpageChennai from "./LandingPages/PythonLandingPages/PythonpageChennai/Python";
import PythonpageHyderabad from "./LandingPages/PythonLandingPages/PythonpageHyderabad/Python";
import PythonpageAhmedabad from "./LandingPages/PythonLandingPages/PythonpageAhmedabad/Python";
import PythonpageJaipur from "./LandingPages/PythonLandingPages/PythonpageJaipur/Python";
import PythonpageNoida from "./LandingPages/PythonLandingPages/PythonpageNoida/Python";
import PythonpageGurugram from "./LandingPages/PythonLandingPages/PythonpageGurugram/Python";
import PythonpageChandigarh from "./LandingPages/PythonLandingPages/PythonpageChandigarh/Python";
import PythonpageLucknow from "./LandingPages/PythonLandingPages/PythonpageLucknow/Python";
import PythonpageKolkata from "./LandingPages/PythonLandingPages/PythonpageKolkata/Python";
import PythonpageBhubaneswar from "./LandingPages/PythonLandingPages/PythonpageBhubaneswar/Python";
import PythonpageIndia from "./LandingPages/PythonLandingPages/PythonpageIndia/Python";
import PythonpageBhopal from "./LandingPages/PythonLandingPages/PythonpageBhopal/Python";
import PythonpageKerala from "./LandingPages/PythonLandingPages/PythonpageKerala/Python";
import PythonpageVijayawada from "./LandingPages/PythonLandingPages/PythonpageVijayawada/Python";
import PythonpageTirupati from "./LandingPages/PythonLandingPages/PythonpageTirupati/Python";
import PythonpageAmaravati from "./LandingPages/PythonLandingPages/PythonpageAmaravati/Python";
import PythonpageItanagar from "./LandingPages/PythonLandingPages/PythonpageItanagar/Python";
import PythonpageDispur from "./LandingPages/PythonLandingPages/PythonpageDispur/Python";
import PythonpageGandhinagar from "./LandingPages/PythonLandingPages/PythonpageGandhinagar/Python";
import PythonpageShimla from "./LandingPages/PythonLandingPages/PythonpageShimla/Python";
import PythonpageRanchi from "./LandingPages/PythonLandingPages/PythonpageRanchi/Python";
import PythonpageThiruvananthapuram from "./LandingPages/PythonLandingPages/PythonpageThiruvananthapuram/Python";
import PythonpageImphal from "./LandingPages/PythonLandingPages/PythonpageImphal/Python";
import PythonpageMysore from "./LandingPages/PythonLandingPages/PythonpageMysore/Python";
import PythonpageHubliDharwar from "./LandingPages/PythonLandingPages/PythonpageHubli-Dharwad/Python";
import PythonpageBelgaum from "./LandingPages/PythonLandingPages/PythonpageBelgaum/Python";
import PythonpageGulbarga from "./LandingPages/PythonLandingPages/PythonpageGulbarga/Python";
import PythonpageBellary from "./LandingPages/PythonLandingPages/PythonpageBellary/Python";
import PythonpageSurat from "./LandingPages/PythonLandingPages/PythonpageSurat/Python";
import PythonpageVisakhapatnam from "./LandingPages/PythonLandingPages/PythonpageVisakhapatnam/Python";
import PythonpageCoimbatore from "./LandingPages/PythonLandingPages/PythonpageCoimbatore/Python";
import PythonpageHosur from "./LandingPages/PythonLandingPages/PythonpageHosur/Python";
import PythonpagePimparechinchwad from "./LandingPages/PythonLandingPages/PythonpagePimpare-chinchwad/Python";
import PythonpageShivamoga from "./LandingPages/PythonLandingPages/PythonpageShivamoga/Python";
import PythonpageMadurai from "./LandingPages/PythonLandingPages/PythonpageMadurai/Python";
import PythonpageTumkuru from "./LandingPages/PythonLandingPages/PythonpageTumkuru/Python";
import PythonpageMangalore from "./LandingPages/PythonLandingPages/PythonpageMangalore/Python";
import PythonpageUdupi from "./LandingPages/PythonLandingPages/PythonpageUdupi/Python";
import PythonpageManipal from "./LandingPages/PythonLandingPages/PythonpageManipal/Python";
import PythonpageBahrain from "./LandingPages/PythonLandingPages/PythonpageBahrain/Python";
import PythonpageCyprus from "./LandingPages/PythonLandingPages/PythonpageCyprus/Python";
import PythonpageEgypt from "./LandingPages/PythonLandingPages/PythonpageEgypt/Python";
import PythonpageIran from "./LandingPages/PythonLandingPages/PythonpageIran/Python";
import PythonpageIraq from "./LandingPages/PythonLandingPages/PythonpageIraq/Python";
import PythonpageIsrael from "./LandingPages/PythonLandingPages/PythonpageIsrael/Python";
import PythonpageJordan from "./LandingPages/PythonLandingPages/PythonpageJordan/Python";
import PythonpageKuwait from "./LandingPages/PythonLandingPages/PythonpageKuwait/Python";
import PythonpageLebanon from "./LandingPages/PythonLandingPages/PythonpageLebanon/Python";
import PythonpageOman from "./LandingPages/PythonLandingPages/PythonpageOman/Python";
import PythonpagePalestine from "./LandingPages/PythonLandingPages/PythonpagePalestine/Python";
import PythonpageQatar from "./LandingPages/PythonLandingPages/PythonpageQatar/Python";
import PythonpageSaudiArabia from "./LandingPages/PythonLandingPages/PythonpageSaudi-Arabia/Python";
import PythonpageSyria from "./LandingPages/PythonLandingPages/PythonpageSyria/Python";
import PythonpageTurkey from "./LandingPages/PythonLandingPages/PythonpageTurkey/Python";
import PythonpageUAE from "./LandingPages/PythonLandingPages/PythonpageUAE/Python";
import PythonpageYemen from "./LandingPages/PythonLandingPages/PythonpageYemen/Python";
import PythonpageAbudhabi from "./LandingPages/PythonLandingPages/PythonpageAbu-dhabi/Python";
import PythonpageDubai from "./LandingPages/PythonLandingPages/PythonpageDubai/Python";

// Androidpage
import AndroidpageBangalore from "./LandingPages/AndroidLandingPages/AndroidpageBangalore/Android";
import AndroidpageDelhi from "./LandingPages/AndroidLandingPages/AndroidpageDelhi/Android";
import AndroidpageMumbai from "./LandingPages/AndroidLandingPages/AndroidpageMumbai/Android";
import AndroidpageChennai from "./LandingPages/AndroidLandingPages/AndroidpageChennai/Android";
import AndroidpageHyderabad from "./LandingPages/AndroidLandingPages/AndroidpageHyderabad/Android";
import AndroidpageAhmedabad from "./LandingPages/AndroidLandingPages/AndroidpageAhmedabad/Android";
import AndroidpageJaipur from "./LandingPages/AndroidLandingPages/AndroidpageJaipur/Android";
import AndroidpageNoida from "./LandingPages/AndroidLandingPages/AndroidpageNoida/Android";
import AndroidpageGurugram from "./LandingPages/AndroidLandingPages/AndroidpageGurugram/Android";
import AndroidpageChandigarh from "./LandingPages/AndroidLandingPages/AndroidpageChandigarh/Android";
import AndroidpageLucknow from "./LandingPages/AndroidLandingPages/AndroidpageLucknow/Android";
import AndroidpageKolkata from "./LandingPages/AndroidLandingPages/AndroidpageKolkata/Android";
import AndroidpageBhubaneswar from "./LandingPages/AndroidLandingPages/AndroidpageBhubaneswar/Android";
import AndroidpageIndia from "./LandingPages/AndroidLandingPages/AndroidpageIndia/Android";
import AndroidpageBhopal from "./LandingPages/AndroidLandingPages/AndroidpageBhopal/Android";
import AndroidpageKerala from "./LandingPages/AndroidLandingPages/AndroidpageKerala/Android";
import AndroidpageVijayawada from "./LandingPages/AndroidLandingPages/AndroidpageVijayawada/Android";
import AndroidpageTirupati from "./LandingPages/AndroidLandingPages/AndroidpageTirupati/Android";
import AndroidpageAmaravati from "./LandingPages/AndroidLandingPages/AndroidpageAmaravati/Android";
import AndroidpageItanagar from "./LandingPages/AndroidLandingPages/AndroidpageItanagar/Android";
import AndroidpageDispur from "./LandingPages/AndroidLandingPages/AndroidpageDispur/Android";
import AndroidpageGandhinagar from "./LandingPages/AndroidLandingPages/AndroidpageGandhinagar/Android";
import AndroidpageShimla from "./LandingPages/AndroidLandingPages/AndroidpageShimla/Android";
import AndroidpageRanchi from "./LandingPages/AndroidLandingPages/AndroidpageRanchi/Android";
import AndroidpageThiruvananthapuram from "./LandingPages/AndroidLandingPages/AndroidpageThiruvananthapuram/Android";
import AndroidpageImphal from "./LandingPages/AndroidLandingPages/AndroidpageImphal/Android";
import AndroidpageMysore from "./LandingPages/AndroidLandingPages/AndroidpageMysore/Android";
import AndroidpageHubliDharwar from "./LandingPages/AndroidLandingPages/AndroidpageHubli-Dharwad/Android";
import AndroidpageBelgaum from "./LandingPages/AndroidLandingPages/AndroidpageBelgaum/Android";
import AndroidpageGulbarga from "./LandingPages/AndroidLandingPages/AndroidpageGulbarga/Android";
import AndroidpageBellary from "./LandingPages/AndroidLandingPages/AndroidpageBellary/Android";
import AndroidpageSurat from "./LandingPages/AndroidLandingPages/AndroidpageSurat/Android";
import AndroidpageVisakhapatnam from "./LandingPages/AndroidLandingPages/AndroidpageVisakhapatnam/Android";
import AndroidpageCoimbatore from "./LandingPages/AndroidLandingPages/AndroidpageCoimbatore/Android";
import AndroidpageHosur from "./LandingPages/AndroidLandingPages/AndroidpageHosur/Android";
import AndroidpagePimparechinchwad from "./LandingPages/AndroidLandingPages/AndroidpagePimpare-chinchwad/Android";
import AndroidpageShivamoga from "./LandingPages/AndroidLandingPages/AndroidpageShivamoga/Android";
import AndroidpageMadurai from "./LandingPages/AndroidLandingPages/AndroidpageMadurai/Android";
import AndroidpageTumkuru from "./LandingPages/AndroidLandingPages/AndroidpageTumkuru/Android";
import AndroidpageMangalore from "./LandingPages/AndroidLandingPages/AndroidpageMangalore/Android";
import AndroidpageUdupi from "./LandingPages/AndroidLandingPages/AndroidpageUdupi/Android";
import AndroidpageManipal from "./LandingPages/AndroidLandingPages/AndroidpageManipal/Android";
import AndroidpageBahrain from "./LandingPages/AndroidLandingPages/AndroidpageBahrain/Android";
import AndroidpageCyprus from "./LandingPages/AndroidLandingPages/AndroidpageCyprus/Android";
import AndroidpageEgypt from "./LandingPages/AndroidLandingPages/AndroidpageEgypt/Android";
import AndroidpageIran from "./LandingPages/AndroidLandingPages/AndroidpageIran/Android";
import AndroidpageIraq from "./LandingPages/AndroidLandingPages/AndroidpageIraq/Android";
import AndroidpageIsrael from "./LandingPages/AndroidLandingPages/AndroidpageIsrael/Android";
import AndroidpageJordan from "./LandingPages/AndroidLandingPages/AndroidpageJordan/Android";
import AndroidpageKuwait from "./LandingPages/AndroidLandingPages/AndroidpageKuwait/Android";
import AndroidpageLebanon from "./LandingPages/AndroidLandingPages/AndroidpageLebanon/Android";
import AndroidpageOman from "./LandingPages/AndroidLandingPages/AndroidpageOman/Android";
import AndroidpagePalestine from "./LandingPages/AndroidLandingPages/AndroidpagePalestine/Android";
import AndroidpageQatar from "./LandingPages/AndroidLandingPages/AndroidpageQatar/Android";
import AndroidpageSaudiArabia from "./LandingPages/AndroidLandingPages/AndroidpageSaudi-Arabia/Android";
import AndroidpageSyria from "./LandingPages/AndroidLandingPages/AndroidpageSyria/Android";
import AndroidpageTurkey from "./LandingPages/AndroidLandingPages/AndroidpageTurkey/Android";
import AndroidpageUAE from "./LandingPages/AndroidLandingPages/AndroidpageUAE/Android";
import AndroidpageYemen from "./LandingPages/AndroidLandingPages/AndroidpageYemen/Android";
import AndroidpageAbudhabi from "./LandingPages/AndroidLandingPages/AndroidpageAbu-dhabi/Android";
import AndroidpageDubai from "./LandingPages/AndroidLandingPages/AndroidpageDubai/Android";
//FullStackpage
import FullStackpageIndia from "./LandingPages/FullStackLandingPages/FullStackpageIndia/FullStack";
import FullStackpageBangalore from "./LandingPages/FullStackLandingPages/FullStackpageBangalore/FullStack";
import FullStackpageDelhi from "./LandingPages/FullStackLandingPages/FullStackpageDelhi/FullStack";
import FullStackpageMumbai from "./LandingPages/FullStackLandingPages/FullStackpageMumbai/FullStack";
import FullStackpageChennai from "./LandingPages/FullStackLandingPages/FullStackpageChennai/FullStack";
import FullStackpageHyderabad from "./LandingPages/FullStackLandingPages/FullStackpageHyderabad/FullStack";
import FullStackpageAhmedabad from "./LandingPages/FullStackLandingPages/FullStackpageAhmedabad/FullStack";
import FullStackpageJaipur from "./LandingPages/FullStackLandingPages/FullStackpageJaipur/FullStack";
import FullStackpageNoida from "./LandingPages/FullStackLandingPages/FullStackpageNoida/FullStack";
import FullStackpageGurugram from "./LandingPages/FullStackLandingPages/FullStackpageGurugram/FullStack";
import FullStackpageChandigarh from "./LandingPages/FullStackLandingPages/FullStackpageChandigarh/FullStack";
import FullStackpageLucknow from "./LandingPages/FullStackLandingPages/FullStackpageLucknow/FullStack";
import FullStackpageKolkata from "./LandingPages/FullStackLandingPages/FullStackpageKolkata/FullStack";
import FullStackpageBhubaneswar from "./LandingPages/FullStackLandingPages/FullStackpageBhubaneswar/FullStack";
import FullStackpageBhopal from "./LandingPages/FullStackLandingPages/FullStackpageBhopal/FullStack";
import FullStackpageKerala from "./LandingPages/FullStackLandingPages/FullStackpageKerala/FullStack";
import FullStackpageVijayawada from "./LandingPages/FullStackLandingPages/FullStackpageVijayawada/FullStack";
import FullStackpageTirupati from "./LandingPages/FullStackLandingPages/FullStackpageTirupati/FullStack";
import FullStackpageAmaravati from "./LandingPages/FullStackLandingPages/FullStackpageAmaravati/FullStack";
import FullStackpageItanagar from "./LandingPages/FullStackLandingPages/FullStackpageItanagar/FullStack";
import FullStackpageDispur from "./LandingPages/FullStackLandingPages/FullStackpageDispur/FullStack";
import FullStackpageGandhinagar from "./LandingPages/FullStackLandingPages/FullStackpageGandhinagar/FullStack";
import FullStackpageShimla from "./LandingPages/FullStackLandingPages/FullStackpageShimla/FullStack";
import FullStackpageRanchi from "./LandingPages/FullStackLandingPages/FullStackpageRanchi/FullStack";
import FullStackpageThiruvananthapuram from "./LandingPages/FullStackLandingPages/FullStackpageThiruvananthapuram/FullStack";
import FullStackpageImphal from "./LandingPages/FullStackLandingPages/FullStackpageImphal/FullStack";
import FullStackpageMysore from "./LandingPages/FullStackLandingPages/FullStackpageMysore/FullStack";
import FullStackpageHubliDharwar from "./LandingPages/FullStackLandingPages/FullStackpageHubli-Dharwad/FullStack";
import FullStackpageBelgaum from "./LandingPages/FullStackLandingPages/FullStackpageBelgaum/FullStack";
import FullStackpageGulbarga from "./LandingPages/FullStackLandingPages/FullStackpageGulbarga/FullStack";
import FullStackpageBellary from "./LandingPages/FullStackLandingPages/FullStackpageBellary/FullStack";
import FullStackpageSurat from "./LandingPages/FullStackLandingPages/FullStackpageSurat/FullStack";
import FullStackpageVisakhapatnam from "./LandingPages/FullStackLandingPages/FullStackpageVisakhapatnam/FullStack";
import FullStackpageCoimbatore from "./LandingPages/FullStackLandingPages/FullStackpageCoimbatore/FullStack";
import FullStackpageHosur from "./LandingPages/FullStackLandingPages/FullStackpageHosur/FullStack";
import FullStackpagePimparechinchwad from "./LandingPages/FullStackLandingPages/FullStackpagePimpare-chinchwad/FullStack";
import FullStackpageShivamoga from "./LandingPages/FullStackLandingPages/FullStackpageShivamoga/FullStack";
import FullStackpageMadurai from "./LandingPages/FullStackLandingPages/FullStackpageMadurai/FullStack";
import FullStackpageTumkuru from "./LandingPages/FullStackLandingPages/FullStackpageTumkuru/FullStack";
import FullStackpageMangalore from "./LandingPages/FullStackLandingPages/FullStackpageMangalore/FullStack";
import FullStackpageUdupi from "./LandingPages/FullStackLandingPages/FullStackpageUdupi/FullStack";
import FullStackpageManipal from "./LandingPages/FullStackLandingPages/FullStackpageManipal/FullStack";
import FullStackpageBahrain from "./LandingPages/FullStackLandingPages/FullStackpageBahrain/FullStack";
import FullStackpageCyprus from "./LandingPages/FullStackLandingPages/FullStackpageCyprus/FullStack";
import FullStackpageEgypt from "./LandingPages/FullStackLandingPages/FullStackpageEgypt/FullStack";
import FullStackpageIran from "./LandingPages/FullStackLandingPages/FullStackpageIran/FullStack";
import FullStackpageIraq from "./LandingPages/FullStackLandingPages/FullStackpageIraq/FullStack";
import FullStackpageIsrael from "./LandingPages/FullStackLandingPages/FullStackpageIsrael/FullStack";
import FullStackpageJordan from "./LandingPages/FullStackLandingPages/FullStackpageJordan/FullStack";
import FullStackpageKuwait from "./LandingPages/FullStackLandingPages/FullStackpageKuwait/FullStack";
import FullStackpageLebanon from "./LandingPages/FullStackLandingPages/FullStackpageLebanon/FullStack";
import FullStackpageOman from "./LandingPages/FullStackLandingPages/FullStackpageOman/FullStack";
import FullStackpagePalestine from "./LandingPages/FullStackLandingPages/FullStackpagePalestine/FullStack";
import FullStackpageQatar from "./LandingPages/FullStackLandingPages/FullStackpageQatar/FullStack";
import FullStackpageSaudiArabia from "./LandingPages/FullStackLandingPages/FullStackpageSaudi-Arabia/FullStack";
import FullStackpageSyria from "./LandingPages/FullStackLandingPages/FullStackpageSyria/FullStack";
import FullStackpageTurkey from "./LandingPages/FullStackLandingPages/FullStackpageTurkey/FullStack";
import FullStackpageUAE from "./LandingPages/FullStackLandingPages/FullStackpageUAE/FullStack";
import FullStackpageYemen from "./LandingPages/FullStackLandingPages/FullStackpageYemen/FullStack";
import FullStackpageAbudhabi from "./LandingPages/FullStackLandingPages/FullStackpageAbu-dhabi/FullStack";
import FullStackpageDubai from "./LandingPages/FullStackLandingPages/FullStackpageDubai/FullStack";
//IOSpage
import IOSpageIndia from "./LandingPages/IOSLandingPages/iOSpageIndia/IOS";
import IOSpageBangalore from "./LandingPages/IOSLandingPages/iOSpageBangalore/IOS";
import IOSpageDelhi from "./LandingPages/IOSLandingPages/iOSpageDelhi/IOS";
import IOSpageMumbai from "./LandingPages/IOSLandingPages/iOSpageMumbai/IOS";
import IOSpageChennai from "./LandingPages/IOSLandingPages/iOSpageChennai/IOS";
import IOSpageHyderabad from "./LandingPages/IOSLandingPages/iOSpageHyderabad/IOS";
import IOSpageAhmedabad from "./LandingPages/IOSLandingPages/iOSpageAhmedabad/IOS";
import IOSpageJaipur from "./LandingPages/IOSLandingPages/iOSpageJaipur/IOS";
import IOSpageNoida from "./LandingPages/IOSLandingPages/iOSpageNoida/IOS";
import IOSpageGurugram from "./LandingPages/IOSLandingPages/iOSpageGurugram/IOS";
import IOSpageChandigarh from "./LandingPages/IOSLandingPages/iOSpageChandigarh/IOS";
import IOSpageLucknow from "./LandingPages/IOSLandingPages/iOSpageLucknow/IOS";
import IOSpageKolkata from "./LandingPages/IOSLandingPages/iOSpageKolkata/IOS";
import IOSpageBhubaneswar from "./LandingPages/IOSLandingPages/iOSpageBhubaneswar/IOS";
import IOSpageBhopal from "./LandingPages/IOSLandingPages/iOSpageBhopal/IOS";
import IOSpageKerala from "./LandingPages/IOSLandingPages/iOSpageKerala/IOS";
import IOSpageVijayawada from "./LandingPages/IOSLandingPages/iOSpageVijayawada/IOS";
import IOSpageTirupati from "./LandingPages/IOSLandingPages/iOSpageTirupati/IOS";
import IOSpageAmaravati from "./LandingPages/IOSLandingPages/iOSpageAmaravati/IOS";
import IOSpageItanagar from "./LandingPages/IOSLandingPages/iOSpageItanagar/IOS";
import IOSpageDispur from "./LandingPages/IOSLandingPages/iOSpageDispur/IOS";
import IOSpageGandhinagar from "./LandingPages/IOSLandingPages/iOSpageGandhinagar/IOS";
import IOSpageShimla from "./LandingPages/IOSLandingPages/iOSpageShimla/IOS";
import IOSpageRanchi from "./LandingPages/IOSLandingPages/iOSpageRanchi/IOS";
import IOSpageThiruvananthapuram from "./LandingPages/IOSLandingPages/iOSpageThiruvananthapuram/IOS";
import IOSpageImphal from "./LandingPages/IOSLandingPages/iOSpageImphal/IOS";
import IOSpageMysore from "./LandingPages/IOSLandingPages/iOSpageMysore/IOS";
import IOSpageHubliDharwar from "./LandingPages/IOSLandingPages/iOSpageHubli-Dharwad/IOS";
import IOSpageBelgaum from "./LandingPages/IOSLandingPages/iOSpageBelgaum/IOS";
import IOSpageGulbarga from "./LandingPages/IOSLandingPages/iOSpageGulbarga/IOS";
import IOSpageBellary from "./LandingPages/IOSLandingPages/iOSpageBellary/IOS";
import IOSpageSurat from "./LandingPages/IOSLandingPages/iOSpageSurat/IOS";
import IOSpageVisakhapatnam from "./LandingPages/IOSLandingPages/iOSpageVisakhapatnam/IOS";
import IOSpageCoimbatore from "./LandingPages/IOSLandingPages/iOSpageCoimbatore/IOS";
import IOSpageHosur from "./LandingPages/IOSLandingPages/iOSpageHosur/IOS";
import IOSpagePimparechinchwad from "./LandingPages/IOSLandingPages/iOSpagePimpare-chinchwad/IOS";
import IOSpageShivamoga from "./LandingPages/IOSLandingPages/iOSpageShivamoga/IOS";
import IOSpageMadurai from "./LandingPages/IOSLandingPages/iOSpageMadurai/IOS";
import IOSpageTumkuru from "./LandingPages/IOSLandingPages/iOSpageTumkuru/IOS";
import IOSpageMangalore from "./LandingPages/IOSLandingPages/iOSpageMangalore/IOS";
import IOSpageUdupi from "./LandingPages/IOSLandingPages/iOSpageUdupi/IOS";
import IOSpageManipal from "./LandingPages/IOSLandingPages/iOSpageManipal/IOS";
import IOSpageBahrain from "./LandingPages/IOSLandingPages/iOSpageBahrain/IOS";
import IOSpageCyprus from "./LandingPages/IOSLandingPages/iOSpageCyprus/IOS";
import IOSpageEgypt from "./LandingPages/IOSLandingPages/iOSpageEgypt/IOS";
import IOSpageIran from "./LandingPages/IOSLandingPages/iOSpageIran/IOS";
import IOSpageIraq from "./LandingPages/IOSLandingPages/iOSpageIraq/IOS";
import IOSpageIsrael from "./LandingPages/IOSLandingPages/iOSpageIsrael/IOS";
import IOSpageJordan from "./LandingPages/IOSLandingPages/iOSpageJordan/IOS";
import IOSpageKuwait from "./LandingPages/IOSLandingPages/iOSpageKuwait/IOS";
import IOSpageLebanon from "./LandingPages/IOSLandingPages/iOSpageLebanon/IOS";
import IOSpageOman from "./LandingPages/IOSLandingPages/iOSpageOman/IOS";
import IOSpagePalestine from "./LandingPages/IOSLandingPages/iOSpagePalestine/IOS";
import IOSpageQatar from "./LandingPages/IOSLandingPages/iOSpageQatar/IOS";
import IOSpageSaudiArabia from "./LandingPages/IOSLandingPages/iOSpageSaudi-Arabia/IOS";
import IOSpageSyria from "./LandingPages/IOSLandingPages/iOSpageSyria/IOS";
import IOSpageTurkey from "./LandingPages/IOSLandingPages/iOSpageTurkey/IOS";
import IOSpageUAE from "./LandingPages/IOSLandingPages/iOSpageUAE/IOS";
import IOSpageYemen from "./LandingPages/IOSLandingPages/iOSpageYemen/IOS";
import IOSpageAbudhabi from "./LandingPages/IOSLandingPages/iOSpageAbu-dhabi/IOS";
import IOSpageDubai from "./LandingPages/IOSLandingPages/iOSpageDubai/IOS";
//UIUX
import UIUXpageIndia from "./LandingPages/UIUXLandingPages/UIUXpageIndia/UIUX";
import UIUXpageBangalore from "./LandingPages/UIUXLandingPages/UIUXpageBangalore/UIUX";
import UIUXpageDelhi from "./LandingPages/UIUXLandingPages/UIUXpageDelhi/UIUX";
import UIUXpageMumbai from "./LandingPages/UIUXLandingPages/UIUXpageMumbai/UIUX";
import UIUXpageChennai from "./LandingPages/UIUXLandingPages/UIUXpageChennai/UIUX";
import UIUXpageHyderabad from "./LandingPages/UIUXLandingPages/UIUXpageHyderabad/UIUX";
import UIUXpageAhmedabad from "./LandingPages/UIUXLandingPages/UIUXpageAhmedabad/UIUX";
import UIUXpageJaipur from "./LandingPages/UIUXLandingPages/UIUXpageJaipur/UIUX";
import UIUXpageNoida from "./LandingPages/UIUXLandingPages/UIUXpageNoida/UIUX";
import UIUXpageGurugram from "./LandingPages/UIUXLandingPages/UIUXpageGurugram/UIUX";
import UIUXpageChandigarh from "./LandingPages/UIUXLandingPages/UIUXpageChandigarh/UIUX";
import UIUXpageLucknow from "./LandingPages/UIUXLandingPages/UIUXpageLucknow/UIUX";
import UIUXpageKolkata from "./LandingPages/UIUXLandingPages/UIUXpageKolkata/UIUX";
import UIUXpageBhubaneswar from "./LandingPages/UIUXLandingPages/UIUXpageBhubaneswar/UIUX";
import UIUXpageBhopal from "./LandingPages/UIUXLandingPages/UIUXpageBhopal/UIUX";
import UIUXpageKerala from "./LandingPages/UIUXLandingPages/UIUXpageKerala/UIUX";
import UIUXpageVijayawada from "./LandingPages/UIUXLandingPages/UIUXpageVijayawada/UIUX";
import UIUXpageTirupati from "./LandingPages/UIUXLandingPages/UIUXpageTirupati/UIUX";
import UIUXpageAmaravati from "./LandingPages/UIUXLandingPages/UIUXpageAmaravati/UIUX";
import UIUXpageItanagar from "./LandingPages/UIUXLandingPages/UIUXpageItanagar/UIUX";
import UIUXpageDispur from "./LandingPages/UIUXLandingPages/UIUXpageDispur/UIUX";
import UIUXpageGandhinagar from "./LandingPages/UIUXLandingPages/UIUXpageGandhinagar/UIUX";
import UIUXpageShimla from "./LandingPages/UIUXLandingPages/UIUXpageShimla/UIUX";
import UIUXpageRanchi from "./LandingPages/UIUXLandingPages/UIUXpageRanchi/UIUX";
import UIUXpageThiruvananthapuram from "./LandingPages/UIUXLandingPages/UIUXpageThiruvananthapuram/UIUX";
import UIUXpageImphal from "./LandingPages/UIUXLandingPages/UIUXpageImphal/UIUX";
import UIUXpageMysore from "./LandingPages/UIUXLandingPages/UIUXpageMysore/UIUX";
import UIUXpageHubliDharwar from "./LandingPages/UIUXLandingPages/UIUXpageHubli-Dharwad/UIUX";
import UIUXpageBelgaum from "./LandingPages/UIUXLandingPages/UIUXpageBelgaum/UIUX";
import UIUXpageGulbarga from "./LandingPages/UIUXLandingPages/UIUXpageGulbarga/UIUX";
import UIUXpageBellary from "./LandingPages/UIUXLandingPages/UIUXpageBellary/UIUX";
import UIUXpageSurat from "./LandingPages/UIUXLandingPages/UIUXpageSurat/UIUX";
import UIUXpageVisakhapatnam from "./LandingPages/UIUXLandingPages/UIUXpageVisakhapatnam/UIUX";
import UIUXpageCoimbatore from "./LandingPages/UIUXLandingPages/UIUXpageCoimbatore/UIUX";
import UIUXpageHosur from "./LandingPages/UIUXLandingPages/UIUXpageHosur/UIUX";
import UIUXpagePimparechinchwad from "./LandingPages/UIUXLandingPages/UIUXpagePimpare-chinchwad/UIUX";
import UIUXpageShivamoga from "./LandingPages/UIUXLandingPages/UIUXpageShivamoga/UIUX";
import UIUXpageMadurai from "./LandingPages/UIUXLandingPages/UIUXpageMadurai/UIUX";
import UIUXpageTumkuru from "./LandingPages/UIUXLandingPages/UIUXpageTumkuru/UIUX";
import UIUXpageMangalore from "./LandingPages/UIUXLandingPages/UIUXpageMangalore/UIUX";
import UIUXpageUdupi from "./LandingPages/UIUXLandingPages/UIUXpageUdupi/UIUX";
import UIUXpageManipal from "./LandingPages/UIUXLandingPages/UIUXpageManipal/UIUX";
import UIUXpageBahrain from "./LandingPages/UIUXLandingPages/UIUXpageBahrain/UIUX";
import UIUXpageCyprus from "./LandingPages/UIUXLandingPages/UIUXpageCyprus/UIUX";
import UIUXpageEgypt from "./LandingPages/UIUXLandingPages/UIUXpageEgypt/UIUX";
import UIUXpageIran from "./LandingPages/UIUXLandingPages/UIUXpageIran/UIUX";
import UIUXpageIraq from "./LandingPages/UIUXLandingPages/UIUXpageIraq/UIUX";
import UIUXpageIsrael from "./LandingPages/UIUXLandingPages/UIUXpageIsrael/UIUX";
import UIUXpageJordan from "./LandingPages/UIUXLandingPages/UIUXpageJordan/UIUX";
import UIUXpageKuwait from "./LandingPages/UIUXLandingPages/UIUXpageKuwait/UIUX";
import UIUXpageLebanon from "./LandingPages/UIUXLandingPages/UIUXpageLebanon/UIUX";
import UIUXpageOman from "./LandingPages/UIUXLandingPages/UIUXpageOman/UIUX";
import UIUXpagePalestine from "./LandingPages/UIUXLandingPages/UIUXpagePalestine/UIUX";
import UIUXpageQatar from "./LandingPages/UIUXLandingPages/UIUXpageQatar/UIUX";
import UIUXpageSaudiArabia from "./LandingPages/UIUXLandingPages/UIUXpageSaudi-Arabia/UIUX";
import UIUXpageSyria from "./LandingPages/UIUXLandingPages/UIUXpageSyria/UIUX";
import UIUXpageTurkey from "./LandingPages/UIUXLandingPages/UIUXpageTurkey/UIUX";
import UIUXpageUAE from "./LandingPages/UIUXLandingPages/UIUXpageUAE/UIUX";
import UIUXpageYemen from "./LandingPages/UIUXLandingPages/UIUXpageYemen/UIUX";
import UIUXpageAbudhabi from "./LandingPages/UIUXLandingPages/UIUXpageAbu-dhabi/UIUX";
import UIUXpageDubai from "./LandingPages/UIUXLandingPages/UIUXpageDubai/UIUX";
import Errorpage from "./Errorpage/Errorpage";
// import MasterClassAndroid from "./MasterClass/MasterClassAndroid";
// import MasterClassiOS from "./MasterClass/MasterClassiOS"
import MasterClassFullStack from "./MasterClass/MasterClassFullStack";
import MasterClassAI from "./MasterClass/MasterClassAI";
import AiMlpageSouthafrica from "./LandingPages/PythonLandingPages/AiMlpageSouth-africa/AiMlpageSouth-africa";
import AiMlpageZimbabwe from "./LandingPages/PythonLandingPages/AiMlpageZimbabwe/AiMlpageZimbabwe";
import AiMlpageEthiopia from "./LandingPages/PythonLandingPages/AiMlpageEthiopia/AiMlpageEthiopia";
import AiMlpageNepal from "./LandingPages/PythonLandingPages/AiMlpageNepal/AiMlpageNepal";
import AiMlpageNewZealand from "./LandingPages/PythonLandingPages/AiMlpageNew-Zealand/AiMlpageNew-Zealand";
import AiMlpageNigeria from "./LandingPages/PythonLandingPages/AiMlpageNigeria/AiMlpageNigeria";
import AiMlpageSriLanka from "./LandingPages/PythonLandingPages/AiMlpageSri-Lanka/AiMlpageSri-Lanka";
import AiMlpageUganda from "./LandingPages/PythonLandingPages/AiMlpageUganda/AiMlpageUganda";
import AiMlpageThailand from "./LandingPages/PythonLandingPages/AiMlpageThailand/AiMlpageThailand";
import AiMlpagePhilippines from "./LandingPages/PythonLandingPages/AiMlpagePhilippines/AiMlpagePhilippines";
import AiMlpageIndonesia from "./LandingPages/PythonLandingPages/AiMlpageIndonesia/AiMlpageIndonesia";
import AiMlpageUK from "./LandingPages/PythonLandingPages/AiMlpageUK/AiMlpageUK";
import AiMlpageIreland from "./LandingPages/PythonLandingPages/AiMlpageIreland/AiMlpageIreland";
import AiMlpageMexico from "./LandingPages/PythonLandingPages/AiMlpageMexico/AiMlpageMexico";
import AiMlpageBrazil from "./LandingPages/PythonLandingPages/AiMlpageBrazil/AiMlpageBrazil";
import AiMlpageVietnam from "./LandingPages/PythonLandingPages/AiMlpageVietnam/AiMlpageVietnam";
import AiMlpageArgentina from "./LandingPages/PythonLandingPages/AiMlpageArgentina/AiMlpageArgentina";
import AiMlpageMalaysia from "./LandingPages/PythonLandingPages/AiMlpageMalaysia/AiMlpageMalaysia";
import AiMlpageAlgeria from "./LandingPages/PythonLandingPages/AiMlpageAlgeria/AiMLpageAlgeria";
import AiMlpageSudan from "./LandingPages/PythonLandingPages/AiMlpageSudan/AiMlpageSudan";
import AiMlpageMorocco from "./LandingPages/PythonLandingPages/AiMlpageMorocco/AiMlpageMorocco";
import AiMlpageGhana from "./LandingPages/PythonLandingPages/AiMlpageGhana/AiMlpageGhana";
import AiMlpageAngola from "./LandingPages/PythonLandingPages/AiMlpageAngola/AiMlpageAngola";
import AiMlpageMozambique from "./LandingPages/PythonLandingPages/AiMlpageMozambique/AiMlpageMozambique";
import AiMlpageMelbourne from "./LandingPages/PythonLandingPages/AiMlpageMelbourne/AiMlpageMelbourne";
import AiMlpageSydney from "./LandingPages/PythonLandingPages/AiMlpageSydney/AiMlpageSydney";
import AiMlpageBrisbane from "./LandingPages/PythonLandingPages/AiMlpageBrisbane/AiMlpageBrisbane";
import AiMlpagePerth from "./LandingPages/PythonLandingPages/AiMlpagePerth/AiMlpagePerth";
import AiMlpageAdelaide from "./LandingPages/PythonLandingPages/AiMlpageAdelaide/AiMlpageAdelaide";
import AiMlpageLondon from "./LandingPages/PythonLandingPages/AiMlpageLondon/AiMlpageLondon";
import AiMlpageBirmingham from "./LandingPages/PythonLandingPages/AiMlpageBirmingham/AiMlpageBirmingham";
import AiMlpageManchester from "./LandingPages/PythonLandingPages/AiMlpageManchester/AiMlpageManchester";
import AiMlpageNottingham from "./LandingPages/PythonLandingPages/AiMlpageNottingham/AiMlpageNottingham";
import AiMlpageToronto from "./LandingPages/PythonLandingPages/AiMlpageToronto/AiMlpageToronto";
import AiMlpageKingston from "./LandingPages/PythonLandingPages/AiMlpageKingston/AiMlpageKingston";
import AiMlpageCharlottetown from "./LandingPages/PythonLandingPages/AiMlpageCharlottetown/AiMlpageCharlottetown";
import AiMlpageOttawa from "./LandingPages/PythonLandingPages/AiMlpageOttawa/AiMlpageOttawa";
import AiMlpageNewYork from "./LandingPages/PythonLandingPages/AiMlpageNew-York/AiMlpageNew-York";
import AiMlpageLosAngeles from "./LandingPages/PythonLandingPages/AiMlpageLos-Angeles/AiMlpageLos-Angeles";
import AiMlpageChicago from "./LandingPages/PythonLandingPages/AiMlpageChicago/AiMlpageChicago";
import AiMlpageHouston from "./LandingPages/PythonLandingPages/AiMlpageHouston/AiMlpageHouston";
import AiMlpagePhoenix from "./LandingPages/PythonLandingPages/AiMlapagePhoenix/AiMlpagePhoenix";
import AiMlpagePhiladelphia from "./LandingPages/PythonLandingPages/AiMlpagePhiladelphia/AiMlpagePhiladelphia";
import AiMlpageSanAntonio from "./LandingPages/PythonLandingPages/AiMlpageSanAntonio/AiMlpageSanAntonio";
import AiMlpageSanDiego from "./LandingPages/PythonLandingPages/AiMlpageSan-Diego/AiMlpageSan-Diego";
import AiMlpageAustin from "./LandingPages/PythonLandingPages/AiMlpageAustin/AiMlpageAustin";
import AiMlpageSeattle from "./LandingPages/PythonLandingPages/AiMlpageSeattle/AiMlpageSeattle";
import AiMlpageWashingtonDC from "./LandingPages/PythonLandingPages/AiMlpageWashington-DC/AiMlpageWashington-DC";
import AiMlpageLasVegas from "./LandingPages/PythonLandingPages/AiMlpageLas-Vegas/AiMlpageLas-Vegas";
// import AiMlpageMiam from "./LandingPages/PythonLandingPages/AiMlpageMiam/AiMlpageMiam";
import AiMlpageIndianapolis from "./LandingPages/PythonLandingPages/AiMlpageIndianapolis/AiMlpageIndianapolis";
import AiMlpageFortWorth from "./LandingPages/PythonLandingPages/AiMlpageFort-Worth/AiMlpageFort-Worth";
import AiMlpageGlasgow from "./LandingPages/PythonLandingPages/AiMlpageGlasgow/AiMlpageGlasgow";
import AiMlpageLiverpool from "./LandingPages/PythonLandingPages/AiMlpageLiverpool/AiMlpageLiverpool";
import AiMlpageEdinburgh from "./LandingPages/PythonLandingPages/AiMlpageEdinburgh/AiMlpageEdinburgh";
import AiMlpageBristol from "./LandingPages/PythonLandingPages/AiMlpageBristol/AiMlpageBristol";
import AiMlpageAustria from "./LandingPages/PythonLandingPages/AiMlpageAustria/AiMlpageAustria";
import AiMlpageBelgium from "./LandingPages/PythonLandingPages/AiMlpageBelgium/AiMlpageBelgium";
import AiMlpageBulgaria from "./LandingPages/PythonLandingPages/AiMlpageBulgaria/AiMlpageBulgaria";
import AiMlpageDenmark from "./LandingPages/PythonLandingPages/AiMlpageDenmark/AiMlpageDenmark";
import AiMlpageFrance from "./LandingPages/PythonLandingPages/AiMlpageFrance/AiMlpageFrance";
import AiMlpageItaly from "./LandingPages/PythonLandingPages/AiMlpageItaly/AiMlpageItaly";
import AiMlpagePoland from "./LandingPages/PythonLandingPages/AiMlpagePoland/AiMlpagePoland";
import AiMlpageSlovakia from "./LandingPages/PythonLandingPages/AiMlpageSlovakia/AiMlpageSlovakia";
import AiMlpageSpain from "./LandingPages/PythonLandingPages/AiMlpageSpain/AiMlpageSpain";
import AiMlpageSweden from "./LandingPages/PythonLandingPages/AiMlpageSweden/AiMlpageSweden";
import AiMlpageSwitzerland from "./LandingPages/PythonLandingPages/AiMlpageSwitzerland/AiMlpageSwitzerland";
import AiMlpageSerbia from "./LandingPages/PythonLandingPages/AiMlpageSerbia/AiMlpageSerbia";
import AiMlpageMonaco from "./LandingPages/PythonLandingPages/AiMlPageMonaco/AiMlpageMonaco";
import AiMlpageMontenegro from "./LandingPages/PythonLandingPages/AiMlpageMontenegro/AiMlpageMontenegro";
import AiMlpageNetherlands from "./LandingPages/PythonLandingPages/AiMlpageNetherlands/AiMlpageNetherlands";
import AiMlpageNorthMacedonia from "./LandingPages/PythonLandingPages/AiMlpageNorthMacedonia/AiMlpageNorthMacedonia";
import AiMlpageNorway from "./LandingPages/PythonLandingPages/AiMlpageNorway/AiMlpageNorway";
import AiMlpagePortugal from "./LandingPages/PythonLandingPages/AiMlpagePortugal/AiMlpagePortugal";
import AiMlpageCroatia from "./LandingPages/PythonLandingPages/AiMlpageCroatia/AiMlpageCroatia";
import AiMlpageGermany from "./LandingPages/PythonLandingPages/AiMlpageGermany/AiMlpageGermany";
import AiMlpageHungary from "./LandingPages/PythonLandingPages/AiMlpageHungary/AiMlpageHungary";
import AiMlpageNamibia from "./LandingPages/PythonLandingPages/AiMlpageNamibia/AiMlpageNamibia";
import AiMlpageMiami from "./LandingPages/PythonLandingPages/AiMlpageMiam/AiMlpageMiam";
// import MasterClassUiUx from "./MasterClass/MasterClassUiUx";

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Router>
        <Routes>
          <Route exact path="/" element={<Homepage />} />
          <Route path="/online-training-courses" element={<OurProgrampage />} />
          <Route
            path="/online-python-computer-vision-training-course"
            element={<Pythonpage />}
          />
          <Route
            path="/online-full-stack-development-training-course"
            element={<FullStackpage />}
          />
          <Route
            path="/online-ios-development-training-course"
            element={<IOSpage />}
          />
          <Route
            path="/online-android-development-training-course"
            element={<Androidpage />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-training-course"
            element={<UIUXpage />}
          />
          {/* ------masterclass---- */}
          {/* <Route path="/masterclass-uiux" element={<MasterClassUiUx />} /> */}
          {/* <Route path="/masterclass-android" element={<MasterClassAndroid />} /> */}
          {/* <Route path="/masterclass-ios" element={<MasterClassiOS />} /> */}
          {/* <Route path="/masterclass-fullstack" element={<MasterClassFullStack />} /> */}
          <Route path="/masterclass-ai" element={<MasterClassAI />} />

          <Route path="/applypage" element={<Applypage />} />

          <Route path="/online-training" element={<Methodology />} />
          <Route path="/Fees-and-FAQs" element={<Feepage />} />
          <Route path="/enroll" element={<Enrollpage />} />
          <Route path="/about" element={<Aboutpage />} />
          <Route path="/reach-us" element={<Reachus />} />
          {/* <Route path="/terms/conditions" element={<Termspage />} /> */}
          {/* <Route path="/refund/policy" element={<Refundpage />} /> */}
          <Route path="/payment" element={<Pay />} />
          <Route path="/payment/success" element={<SuccessPayment />} />
          <Route path="/payment/receipt" element={<PdfTemplate />} />


          {/* ----------------------ai&ml landing pages---------------------- */}

          <Route
            path="/online-artificial-intelligence-development-training-course-Australia"
            element={<AiMlpageAustralia />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-singapore"
            element={<AiMlpageSingapore />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Bangladesh"
            element={<AiMlpageBangladesh />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Kenya"
            element={<AiMlpageKenya />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Muscat"
            element={<AiMlpageMuscat />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-South-africa"
            element={<AiMlpageSouthafrica />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Zimbabwe"
            element={<AiMlpageZimbabwe />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Ethiopia"
            element={<AiMlpageEthiopia />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Nepal"
            element={<AiMlpageNepal />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-New-Zealand"
            element={<AiMlpageNewZealand />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Nigeria"
            element={<AiMlpageNigeria />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Sri-Lanka"
            element={<AiMlpageSriLanka />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Uganda"
            element={<AiMlpageUganda />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Thailand"
            element={<AiMlpageThailand />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Philippines"
            element={<AiMlpagePhilippines />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Indonesia"
            element={<AiMlpageIndonesia />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-UK"
            element={<AiMlpageUK />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Ireland"
            element={<AiMlpageIreland />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Mexico"
            element={<AiMlpageMexico />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Brazil"
            element={<AiMlpageBrazil />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Vietnam"
            element={<AiMlpageVietnam />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Argentina"
            element={<AiMlpageArgentina />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Malaysia"
            element={<AiMlpageMalaysia />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Algeria"
            element={<AiMlpageAlgeria />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Sudan"
            element={<AiMlpageSudan />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Morocco"
            element={<AiMlpageMorocco />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Ghana"
            element={<AiMlpageGhana />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Angola"
            element={<AiMlpageAngola />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Mozambique"
            element={<AiMlpageMozambique />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Melbourne"
            element={<AiMlpageMelbourne />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Sydney"
            element={<AiMlpageSydney />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Brisbane"
            element={<AiMlpageBrisbane />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Perth"
            element={<AiMlpagePerth />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Adelaide"
            element={<AiMlpageAdelaide />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-London"
            element={<AiMlpageLondon />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Birmingham"
            element={<AiMlpageBirmingham />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Manchester"
            element={<AiMlpageManchester />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Nottingham"
            element={<AiMlpageNottingham />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Toronto"
            element={<AiMlpageToronto />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Kingston"
            element={<AiMlpageKingston />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Charlottetown"
            element={<AiMlpageCharlottetown />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Ottawa"
            element={<AiMlpageOttawa />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-New-York"
            element={<AiMlpageNewYork />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Los-Angeles"
            element={<AiMlpageLosAngeles />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Chicago"
            element={<AiMlpageChicago />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Houston"
            element={<AiMlpageHouston />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Phoenix"
            element={<AiMlpagePhoenix />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Philadelphia"
            element={<AiMlpagePhiladelphia />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-San-Antonio"
            element={<AiMlpageSanAntonio />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-San-Diego"
            element={<AiMlpageSanDiego />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Austin"
            element={<AiMlpageAustin />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Seattle"
            element={<AiMlpageSeattle />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Washington-DC"
            element={<AiMlpageWashingtonDC />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Las-Vegas"
            element={<AiMlpageLasVegas />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Miami"
            element={<AiMlpageMiami />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Indianapolis"
            element={<AiMlpageIndianapolis />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Fort-Worth"
            element={<AiMlpageFortWorth />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Glasgow"
            element={<AiMlpageGlasgow />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Liverpool"
            element={<AiMlpageLiverpool />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Edinburgh"
            element={<AiMlpageEdinburgh />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Bristol"
            element={<AiMlpageBristol />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Austria"
            element={<AiMlpageAustria />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Belgium"
            element={<AiMlpageBelgium />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Bulgaria"
            element={<AiMlpageBulgaria />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Denmark"
            element={<AiMlpageDenmark />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-France"
            element={<AiMlpageFrance />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Italy"
            element={<AiMlpageItaly />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Poland"
            element={<AiMlpagePoland />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Slovakia"
            element={<AiMlpageSlovakia />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Spain"
            element={<AiMlpageSpain />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Sweden"
            element={<AiMlpageSweden />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Switzerland"
            element={<AiMlpageSwitzerland />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Serbia"
            element={<AiMlpageSerbia />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Monaco"
            element={<AiMlpageMonaco />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Montenegro"
            element={<AiMlpageMontenegro />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Netherlands"
            element={<AiMlpageNetherlands />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-North-Macedonia"
            element={<AiMlpageNorthMacedonia />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Norway"
            element={<AiMlpageNorway />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Portugal"
            element={<AiMlpagePortugal />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Croatia"
            element={<AiMlpageCroatia />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Germany"
            element={<AiMlpageGermany />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Hungary"
            element={<AiMlpageHungary />}
          />
          <Route
            path="/online-artificial-intelligence-development-training-course-Namibia"
            element={<AiMlpageNamibia />}
          />



          {/* Pradeep-01/02/24 */}
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Bangalore"
            element={<PythonpageBangalore />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Delhi"
            element={<PythonpageDelhi />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Mumbai"
            element={<PythonpageMumbai />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Chennai"
            element={<PythonpageChennai />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Hyderabad"
            element={<PythonpageHyderabad />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Ahmedabad"
            element={<PythonpageAhmedabad />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Jaipur"
            element={<PythonpageJaipur />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Noida"
            element={<PythonpageNoida />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Gurugram"
            element={<PythonpageGurugram />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Chandigarh"
            element={<PythonpageChandigarh />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Lucknow"
            element={<PythonpageLucknow />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Kolkata"
            element={<PythonpageKolkata />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Bhubaneswar"
            element={<PythonpageBhubaneswar />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-India"
            element={<PythonpageIndia />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Bhopal"
            element={<PythonpageBhopal />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Kerala"
            element={<PythonpageKerala />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Vijayawada"
            element={<PythonpageVijayawada />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Tirupati"
            element={<PythonpageTirupati />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Amaravati"
            element={<PythonpageAmaravati />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Itanagar"
            element={<PythonpageItanagar />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Dispur"
            element={<PythonpageDispur />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Gandhinagar"
            element={<PythonpageGandhinagar />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Shimla"
            element={<PythonpageShimla />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Ranchi"
            element={<PythonpageRanchi />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Thiruvananthapuram"
            element={<PythonpageThiruvananthapuram />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Imphal"
            element={<PythonpageImphal />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Mysore"
            element={<PythonpageMysore />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Hubli-Dharwad"
            element={<PythonpageHubliDharwar />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Belgaum"
            element={<PythonpageBelgaum />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Gulbarga"
            element={<PythonpageGulbarga />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Bellary"
            element={<PythonpageBellary />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Surat"
            element={<PythonpageSurat />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Visakhapatnam"
            element={<PythonpageVisakhapatnam />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Coimbatore"
            element={<PythonpageCoimbatore />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Hosur"
            element={<PythonpageHosur />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Pimpare-chinchwad"
            element={<PythonpagePimparechinchwad />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Shivamoga"
            element={<PythonpageShivamoga />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Madurai"
            element={<PythonpageMadurai />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Tumkuru"
            element={<PythonpageTumkuru />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Mangalore"
            element={<PythonpageMangalore />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Udupi"
            element={<PythonpageUdupi />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Manipal"
            element={<PythonpageManipal />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Bahrain"
            element={<PythonpageBahrain />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Cyprus"
            element={<PythonpageCyprus />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Egypt"
            element={<PythonpageEgypt />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Iran"
            element={<PythonpageIran />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Iraq"
            element={<PythonpageIraq />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Israel"
            element={<PythonpageIsrael />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Jordan"
            element={<PythonpageJordan />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Kuwait"
            element={<PythonpageKuwait />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Lebanon"
            element={<PythonpageLebanon />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Oman"
            element={<PythonpageOman />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Palestine"
            element={<PythonpagePalestine />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Qatar"
            element={<PythonpageQatar />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Saudi-Arabia"
            element={<PythonpageSaudiArabia />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Syria"
            element={<PythonpageSyria />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Turkey"
            element={<PythonpageTurkey />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-UAE"
            element={<PythonpageUAE />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Yemen"
            element={<PythonpageYemen />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Abu-dhabi"
            element={<PythonpageAbudhabi />}
          />
          <Route
            path="/online-python-artificial-intelligence-development-training-course-Dubai"
            element={<PythonpageDubai />}
          />

          {/* Androidpage */}
          <Route
            path="/online-android-development-training-course-Bangalore"
            element={<AndroidpageBangalore />}
          />
          <Route
            path="/online-android-development-training-course-Delhi"
            element={<AndroidpageDelhi />}
          />
          <Route
            path="/online-android-development-training-course-Mumbai"
            element={<AndroidpageMumbai />}
          />
          <Route
            path="/online-android-development-training-course-Chennai"
            element={<AndroidpageChennai />}
          />
          <Route
            path="/online-android-development-training-course-Hyderabad"
            element={<AndroidpageHyderabad />}
          />
          <Route
            path="/online-android-development-training-course-Ahmedabad"
            element={<AndroidpageAhmedabad />}
          />
          <Route
            path="/online-android-development-training-course-Jaipur"
            element={<AndroidpageJaipur />}
          />
          <Route
            path="/online-android-development-training-course-Noida"
            element={<AndroidpageNoida />}
          />
          <Route
            path="/online-android-development-training-course-Gurugram"
            element={<AndroidpageGurugram />}
          />
          <Route
            path="/online-android-development-training-course-Chandigarh"
            element={<AndroidpageChandigarh />}
          />
          <Route
            path="/online-android-development-training-course-Lucknow"
            element={<AndroidpageLucknow />}
          />
          <Route
            path="/online-android-development-training-course-Kolkata"
            element={<AndroidpageKolkata />}
          />
          <Route
            path="/online-android-development-training-course-Bhubaneswar"
            element={<AndroidpageBhubaneswar />}
          />
          <Route
            path="/online-android-development-training-course-India"
            element={<AndroidpageIndia />}
          />
          <Route
            path="/online-android-development-training-course-Bhopal"
            element={<AndroidpageBhopal />}
          />
          <Route
            path="/online-android-development-training-course-Kerala"
            element={<AndroidpageKerala />}
          />
          <Route
            path="/online-android-development-training-course-Vijayawada"
            element={<AndroidpageVijayawada />}
          />
          <Route
            path="/online-android-development-training-course-Tirupati"
            element={<AndroidpageTirupati />}
          />
          <Route
            path="/online-android-development-training-course-Amaravati"
            element={<AndroidpageAmaravati />}
          />
          <Route
            path="/online-android-development-training-course-Itanagar"
            element={<AndroidpageItanagar />}
          />
          <Route
            path="/online-android-development-training-course-Dispur"
            element={<AndroidpageDispur />}
          />
          <Route
            path="/online-android-development-training-course-Gandhinagar"
            element={<AndroidpageGandhinagar />}
          />
          <Route
            path="/online-android-development-training-course-Shimla"
            element={<AndroidpageShimla />}
          />
          <Route
            path="/online-android-development-training-course-Ranchi"
            element={<AndroidpageRanchi />}
          />
          <Route
            path="/online-android-development-training-course-Thiruvananthapuram"
            element={<AndroidpageThiruvananthapuram />}
          />
          <Route
            path="/online-android-development-training-course-Imphal"
            element={<AndroidpageImphal />}
          />
          <Route
            path="/online-android-development-training-course-Mysore"
            element={<AndroidpageMysore />}
          />
          <Route
            path="/online-android-development-training-course-Hubli-Dharwad"
            element={<AndroidpageHubliDharwar />}
          />
          <Route
            path="/online-android-development-training-course-Belgaum"
            element={<AndroidpageBelgaum />}
          />
          <Route
            path="/online-android-development-training-course-Gulbarga"
            element={<AndroidpageGulbarga />}
          />
          <Route
            path="/online-android-development-training-course-Bellary"
            element={<AndroidpageBellary />}
          />
          <Route
            path="/online-android-development-training-course-Surat"
            element={<AndroidpageSurat />}
          />
          <Route
            path="/online-android-development-training-course-Visakhapatnam"
            element={<AndroidpageVisakhapatnam />}
          />
          <Route
            path="/online-android-development-training-course-Coimbatore"
            element={<AndroidpageCoimbatore />}
          />
          <Route
            path="/online-android-development-training-course-Hosur"
            element={<AndroidpageHosur />}
          />
          <Route
            path="/online-android-development-training-course-Pimpare-chinchwad"
            element={<AndroidpagePimparechinchwad />}
          />
          <Route
            path="/online-android-development-training-course-Shivamoga"
            element={<AndroidpageShivamoga />}
          />
          <Route
            path="/online-android-development-training-course-Madurai"
            element={<AndroidpageMadurai />}
          />
          <Route
            path="/online-android-development-training-course-Tumkuru"
            element={<AndroidpageTumkuru />}
          />
          <Route
            path="/online-android-development-training-course-Mangalore"
            element={<AndroidpageMangalore />}
          />
          <Route
            path="/online-android-development-training-course-Udupi"
            element={<AndroidpageUdupi />}
          />
          <Route
            path="/online-android-development-training-course-Manipal"
            element={<AndroidpageManipal />}
          />
          <Route
            path="/online-android-development-training-course-Bahrain"
            element={<AndroidpageBahrain />}
          />
          <Route
            path="/online-android-development-training-course-Cyprus"
            element={<AndroidpageCyprus />}
          />
          <Route
            path="/online-android-development-training-course-Egypt"
            element={<AndroidpageEgypt />}
          />
          <Route
            path="/online-android-development-training-course-Iran"
            element={<AndroidpageIran />}
          />
          <Route
            path="/online-android-development-training-course-Iraq"
            element={<AndroidpageIraq />}
          />
          <Route
            path="/online-android-development-training-course-Israel"
            element={<AndroidpageIsrael />}
          />
          <Route
            path="/online-android-development-training-course-Jordan"
            element={<AndroidpageJordan />}
          />
          <Route
            path="/online-android-development-training-course-Kuwait"
            element={<AndroidpageKuwait />}
          />
          <Route
            path="/online-android-development-training-course-Lebanon"
            element={<AndroidpageLebanon />}
          />
          <Route
            path="/online-android-development-training-course-Oman"
            element={<AndroidpageOman />}
          />
          <Route
            path="/online-android-development-training-course-Palestine"
            element={<AndroidpagePalestine />}
          />
          <Route
            path="/online-android-development-training-course-Qatar"
            element={<AndroidpageQatar />}
          />
          <Route
            path="/online-android-development-training-course-Saudi-Arabia"
            element={<AndroidpageSaudiArabia />}
          />
          <Route
            path="/online-android-development-training-course-Syria"
            element={<AndroidpageSyria />}
          />
          <Route
            path="/online-android-development-training-course-Turkey"
            element={<AndroidpageTurkey />}
          />
          <Route
            path="/online-android-development-training-course-UAE"
            element={<AndroidpageUAE />}
          />
          <Route
            path="/online-android-development-training-course-Yemen"
            element={<AndroidpageYemen />}
          />
          <Route
            path="/online-android-development-training-course-Abu-dhabi"
            element={<AndroidpageAbudhabi />}
          />
          <Route
            path="/online-android-development-training-course-Dubai"
            element={<AndroidpageDubai />}
          />
          {/* FullStack */}
          <Route
            path="/online-full-stack-development-training-course-Bangalore"
            element={<FullStackpageBangalore />}
          />
          <Route
            path="/online-full-stack-development-training-course-Delhi"
            element={<FullStackpageDelhi />}
          />
          <Route
            path="/online-full-stack-development-training-course-Mumbai"
            element={<FullStackpageMumbai />}
          />
          <Route
            path="/online-full-stack-development-training-course-Chennai"
            element={<FullStackpageChennai />}
          />
          <Route
            path="/online-full-stack-development-training-course-Hyderabad"
            element={<FullStackpageHyderabad />}
          />
          <Route
            path="/online-full-stack-development-training-course-Ahmedabad"
            element={<FullStackpageAhmedabad />}
          />
          <Route
            path="/online-full-stack-development-training-course-Jaipur"
            element={<FullStackpageJaipur />}
          />
          <Route
            path="/online-full-stack-development-training-course-Noida"
            element={<FullStackpageNoida />}
          />
          <Route
            path="/online-full-stack-development-training-course-Gurugram"
            element={<FullStackpageGurugram />}
          />
          <Route
            path="/online-full-stack-development-training-course-Chandigarh"
            element={<FullStackpageChandigarh />}
          />
          <Route
            path="/online-full-stack-development-training-course-Lucknow"
            element={<FullStackpageLucknow />}
          />
          <Route
            path="/online-full-stack-development-training-course-Kolkata"
            element={<FullStackpageKolkata />}
          />
          <Route
            path="/online-full-stack-development-training-course-Bhubaneswar"
            element={<FullStackpageBhubaneswar />}
          />
          <Route
            path="/online-full-stack-development-training-course-India"
            element={<FullStackpageIndia />}
          />
          <Route
            path="/online-full-stack-development-training-course-Bhopal"
            element={<FullStackpageBhopal />}
          />
          <Route
            path="/online-full-stack-development-training-course-Kerala"
            element={<FullStackpageKerala />}
          />
          <Route
            path="/online-full-stack-development-training-course-Vijayawada"
            element={<FullStackpageVijayawada />}
          />
          <Route
            path="/online-full-stack-development-training-course-Tirupati"
            element={<FullStackpageTirupati />}
          />
          <Route
            path="/online-full-stack-development-training-course-Amaravati"
            element={<FullStackpageAmaravati />}
          />
          <Route
            path="/online-full-stack-development-training-course-Itanagar"
            element={<FullStackpageItanagar />}
          />
          <Route
            path="/online-full-stack-development-training-course-Dispur"
            element={<FullStackpageDispur />}
          />
          <Route
            path="/online-full-stack-development-training-course-Gandhinagar"
            element={<FullStackpageGandhinagar />}
          />
          <Route
            path="/online-full-stack-development-training-course-Shimla"
            element={<FullStackpageShimla />}
          />
          <Route
            path="/online-full-stack-development-training-course-Ranchi"
            element={<FullStackpageRanchi />}
          />
          <Route
            path="/online-full-stack-development-training-course-Thiruvananthapuram"
            element={<FullStackpageThiruvananthapuram />}
          />
          <Route
            path="/online-full-stack-development-training-course-Imphal"
            element={<FullStackpageImphal />}
          />
          <Route
            path="/online-full-stack-development-training-course-Mysore"
            element={<FullStackpageMysore />}
          />
          <Route
            path="/online-full-stack-development-training-course-Hubli-Dharwad"
            element={<FullStackpageHubliDharwar />}
          />
          <Route
            path="/online-full-stack-development-training-course-Belgaum"
            element={<FullStackpageBelgaum />}
          />
          <Route
            path="/online-full-stack-development-training-course-Gulbarga"
            element={<FullStackpageGulbarga />}
          />
          <Route
            path="/online-full-stack-development-training-course-Bellary"
            element={<FullStackpageBellary />}
          />
          <Route
            path="/online-full-stack-development-training-course-Surat"
            element={<FullStackpageSurat />}
          />
          <Route
            path="/online-full-stack-development-training-course-Visakhapatnam"
            element={<FullStackpageVisakhapatnam />}
          />
          <Route
            path="/online-full-stack-development-training-course-Coimbatore"
            element={<FullStackpageCoimbatore />}
          />
          <Route
            path="/online-full-stack-development-training-course-Hosur"
            element={<FullStackpageHosur />}
          />
          <Route
            path="/online-full-stack-development-training-course-Pimpare-chinchwad"
            element={<FullStackpagePimparechinchwad />}
          />
          <Route
            path="/online-full-stack-development-training-course-Shivamoga"
            element={<FullStackpageShivamoga />}
          />
          <Route
            path="/online-full-stack-development-training-course-Madurai"
            element={<FullStackpageMadurai />}
          />
          <Route
            path="/online-full-stack-development-training-course-Tumkuru"
            element={<FullStackpageTumkuru />}
          />
          <Route
            path="/online-full-stack-development-training-course-Mangalore"
            element={<FullStackpageMangalore />}
          />
          <Route
            path="/online-full-stack-development-training-course-Udupi"
            element={<FullStackpageUdupi />}
          />
          <Route
            path="/online-full-stack-development-training-course-Manipal"
            element={<FullStackpageManipal />}
          />
          <Route
            path="/online-full-stack-development-training-course-Bahrain"
            element={<FullStackpageBahrain />}
          />
          <Route
            path="/online-full-stack-development-training-course-Cyprus"
            element={<FullStackpageCyprus />}
          />
          <Route
            path="/online-full-stack-development-training-course-Egypt"
            element={<FullStackpageEgypt />}
          />
          <Route
            path="/online-full-stack-development-training-course-Iran"
            element={<FullStackpageIran />}
          />
          <Route
            path="/online-full-stack-development-training-course-Iraq"
            element={<FullStackpageIraq />}
          />
          <Route
            path="/online-full-stack-development-training-course-Israel"
            element={<FullStackpageIsrael />}
          />
          <Route
            path="/online-full-stack-development-training-course-Jordan"
            element={<FullStackpageJordan />}
          />
          <Route
            path="/online-full-stack-development-training-course-Kuwait"
            element={<FullStackpageKuwait />}
          />
          <Route
            path="/online-full-stack-development-training-course-Lebanon"
            element={<FullStackpageLebanon />}
          />
          <Route
            path="/online-full-stack-development-training-course-Oman"
            element={<FullStackpageOman />}
          />
          <Route
            path="/online-full-stack-development-training-course-Palestine"
            element={<FullStackpagePalestine />}
          />
          <Route
            path="/online-full-stack-development-training-course-Qatar"
            element={<FullStackpageQatar />}
          />
          <Route
            path="/online-full-stack-development-training-course-Saudi-Arabia"
            element={<FullStackpageSaudiArabia />}
          />
          <Route
            path="/online-full-stack-development-training-course-Syria"
            element={<FullStackpageSyria />}
          />
          <Route
            path="/online-full-stack-development-training-course-Turkey"
            element={<FullStackpageTurkey />}
          />
          <Route
            path="/online-full-stack-development-training-course-UAE"
            element={<FullStackpageUAE />}
          />
          <Route
            path="/online-full-stack-development-training-course-Yemen"
            element={<FullStackpageYemen />}
          />
          <Route
            path="/online-full-stack-development-training-course-Abu-dhabi"
            element={<FullStackpageAbudhabi />}
          />
          <Route
            path="/online-full-stack-development-training-course-Dubai"
            element={<FullStackpageDubai />}
          />
          {/* ios */}
          <Route
            path="/online-ios-development-training-course-Bangalore"
            element={<IOSpageBangalore />}
          />
          <Route
            path="/online-ios-development-training-course-Delhi"
            element={<IOSpageDelhi />}
          />
          <Route
            path="/online-ios-development-training-course-Mumbai"
            element={<IOSpageMumbai />}
          />
          <Route
            path="/online-ios-development-training-course-Chennai"
            element={<IOSpageChennai />}
          />
          <Route
            path="/online-ios-development-training-course-Hyderabad"
            element={<IOSpageHyderabad />}
          />
          <Route
            path="/online-ios-development-training-course-Ahmedabad"
            element={<IOSpageAhmedabad />}
          />
          <Route
            path="/online-ios-development-training-course-Jaipur"
            element={<IOSpageJaipur />}
          />
          <Route
            path="/online-ios-development-training-course-Noida"
            element={<IOSpageNoida />}
          />
          <Route
            path="/online-ios-development-training-course-Gurugram"
            element={<IOSpageGurugram />}
          />
          <Route
            path="/online-ios-development-training-course-Chandigarh"
            element={<IOSpageChandigarh />}
          />
          <Route
            path="/online-ios-development-training-course-Lucknow"
            element={<IOSpageLucknow />}
          />
          <Route
            path="/online-ios-development-training-course-Kolkata"
            element={<IOSpageKolkata />}
          />
          <Route
            path="/online-ios-development-training-course-Bhubaneswar"
            element={<IOSpageBhubaneswar />}
          />
          <Route
            path="/online-ios-development-training-course-India"
            element={<IOSpageIndia />}
          />
          <Route
            path="/online-ios-development-training-course-Bhopal"
            element={<IOSpageBhopal />}
          />
          <Route
            path="/online-ios-development-training-course-Kerala"
            element={<IOSpageKerala />}
          />
          <Route
            path="/online-ios-development-training-course-Vijayawada"
            element={<IOSpageVijayawada />}
          />
          <Route
            path="/online-ios-development-training-course-Tirupati"
            element={<IOSpageTirupati />}
          />
          <Route
            path="/online-ios-development-training-course-Amaravati"
            element={<IOSpageAmaravati />}
          />
          <Route
            path="/online-ios-development-training-course-Itanagar"
            element={<IOSpageItanagar />}
          />
          <Route
            path="/online-ios-development-training-course-Dispur"
            element={<IOSpageDispur />}
          />
          <Route
            path="/online-ios-development-training-course-Gandhinagar"
            element={<IOSpageGandhinagar />}
          />
          <Route
            path="/online-ios-development-training-course-Shimla"
            element={<IOSpageShimla />}
          />
          <Route
            path="/online-ios-development-training-course-Ranchi"
            element={<IOSpageRanchi />}
          />
          <Route
            path="/online-ios-development-training-course-Thiruvananthapuram"
            element={<IOSpageThiruvananthapuram />}
          />
          <Route
            path="/online-ios-development-training-course-Imphal"
            element={<IOSpageImphal />}
          />
          <Route
            path="/online-ios-development-training-course-Mysore"
            element={<IOSpageMysore />}
          />
          <Route
            path="/online-ios-development-training-course-Hubli-Dharwad"
            element={<IOSpageHubliDharwar />}
          />
          <Route
            path="/online-ios-development-training-course-Belgaum"
            element={<IOSpageBelgaum />}
          />
          <Route
            path="/online-ios-development-training-course-Gulbarga"
            element={<IOSpageGulbarga />}
          />
          <Route
            path="/online-ios-development-training-course-Bellary"
            element={<IOSpageBellary />}
          />
          <Route
            path="/online-ios-development-training-course-Surat"
            element={<IOSpageSurat />}
          />
          <Route
            path="/online-ios-development-training-course-Visakhapatnam"
            element={<IOSpageVisakhapatnam />}
          />
          <Route
            path="/online-ios-development-training-course-Coimbatore"
            element={<IOSpageCoimbatore />}
          />
          <Route
            path="/online-ios-development-training-course-Hosur"
            element={<IOSpageHosur />}
          />
          <Route
            path="/online-ios-development-training-course-Pimpare-chinchwad"
            element={<IOSpagePimparechinchwad />}
          />
          <Route
            path="/online-ios-development-training-course-Shivamoga"
            element={<IOSpageShivamoga />}
          />
          <Route
            path="/online-ios-development-training-course-Madurai"
            element={<IOSpageMadurai />}
          />
          <Route
            path="/online-ios-development-training-course-Tumkuru"
            element={<IOSpageTumkuru />}
          />
          <Route
            path="/online-ios-development-training-course-Mangalore"
            element={<IOSpageMangalore />}
          />
          <Route
            path="/online-ios-development-training-course-Udupi"
            element={<IOSpageUdupi />}
          />
          <Route
            path="/online-ios-development-training-course-Manipal"
            element={<IOSpageManipal />}
          />
          <Route
            path="/online-ios-development-training-course-Bahrain"
            element={<IOSpageBahrain />}
          />
          <Route
            path="/online-ios-development-training-course-Cyprus"
            element={<IOSpageCyprus />}
          />
          <Route
            path="/online-ios-development-training-course-Egypt"
            element={<IOSpageEgypt />}
          />
          <Route
            path="/online-ios-development-training-course-Iran"
            element={<IOSpageIran />}
          />
          <Route
            path="/online-ios-development-training-course-Iraq"
            element={<IOSpageIraq />}
          />
          <Route
            path="/online-ios-development-training-course-Israel"
            element={<IOSpageIsrael />}
          />
          <Route
            path="/online-ios-development-training-course-Jordan"
            element={<IOSpageJordan />}
          />
          <Route
            path="/online-ios-development-training-course-Kuwait"
            element={<IOSpageKuwait />}
          />
          <Route
            path="/online-ios-development-training-course-Lebanon"
            element={<IOSpageLebanon />}
          />
          <Route
            path="/online-ios-development-training-course-Oman"
            element={<IOSpageOman />}
          />
          <Route
            path="/online-ios-development-training-course-Palestine"
            element={<IOSpagePalestine />}
          />
          <Route
            path="/online-ios-development-training-course-Qatar"
            element={<IOSpageQatar />}
          />
          <Route
            path="/online-ios-development-training-course-Saudi-Arabia"
            element={<IOSpageSaudiArabia />}
          />
          <Route
            path="/online-ios-development-training-course-Syria"
            element={<IOSpageSyria />}
          />
          <Route
            path="/online-ios-development-training-course-Turkey"
            element={<IOSpageTurkey />}
          />
          <Route
            path="/online-ios-development-training-course-UAE"
            element={<IOSpageUAE />}
          />
          <Route
            path="/online-ios-development-training-course-Yemen"
            element={<IOSpageYemen />}
          />
          <Route
            path="/online-ios-development-training-course-Abu-dhabi"
            element={<IOSpageAbudhabi />}
          />
          <Route
            path="/online-ios-development-training-course-Dubai"
            element={<IOSpageDubai />}
          />
          {/* UIUX */}
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Bangalore"
            element={<UIUXpageBangalore />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Delhi"
            element={<UIUXpageDelhi />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Mumbai"
            element={<UIUXpageMumbai />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Chennai"
            element={<UIUXpageChennai />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Hyderabad"
            element={<UIUXpageHyderabad />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Ahmedabad"
            element={<UIUXpageAhmedabad />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Jaipur"
            element={<UIUXpageJaipur />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Noida"
            element={<UIUXpageNoida />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Gurugram"
            element={<UIUXpageGurugram />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Chandigarh"
            element={<UIUXpageChandigarh />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Lucknow"
            element={<UIUXpageLucknow />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Kolkata"
            element={<UIUXpageKolkata />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Bhubaneswar"
            element={<UIUXpageBhubaneswar />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-India"
            element={<UIUXpageIndia />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Bhopal"
            element={<UIUXpageBhopal />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Kerala"
            element={<UIUXpageKerala />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Vijayawada"
            element={<UIUXpageVijayawada />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Tirupati"
            element={<UIUXpageTirupati />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Amaravati"
            element={<UIUXpageAmaravati />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Itanagar"
            element={<UIUXpageItanagar />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Dispur"
            element={<UIUXpageDispur />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Gandhinagar"
            element={<UIUXpageGandhinagar />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Shimla"
            element={<UIUXpageShimla />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Ranchi"
            element={<UIUXpageRanchi />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Thiruvananthapuram"
            element={<UIUXpageThiruvananthapuram />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Imphal"
            element={<UIUXpageImphal />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Mysore"
            element={<UIUXpageMysore />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Hubli-Dharwad"
            element={<UIUXpageHubliDharwar />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Belgaum"
            element={<UIUXpageBelgaum />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Gulbarga"
            element={<UIUXpageGulbarga />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Bellary"
            element={<UIUXpageBellary />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Surat"
            element={<UIUXpageSurat />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Visakhapatnam"
            element={<UIUXpageVisakhapatnam />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Coimbatore"
            element={<UIUXpageCoimbatore />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Hosur"
            element={<UIUXpageHosur />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Pimpare-chinchwad"
            element={<UIUXpagePimparechinchwad />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Shivamoga"
            element={<UIUXpageShivamoga />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Madurai"
            element={<UIUXpageMadurai />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Tumkuru"
            element={<UIUXpageTumkuru />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Mangalore"
            element={<UIUXpageMangalore />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Udupi"
            element={<UIUXpageUdupi />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Manipal"
            element={<UIUXpageManipal />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Bahrain"
            element={<UIUXpageBahrain />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Cyprus"
            element={<UIUXpageCyprus />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Egypt"
            element={<UIUXpageEgypt />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Iran"
            element={<UIUXpageIran />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Iraq"
            element={<UIUXpageIraq />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Israel"
            element={<UIUXpageIsrael />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Jordan"
            element={<UIUXpageJordan />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Kuwait"
            element={<UIUXpageKuwait />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Lebanon"
            element={<UIUXpageLebanon />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Oman"
            element={<UIUXpageOman />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Palestine"
            element={<UIUXpagePalestine />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Qatar"
            element={<UIUXpageQatar />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Saudi-Arabia"
            element={<UIUXpageSaudiArabia />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Syria"
            element={<UIUXpageSyria />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Turkey"
            element={<UIUXpageTurkey />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-UAE"
            element={<UIUXpageUAE />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Yemen"
            element={<UIUXpageYemen />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Abu-dhabi"
            element={<UIUXpageAbudhabi />}
          />
          <Route
            path="/online-UI-UX-graphic-designing-development-training-course-Dubai"
            element={<UIUXpageDubai />}
          />
          <Route path="*" element={<Errorpage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
