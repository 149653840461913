import React from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import "../../PythonLandingPages/AiMlcss/AiMl.css"
import { Carousel } from 'react-responsive-carousel';
import Slider from 'react-slick';
import transform from "../../../assets/landingpage/transform.png"
import image from "../../../assets/CV.svg";
import image1 from "../../../assets/CV 1.svg";
import python from "../../../assets/landingpage/python.png"
import pytorch from "../../../assets/landingpage/pytorch.png"
import tensorflow from "../../../assets/landingpage/tensorflow.png"
import docker from "../../../assets/landingpage/docker.png"
import yolo from "../../../assets/landingpage/yolo.png"
import cuda from "../../../assets/landingpage/cuda.png"
import kafka from "../../../assets/landingpage/kafka.png"
import openpose from '../../../assets/landingpage/openpose.png'
import ocr from "../../../assets/landingpage/ocr.png"
import opencv from "../../../assets/landingpage/opencv.png"
import card1 from "../../../assets/landingpage/card-1.svg"
import card2 from "../../../assets/landingpage/card-2.svg"
import card3 from "../../../assets/landingpage/card-3.svg"
import card4 from "../../../assets/landingpage/card-4.svg"
import card5 from "../../../assets/landingpage/card-5.svg"
import card6 from "../../../assets/landingpage/card-6.svg"
import card7 from "../../../assets/landingpage/card-7.svg"
import card8 from "../../../assets/landingpage/card-8.svg"
import card9 from "../../../assets/landingpage/card-9.svg"
import card10 from "../../../assets/landingpage/card-10.svg"
import Header from "../../../Header/Header";
import THeader from "../../../THeader/THeader";
import Form from "../../../Form/Form";
import Footer from "../../../Footer/Footer";
import { useRef } from "react";

const AiMlpageSouthafrica = () => {
    const [currentIndex1, setCurrentIndex1] = useState(0);
    const [currentIndex2, setCurrentIndex2] = useState(0);
    const text2 = "{ Codingeni }"
    const formRef = useRef(null);
    const handleScrollInto = () => {
        formRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const videoUrls = [
        "https://www.youtube.com/embed/fHY8yOda9KM?si=hNDEVEMNiJJo2mCP",
        "https://www.youtube.com/embed/DZdlYu5KOag?si=48lU4G6eVCxT-mtL",
        "https://www.youtube.com/embed/q2nDwjqyhrA?si=_uEfr0qiNvOH9RnE",
        "https://www.youtube.com/embed/m8MuNAP1YLk?si=UlMetfsBgTlZD4R-",
        "https://www.youtube.com/embed/TrcvZCuS23w?si=clpFk5wOKW5m702L",
        "https://www.youtube.com/embed/-2M1QFsXFl0?si=CKxKhLjELLXwOnw6",
        // "https://www.youtube.com/embed/_biGfmuIld4",
        // "https://www.youtube.com/embed/l3Ys8UekYWQ",
        // "https://www.youtube.com/embed/b6mi9bRIV0o",
    ]

    const handlePrevClick = () => {
        setCurrentIndex1((prevIndex) => Math.max(prevIndex - 3, 0));
    };

    const handleNextClick = () => {
        setCurrentIndex1((prevIndex) => Math.min(prevIndex + 3, 6));
    };

    const handlePrevClickM = () => {
        setCurrentIndex2((prevIndex) =>
            prevIndex === 0 ? videoUrls.length - 1 : prevIndex - 1
        );
    };

    const handleNextClickM = () => {
        setCurrentIndex2((prevIndex) =>
            prevIndex === videoUrls.length - 1 ? 0 : prevIndex + 1
        );
    };

    return (
        <div className="main-container">
            <Helmet>
                <title>
                    AI & Machine Learning Online Instructor-led Developer Training with Internship in South-africa
                </title>
                <meta
                    name="description"
                    content="Codingeni provide advance your skills with our comprehensive online course in AI, Machine Learning, and Computer Vision in South-africa. Gain practical experience, learn from industry experts, and work on real-world projects. Enroll now to master cutting-edge technologies and boost your career."
                />
                <meta
                    name="keyword"
                    content="online artificial intelligence Development courses in South-africa, online artificial intelligence course in South-africa, online ai courses in South-africa, online ai training in South-africa, Online ai training courses in South-africa, online ai learning courses in South-africa, Online ai training programs in South-africa, online ai engineer course in South-africa, online Machine learning course in South-africa, online ML courses in South-africa , Online ML course programs in South-africa, online ML engineer course in South-africa, Online computer vision course in Bangalore, online Computer vision training program in South-africa, online Computer vision course program in South-africa."
                />
                <link
                    rel="canonical"
                    href="https://www.codingeni.com/online-artificial-intelligence-development-training-course-South-africa"
                />
            </Helmet>
            <THeader />
            <Header />
            <div className="Section-Ai">
                <div className="smaple-div">
                    <div className="background2-container">
                        <div className="inner-container">
                            <h1>TRANSFORM</h1>
                            <img src={transform} alt="classtocubicle" />
                            <h3>from graduates ------------- job-ready</h3>
                            <p>With almost everything in the world relying on technology, wanting for techies especially good coders will never end.
                                Coding is not theoretical, it’s practical. Do it to learn it.</p>
                        </div>
                    </div>
                </div>
                <div className="text-containerai">
                    <h2>{text2}</h2>
                    <p>Welcome to Techready, a bridge program taking you to the career and industry you’re looking forward to with building some of the best and exciting products & projects, equipping you with real world, commercial product developments in artificial intelligence, deep learning, IoT and robotics. From training to fulltime commercial internships to jobs, techready gets you ready for the industry not just by writing on a board, but you will be working on live commercial projects, building disruptive products in deep learning or full stack or IoT embedded platform, whichever stream you choose</p>
                    <p>Our approach to learning is ‘Build to Learn’ wherein every intern with us will be developing products in AI mentored, guided by our experienced developers and engineers. This gives you the edge to learn while to build with an unmatched experience of developing a full scale commercial product that’s sold to our customers.</p>
                    <p>The difference is that you become an experienced AI developer during this Build to Learn Transform program with Techready and you’re industry ready matching the skills industry wants, thereby increasing the chances of you getting your dream AI job.</p>
                </div>
                <div className="background" >
                    <div className="AiMl_Developement">
                        <div className="Text_Holder_AiMl">
                            <h1>Machine Learning Developer</h1>
                            <h3>In South-africa</h3>
                            <p>
                                Learning Python today is a must for every aspiring coder as this is the most widely used tech for AI, Web & Desktop applications, Embedded electronics, Medical engineering, etc. This is sort of become a universal tech platform for every product and sector. Learning Python is essential if you want to be into the exploding field of AI.
                            </p>
                            <div className="Apply_Now_button_AiMl">
                                <button className="Apply-AiMl" onClick={handleScrollInto}>Click for Enquiry</button>
                            </div>
                        </div>
                        <div className="AiMl_Image">
                            <img src={image1} alt="AiMl_Develop" />
                        </div>
                    </div>
                    <div className="Text_Holder_AiMl2">
                        <h1>AI - The Leading Tech</h1>
                        <p>
                            With the world moving towards artificial intelligence and automation, AI Developer or Computer Vision Engineer will play a pivotal role in furthering the technology, job market and economy of the country to a great extent. While it’s a fact that the future belongs to an AI Developer, it’s also true that our graduates are far from being ready for this challenging and exiting career. The building block of any computer vision coding is Python. Master the art of python coding from the makers of AI products such as face recognition, object detection & recognition, intrusion detection, and other such data & video analytics platform. Sr Python devs will be your instructor, mentor and path finder towards making you the perfect python developer enabling you to make a prospective career in AI.
                        </p>
                        <div className="Image_Holder_Landingpage">
                            <div className="Animation_Holder_Landingpage">
                                <div className="Circle_Animation_Landingpage"></div>
                                <img src={image} alt="Computer Vision" />
                            </div>
                        </div >
                    </div>
                    <div className="AiMl-tools">
                        <h1>Tools & Languages you will be trained on</h1>
                        <div className="AiMl-tools-container">
                            <div className="box">
                                <p>1. Python</p>
                                <img src={python} alt="python" />
                            </div>
                            <div className="box">
                                <p>2.PyTorch</p>
                                <img src={pytorch} alt="PyTorch" />
                            </div>
                            <div className="box">
                                <p>3. TensorFlow</p>
                                <img src={tensorflow} alt="TensorFlow" />
                            </div>
                            <div className="box">
                                <p>4. Docker</p>
                                <img src={docker} alt="Docker" />
                            </div>
                            <div className="box">
                                <p>5. Yolo</p>
                                <img src={yolo} alt="Yolo" />
                            </div>
                            <div className="box">
                                <p>6. Cuda</p>
                                <img src={cuda} alt="Cuda" />
                            </div>
                            <div className="box">
                                <p>7. Kafka</p>
                                <img src={kafka} alt="Kafka" />
                            </div>
                            <div className="box">
                                <p>8. Openpose</p>
                                <img src={openpose} alt="Openpose" />
                            </div>
                            <div className="box">
                                <p>9. OCR</p>
                                <img src={ocr} alt="ocr" />
                            </div>
                            <div className="box">
                                <p>10. Open CV</p>
                                <img src={opencv} alt="Open CV" />
                            </div>
                        </div>
                    </div>
                    <div className="AiMl-projects">
                        <h1>Projects we work on</h1>
                        <div className="project-holder">
                            <div className="project-card">
                                <img src={card1} alt="card1" />
                                <div className="paragraph">
                                    <p>Face recognition time & attendance system</p>
                                </div>
                            </div>
                            <div className="project-card">
                                <img src={card2} alt="card2" />
                                <div className="paragraph">
                                    <p>ANPR – Vehicle traffic management video analytics</p>
                                </div>
                            </div>
                            <div className="project-card">
                                <img src={card3} alt="card3" />
                                <div className="paragraph">
                                    <p>REACT – Intrusion & breach detection alarms</p>
                                </div>
                            </div>
                            <div className="project-card">
                                <img src={card4} alt="card4" />
                                <div className="paragraph">
                                    <p>ATM security and operation analytics</p>
                                </div>
                            </div>
                            <div className="project-card">
                                <img src={card5} alt="card5" />
                                <div className="paragraph">
                                    <p>Retailytics – People counting, crowd heat mapping, regular customer alerts</p>
                                </div>
                            </div>
                            <div className="project-card">
                                <img src={card6} alt="card6" />
                                <div className="paragraph">
                                    <p>Fire and smoke detection video analytics</p>
                                </div>
                            </div>
                            <div className="project-card">
                                <img src={card7} alt="card7" />
                                <div className="paragraph">
                                    <p>Safety gear violation tracking</p>
                                </div>
                            </div>
                            <div className="project-card">
                                <img src={card8} alt="card8" />
                                <div className="paragraph">
                                    <p>Robotic BOT EKA</p>
                                </div>
                            </div>
                            <div className="project-card">
                                <img src={card9} alt="card9" />
                                <div className="paragraph">
                                    <p>Billboard view count & demographics unified</p>
                                </ div>
                            </div>
                            <div className="project-card">
                                <img src={card10} alt="card10" />
                                <div className="paragraph">
                                    <p>Perimeter intrusion detection</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="intern-works">
                        <h1>Few works of our Interns</h1>
                        <div className="carousel-container">
                            <div className="Button_Left" onClick={handlePrevClick}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="40"
                                    height="40"
                                    viewBox="0 0 40 40"
                                    fill="none"
                                    className={`left-button ${currentIndex1 === 0 ? "hidden" : ""
                                        }`}
                                >
                                    <path
                                        className="Arrow_Left"
                                        d="M28 19C28.5523 19 29 19.4477 29 20C29 20.5523 28.5523 21 28 21V19ZM11.2929 20.7071C10.9024 20.3166 10.9024 19.6834 11.2929 19.2929L17.6569 12.9289C18.0474 12.5384 18.6805 12.5384 19.0711 12.9289C19.4616 13.3195 19.4616 13.9526 19.0711 14.3431L13.4142 20L19.0711 25.6569C19.4616 26.0474 19.4616 26.6805 19.0711 27.0711C18.6805 27.4616 18.0474 27.4616 17.6569 27.0711L11.2929 20.7071ZM28 21L12 21V19L28 19V21Z"
                                        fill="white"
                                    />
                                    <circle
                                        className="Circle"
                                        cx="20"
                                        cy="20"
                                        r="19"
                                        transform="matrix(-1 0 0 1 40 0)"
                                        stroke="white"
                                        stroke-width="2"
                                    />
                                </svg>
                            </div>
                            <div className="Image_carousel">
                                <div
                                    className="carousel-inner"
                                    style={{
                                        transform: `translateX(-${currentIndex1 * (101 / 9)}%)`,
                                    }}
                                >
                                    <iframe
                                        title="Facesense Demo Video"
                                        src="https://www.youtube.com/embed/fHY8yOda9KM?si=hNDEVEMNiJJo2mCP"
                                    ></iframe>
                                    <iframe
                                        title="Facesense Demo Video"
                                        src="https://www.youtube.com/embed/DZdlYu5KOag?si=48lU4G6eVCxT-mtL"
                                    ></iframe>
                                    <iframe
                                        title="Facesense Demo Video"
                                        src="https://www.youtube.com/embed/q2nDwjqyhrA?si=_uEfr0qiNvOH9RnE"
                                    ></iframe>
                                    <iframe
                                        title="Facesense Demo Video"
                                        src="https://www.youtube.com/embed/m8MuNAP1YLk?si=UlMetfsBgTlZD4R-"
                                    ></iframe>
                                    <iframe
                                        title="Facesense Demo Video"
                                        src="https://www.youtube.com/embed/TrcvZCuS23w?si=clpFk5wOKW5m702L"
                                    ></iframe>
                                    <iframe
                                        title="Facesense Demo Video"
                                        src="https://www.youtube.com/embed/-2M1QFsXFl0?si=CKxKhLjELLXwOnw6"
                                    ></iframe>
                                    {/* <iframe
                                        title="Facesense Demo Video"
                                        src="https://www.youtube.com/embed/_biGfmuIld4"
                                    ></iframe>
                                    <iframe
                                        title="Facesense Demo Video"
                                        src="https://www.youtube.com/embed/l3Ys8UekYWQ"
                                    ></iframe>
                                    <iframe
                                        title="Facesense Demo Video"
                                        src="https://www.youtube.com/embed/b6mi9bRIV0o"
                                    ></iframe> */}
                                </div>
                            </div>
                            <div className="Button_Right" onClick={handleNextClick}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="40"
                                    height="40"
                                    viewBox="0 0 40 40"
                                    fill="none"
                                    className={`right-button ${currentIndex1 === 6 ? "hidden" : ""
                                        }`}
                                >
                                    <path
                                        className="Arrow_Right"
                                        d="M12 19C11.4477 19 11 19.4477 11 20C11 20.5523 11.4477 21 12 21V19ZM28.7071 20.7071C29.0976 20.3166 29.0976 19.6834 28.7071 19.2929L22.3431 12.9289C21.9526 12.5384 21.3195 12.5384 20.9289 12.9289C20.5384 13.3195 20.5384 13.9526 20.9289 14.3431L26.5858 20L20.9289 25.6569C20.5384 26.0474 20.5384 26.6805 20.9289 27.0711C21.3195 27.4616 21.9526 27.4616 22.3431 27.0711L28.7071 20.7071ZM12 21L28 21V19L12 19V21Z"
                                        fill="white"
                                    />
                                    <circle
                                        className="Circle"
                                        cx="20"
                                        cy="20"
                                        r="19"
                                        stroke="white"
                                        stroke-width="2"
                                    />
                                </svg>
                            </div>
                        </div>
                        <div className="carousel-container-mobile">
                            <div className="Button_Left" onClick={handlePrevClickM}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="40"
                                    height="40"
                                    viewBox="0 0 40 40"
                                    fill="none"
                                    className={`left-button ${currentIndex1 === 0 ? "hidden" : ""
                                        }`}
                                >
                                    <path
                                        className="Arrow_Left"
                                        d="M28 19C28.5523 19 29 19.4477 29 20C29 20.5523 28.5523 21 28 21V19ZM11.2929 20.7071C10.9024 20.3166 10.9024 19.6834 11.2929 19.2929L17.6569 12.9289C18.0474 12.5384 18.6805 12.5384 19.0711 12.9289C19.4616 13.3195 19.4616 13.9526 19.0711 14.3431L13.4142 20L19.0711 25.6569C19.4616 26.0474 19.4616 26.6805 19.0711 27.0711C18.6805 27.4616 18.0474 27.4616 17.6569 27.0711L11.2929 20.7071ZM28 21L12 21V19L28 19V21Z"
                                        fill="white"
                                    />
                                    <circle
                                        className="Circle"
                                        cx="20"
                                        cy="20"
                                        r="19"
                                        transform="matrix(-1 0 0 1 40 0)"
                                        stroke="white"
                                        stroke-width="2"
                                    />
                                </svg>
                            </div>
                            <div className="Image_carousel">
                                <div
                                    className="carousel-inner"
                                    style={{
                                        transform: `translateX(-${currentIndex2 * (104.5 / 9)}%)`,
                                    }}
                                >
                                    {videoUrls.map((url, index) => (
                                        <iframe
                                            key={index}
                                            title={`Facesense Demo Video ${index}`}
                                            src={url}
                                        ></iframe>
                                    ))}
                                </div>
                            </div>
                            <div className="Button_Right" onClick={handleNextClickM}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="40"
                                    height="40"
                                    viewBox="0 0 40 40"
                                    fill="none"
                                    className={`right-button ${currentIndex1 === 6 ? "hidden" : ""
                                        }`}
                                >
                                    <path
                                        className="Arrow_Right"
                                        d="M12 19C11.4477 19 11 19.4477 11 20C11 20.5523 11.4477 21 12 21V19ZM28.7071 20.7071C29.0976 20.3166 29.0976 19.6834 28.7071 19.2929L22.3431 12.9289C21.9526 12.5384 21.3195 12.5384 20.9289 12.9289C20.5384 13.3195 20.5384 13.9526 20.9289 14.3431L26.5858 20L20.9289 25.6569C20.5384 26.0474 20.5384 26.6805 20.9289 27.0711C21.3195 27.4616 21.9526 27.4616 22.3431 27.0711L28.7071 20.7071ZM12 21L28 21V19L12 19V21Z"
                                        fill="white"
                                    />
                                    <circle
                                        className="Circle"
                                        cx="20"
                                        cy="20"
                                        r="19"
                                        stroke="white"
                                        stroke-width="2"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div ref={formRef} className="Form_1">
                <Form />
            </div>
            <Footer />
        </div>
    )
}

export default AiMlpageSouthafrica