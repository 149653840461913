import React, { useEffect, useState } from "react";
import axios from "axios"
import image from "../images/Codingeni.svg";
import "./Pay.css";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_URL } from "../constants";
import Logo from "../assets/logo.png";

const Pay = () => {
  const location = useLocation();
  const data = location.state;
  const navigate = useNavigate();

  const [formData, setFormData] = useState({});

  const handlePayment = async (amount) => {
    const {
      data: { key },
    } = await axios.get(`${BASE_URL}/payment/get_key`);

    const {
      data: { order },
    } = await axios.post(`${BASE_URL}/payment/order`, {
      amount,
      userId: data._id,
    });

    const options = {
      key: key,
      amount: order.amount,
      currency: "INR",
      name: "Safepro AI Video Research Lab Pvt. Ltd.",
      description: "Test Transaction",
      image: Logo,
      order_id: order.id,
      callback_url: `${BASE_URL}/payment/payment_verify`,
      notes: {
        address: "Razorpay Corporate Office",
      },
      modal: {
        animation: true,
      },
      theme: {
        color: "#1A1A1A",
      },
    };
    var razor = new window.Razorpay(options);
    razor.open();
  };

  const getData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/user/get-user/${data._id}`
      );
      if (response.data) {
        setFormData(response.data);
      } else {
        alert("Something went wrong, Page not found");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (data === null) {
      navigate("/enroll");
    }

    getData();

    function handlePopState(event) { }

    // window.addEventListener("popstate", handlePopState, false);
    window.onpopstate = () => {
      var r = window.confirm("You pressed a Back button! Are you sure?!");

      if (r === true) {
        window.history.back();
      } else {
        window.history.pushState(null, null, window.location.pathname);
      }
    };

    return () => {
      window.removeEventListener("popstate", handlePopState, false);
    };

  }, []);
  return (
    <div>
      <div className="Paypage">
        <div className="P_Contents">
          <img src={image} alt="Codingeni" />
          <h1>Course Payment Details</h1>
          <div className="PayPreview">
            <div className="Review">
              <div className="Fee_details">
                <h1>Track Chosen</h1>
                <h3>:</h3>
                <p>{formData?.stream}</p>
              </div>
              <div className="Fee_details">
                <h1>Course Fee</h1>
                <h3>:</h3>
                <p>₹45,000</p>
              </div>
              <div className="Fee_details">
                <h1>GST Charges(18%)</h1>
                <h3>:</h3>
                <p>₹8,100</p>
              </div>
              <div className="Fee_details">
                <h1>
                  <strong>Total Amount</strong>
                </h1>
                <h3>:</h3>
                <p>
                  <strong>₹53,100</strong>
                </p>
              </div>

              <div className="Pay_Button">
                <button className="buttoN" onClick={() => handlePayment(53100)}>
                  Pay Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pay;
