import "./Footer.css";
import logo from "../assets/Codingeni.svg";

function Footer() {
  return (
    <footer>
      <div className="Footer">
        <div className="Footer_Elements">
          <div className="Footer_Part_1">
            <img src={logo} alt="Codingeni" />
          </div>
          <div className="Footer_Part_2">
            <h1>Our Programs</h1>
            <div className="Course">
              <a href="/online-python-computer-vision-training-course">
                AI Developer
              </a>
              <a href="/online-full-stack-development-training-course">
                Full Stack Developer
              </a>
              <a href="/online-ios-development-training-course">
                iOS Developer
              </a>
              <a href="/online-android-development-training-course">
                Android Developer
              </a>
              <a href="/online-UI-UX-graphic-designing-training-course">
                UI/UX & Graphic Designer
              </a>
            </div>
          </div>
          <div className="Footer_Part_3">
            <h1>
              Reach us <br />
              <br />
              <span> Codingeni | Okulr Techminds</span>
            </h1>
            <div className="Contact">
              <div className="content">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                >
                  <path
                    d="M12.4732 15.4891C14.4548 17.4561 16.7485 19.3352 17.6489 18.4279C18.9382 17.1292 19.7318 15.9992 22.5929 18.2809C25.4539 20.5637 23.2689 22.1 22.0201 23.3584C20.5791 24.8104 15.1819 23.4602 9.83202 18.1509C4.48251 12.8406 3.09112 7.45386 4.53319 6.00125C5.78231 4.74271 7.30222 2.54681 9.60493 5.39118C11.9088 8.23411 10.7849 9.03639 9.49557 10.3355C8.59553 11.2425 10.4913 13.5216 12.4732 15.4891Z"
                    fill="#00FF00"
                  />
                </svg>
                <a href="tel:+91 82174 02144">+91 82174 02144</a>
              </div>
              <div className="content">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14 15.125L23.375 7H4.625L14 15.125ZM11.4678 14.2256L14 16.304L16.4925 14.2256L23.375 20.125H4.625L11.4678 14.2256ZM4 19.5V7.625L10.875 13.5625L4 19.5ZM24 19.5V7.625L17.125 13.5625L24 19.5Z"
                    fill="#00FF00"
                  />
                </svg>
                <a href="mailto:contact@codingeni.com">contact@codingeni.com</a>
              </div>
              <div className="content">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                >
                  <path
                    d="M13.9403 4C10.1134 4 7 7.11385 7 10.9409C7 14.6248 13.2971 23.2316 13.5652 23.596L13.8154 23.9366C13.8447 23.9766 13.8913 24 13.9403 24C13.9901 24 14.0364 23.9766 14.066 23.9366L14.3161 23.596C14.5843 23.2316 20.8813 14.6248 20.8813 10.9409C20.8813 7.11385 17.7674 4 13.9403 4ZM13.9403 8.45468C15.3115 8.45468 16.4266 9.56974 16.4266 10.9409C16.4266 12.3113 15.3115 13.4271 13.9403 13.4271C12.5699 13.4271 11.4541 12.3113 11.4541 10.9409C11.4541 9.56974 12.5699 8.45468 13.9403 8.45468Z"
                    fill="#00FF00"
                  />
                </svg>
                <a
                  href="https://maps.app.goo.gl/Hzbf9pho5WSdX39v6"
                  target="_blank"
                  rel="noreferrer"
                >
                  Bengaluru, Karnataka 560010
                </a>
              </div>
            </div>
          </div>
          <div className="Footer_Part_4">
            <div className="linE"></div>
            <div className="Footer_End">
              <h6>© 2024 All Rights Reserved by Okulr Techminds.</h6>
              {/* <p>
                <a href="/terms/conditions" target="_blank" rel="noreferrer">
                  Terms & Conditions |{" "}
                </a>
                <a
                  href="https://merchant.razorpay.com/policy/MqRy2ZJgq73zlc/privacy"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy |{" "}
                </a>
                <a href="/refund/policy" target="_blank" rel="noreferrer">
                  Cancellation&nbsp;&&nbsp;Refund |{" "}
                </a>
                <a
                  href="https://merchant.razorpay.com/policy/MqRy2ZJgq73zlc/shipping"
                  target="_blank"
                  rel="noreferrer"
                >
                  Shipping&nbsp;&&nbsp;Delivery&nbsp;Policy
                </a>
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
