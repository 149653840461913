import "./Homepage.css";
import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";
// import AnimatedText from "../Homepage_Animation/HomeAnimation";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import THeader from "../THeader/THeader";
import Header from "../Header/Header";
import Form from "../Form/Form";
import Footer from "../Footer/Footer";
import icon1 from "../assets/Python.svg";
import icon2 from "../assets/FullStack.svg";
import icon3 from "../assets/IOS.svg";
import icon4 from "../assets/Android.svg";
import icon5 from "../assets/UIUX.svg";

function Homepage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);
  const handleScrollInto = () => {
    const targetElement = document.querySelector(".Section_2");

    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const handleLeft = () => {
    const Carousel_Container = document.querySelector(".Carousel");
    Carousel_Container.scrollLeft -= 500;
  };

  const handleRight = () => {
    const Carousel_Container = document.querySelector(".Carousel");
    Carousel_Container.scrollLeft += 500;
  };

  return (
    <div>
      <Helmet>
        <title>
          Online Training Courses | Best Learning Platform - Codingeni
        </title>
        <meta
          name="description"
          content="Codingeni is the best online learning platform in India. Explore our online course and learn full stack development, android, iOS etc. and work on live commercial projects.
          "
        />
        <meta
          name="keyword"
          content="Codingeni, online training course in India, online learning platform in India, online course in India, online training course, online learning platform, learning courses, best online coding courses, best online courses platform.
          "
        />
        <link rel="canonical" href="https://www.codingeni.com" />
      </Helmet>
      <div className="NormalScroll">
        <THeader />
        <Header />
        <section className="Section_1">
          <div className="Section_Contents">
            <div className="Mid_Text">
              <h1 className="gradient-animation">Bridge the _ gap;</h1>
              {/* <AnimatedText /> */}
              <h2>from graduates ------------- to job-ready</h2>
              <h3>
                With almost everything in the world relying on technology,
                wanting for techies, especially good coders, will never end.
                Coding is not theoretical; it’s practical. Do it to learn it.
              </h3>
            </div>
            <div className="SVG_Container">
              <div className="ScrollButton" onClick={handleScrollInto}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="80"
                  height="80"
                  viewBox="0 0 80 80"
                  fill="none"
                >
                  <path
                    className="Arrow_Down"
                    d="M41.5 24C41.5 23.1716 40.8284 22.5 40 22.5C39.1716 22.5 38.5 23.1716 38.5 24H41.5ZM38.9393 57.0607C39.5251 57.6464 40.4749 57.6464 41.0607 57.0607L50.6066 47.5147C51.1924 46.9289 51.1924 45.9792 50.6066 45.3934C50.0208 44.8076 49.0711 44.8076 48.4853 45.3934L40 53.8787L31.5147 45.3934C30.9289 44.8076 29.9792 44.8076 29.3934 45.3934C28.8076 45.9792 28.8076 46.9289 29.3934 47.5147L38.9393 57.0607ZM38.5 24L38.5 56H41.5L41.5 24H38.5Z"
                    fill="white"
                  />
                  <circle
                    className="Circle"
                    cx="40"
                    cy="40"
                    r="38.5"
                    stroke="white"
                    strokeWidth="3"
                  />
                </svg>
              </div>
            </div>
          </div>
        </section>
        <section className="Section_2">
          <h1 className="DivText1">&lt;div&gt;</h1>
          <div className="Section_Contents">
            <div className="Block_A">
              <div className="Block_A_Text" data-aos="fade-up">
                <p>
                  <span>&lt;</span>&emsp;If you can’t, don’t worry! You’re at
                  the right place at the right time.
                </p>{" "}
                <p>
                  If you want to be a coder: Learn, Implement, Learn, Implement
                  & repeat till you can code a project. Love your keyboard!
                  There is no two ways about it.
                </p>
                <p>
                  {" "}
                  It’s a dog-eat-dog world of competition in the tech world. A
                  Degree can no more guarantee you a job, but it’s about what
                  you’ve built in these years along with your graduation. In
                  tech world, what career path you’re choosing? What tech do you
                  want to be associated with? What product or project or
                  solution have you built? Can you code? This is the big
                  question you’ll be asked in every tech interview you attend.
                </p>
                <p>
                  {" "}
                  Learn coding with Codingeni with our expert developers. &emsp;
                  <span>&gt;</span>
                </p>
              </div>
              <div className="Block_A_Point">
                <h1>(A</h1>
                <p>Can you code?</p>
                <div className="LINE1"></div>
              </div>
            </div>
          </div>
        </section>
        <section className="Section_3" data-aos="fade-up">
          <div className="Section_Contents">
            <div className="Block_B">
              <div className="Block_B_Point">
                <h1>B;</h1>
                <p>Bridge the gap</p>
                <div className="LINE2"></div>
              </div>
              <div className="Block_B_Text" data-aos="fade-up">
                <p>
                  <span>&lt;</span>&emsp;There is a gap indeed between Graduated
                  and Industry Ready. Today’s industry doesn’t really bother
                  about your graduation or degree, but it’s of paramount
                  importance to them about your skillset, what you’ve built, the
                  tech you excel in, and your job readiness. With so many young
                  engineers graduating every year, to stand out of the crowd is
                  important and difficult at the same time.
                </p>{" "}
                <p>
                  While you’re studying, learn and gain the coding experience
                  bridging the gap between being a graduate to becoming job
                  ready in the field of tech. Codingeni is a part of the 18
                  years legacy of core competency in vision computing, data
                  science, IoT and Robotics which is a division of Okulr
                  Techminds.&emsp;
                  <span>&gt;</span>
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="Section_4" data-aos="fade-up">
          <div className="Section_Contents">
            <div className="Block_C">
              <div className="Block_C_Text" data-aos="fade-up">
                <p>
                  <span>&lt;</span>&emsp; With coding being the building block
                  of becoming a developer, start to code from today and not
                  while you’re heading for an interview. Learn coding from the
                  experienced coders and not a training institute with
                  faculties.
                </p>
                <p>
                  Learn, practice, develop; Learn, practice, develop and repeat
                  this over & over again to code your way up the ladder of
                  becoming the coding genius. At Codingeni, you get into the
                  fascinating and limitless world of creating something right
                  from a scratch with your coding skills. Every product you see
                  around and use such as WhatsApp, Facebook, Twitter or
                  LinkedIn, etc., is culmination of the best of code and the
                  coders who did that.
                </p>
                <p>
                  Code your way up the world of tech with Codingeni. &emsp;
                  <span>&gt;</span>
                </p>
              </div>
              <div className="Block_C_Point">
                <h1>.C</h1>
                <p>Coding your way up</p>
              </div>
            </div>
          </div>
        </section>
        <section className="Section_5">
          <div className="Section_Contents">
            <div className="Block_D">
              <div className="Block_D_Point">
                <h1>D&#123;</h1>
                <p>The confident coder</p>
              </div>
              <div className="Block_D_Text" data-aos="fade-up">
                <p>
                  <span>&lt;</span>&emsp;You’re as good as the number of lines
                  you have coded. Every line of code you write, you’re only
                  getting better. At Codingeni, this is what you do every day
                  during your term with us. We are with you from your first line
                  of coding to your first complete successful project, along
                  with our experienced coders.
                </p>{" "}
                <p>
                  The confident coder you become at Codingeni is through a
                  methodical approach to the best practices of coding in
                  whatever the tech platform you chose with us. It’s about not
                  just learning to code, but building a full scale project with
                  hundreds and thousands of lines of coding.
                </p>
                <p>
                  {" "}
                  Join us to be the Coding Genius! &emsp;
                  <span>&gt;</span>
                </p>
              </div>
            </div>
          </div>
          <h1 className="DivText2">&lt;/div&gt;</h1>
        </section>

        <section className="Section_6">
          <div className="Image_Carousel">
            <div className="Heading1">
              <h1>&#123;&nbsp;Programs&nbsp;&#125;</h1>
              <p>
                <span>&#47;&#42;</span> We have four different streams of coding
                tech you could choose from apart from the UI & UX design stream
                if you’re keen to be a good designer. All these streams lead you
                to different tech careers in your life you could excel further
                into. Remember to give your full and be passionate about which
                ever path you take up; Success will happen.
                <span>&#42;&#47;</span>
              </p>
            </div>
            <div className="Carousel_Container">
              <h3>Choose the track</h3>
              <div
                className="Carousel"
                data-aos="flip-up"
                data-aos-duration="1800"
              >
                <a href="/online-python-computer-vision-training-course">
                  <div className="BOX">
                    <div className="BOX_Container">
                      <img src={icon1} alt="Python" />
                      <h1>AI Developer</h1>
                    </div>
                  </div>
                </a>
                <a href="/online-full-stack-development-training-course">
                  <div className="BOX">
                    <div className="BOX_Container">
                      <img src={icon2} alt="Full Stack" />
                      <h1>Full Stack Developer</h1>
                    </div>
                  </div>
                </a>
                <a href="/online-ios-development-training-course">
                  <div className="BOX">
                    <div className="BOX_Container">
                      <img src={icon3} alt="IOS" />
                      <h1>iOS Developer</h1>
                    </div>
                  </div>
                </a>
                <a href="/online-android-development-training-course">
                  <div className="BOX">
                    <div className="BOX_Container">
                      <img src={icon4} alt="Android" />
                      <h1>Android Developer</h1>
                    </div>
                  </div>
                </a>

                <a href="/online-UI-UX-graphic-designing-training-course">
                  <div className="BOX">
                    <div className="BOX_Container">
                      <img src={icon5} alt="UI UX" />
                      <h1>UI/UX & Graphic Designer</h1>
                    </div>
                  </div>
                </a>
              </div>
              <div className="Buttons">
                <div className="Button_Left" onClick={handleLeft}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="80"
                    height="80"
                    viewBox="0 0 80 80"
                    fill="none"
                  >
                    <path
                      className="Arrow_Left"
                      d="M56 38.5C56.8284 38.5 57.5 39.1716 57.5 40C57.5 40.8284 56.8284 41.5 56 41.5V38.5ZM22.9393 41.0607C22.3536 40.4749 22.3536 39.5251 22.9393 38.9393L32.4853 29.3934C33.0711 28.8076 34.0208 28.8076 34.6066 29.3934C35.1924 29.9792 35.1924 30.9289 34.6066 31.5147L26.1213 40L34.6066 48.4853C35.1924 49.0711 35.1924 50.0208 34.6066 50.6066C34.0208 51.1924 33.0711 51.1924 32.4853 50.6066L22.9393 41.0607ZM56 41.5L24 41.5V38.5L56 38.5V41.5Z"
                      fill="white"
                    />
                    <circle
                      className="Circle"
                      cx="40"
                      cy="40"
                      r="38.5"
                      transform="matrix(0 -1 -1 0 80 80)"
                      stroke="white"
                      strokeWidth="3"
                    />
                  </svg>
                </div>
                <div className="Button_Right" onClick={handleRight}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="80"
                    height="80"
                    viewBox="0 0 80 80"
                    fill="none"
                  >
                    <path
                      className="Arrow_Right"
                      d="M24 38.5C23.1716 38.5 22.5 39.1716 22.5 40C22.5 40.8284 23.1716 41.5 24 41.5V38.5ZM57.0607 41.0607C57.6464 40.4749 57.6464 39.5251 57.0607 38.9393L47.5147 29.3934C46.9289 28.8076 45.9792 28.8076 45.3934 29.3934C44.8076 29.9792 44.8076 30.9289 45.3934 31.5147L53.8787 40L45.3934 48.4853C44.8076 49.0711 44.8076 50.0208 45.3934 50.6066C45.9792 51.1924 46.9289 51.1924 47.5147 50.6066L57.0607 41.0607ZM24 41.5L56 41.5V38.5L24 38.5V41.5Z"
                      fill="white"
                    />
                    <circle
                      className="Circle"
                      cx="40"
                      cy="40"
                      r="38.5"
                      transform="rotate(-90 40 40)"
                      stroke="white"
                      strokeWidth="3"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Form />
        <Footer />
      </div>
    </div>
  );
}

export default Homepage;
