import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import axios from "axios";
import image from "../images/Codingeni.svg";

import { BASE_URL } from "../constants";
import { saveAs } from "file-saver";

const SuccessPayment = () => {
  const searhQuery = useSearchParams()[0];
  const [formData, setFormData] = useState();

  const getData = async () => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/user/${searhQuery.get("reference")}`
      );

      // console.log(data);

      if (data) {
        setFormData(data);
      } else {
        alert("Something went wrong, Page not found");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleDownload = async () => {

    const res = await axios.get(`${BASE_URL}/payment/pdf`, {
      responseType: "blob",
    });
    const pdfBlob = await new Blob([res.data], { type: "application/pdf" });

    await saveAs(pdfBlob, "Codingeni_Receipt.pdf");
  };

  return (
    <div>
      <div>
        <div className="Paypage">
          <div className="P_Contents">
            <img src={image} alt="Codingeni" />
            <h1>Course Payment Recieved</h1>
            <div className="PayPreview">
              <div className="Review">
                <div className="Fee_details">
                  <h1>Transaction ID</h1>
                  <h3>:</h3>
                  <p>{formData?.orderId.split("_")[1]}</p>
                </div>
                <div className="Fee_details">
                  <h1>Date & Time</h1>
                  <h3>:</h3>
                  <p>
                    {new Date(formData?.createdAt)
                      .toString()
                      .split(" ")
                      .slice(1, 4)
                      .join(" ")}
                    &nbsp; & &nbsp;
                    {new Date(formData?.createdAt).toString().split(" ")[4]}
                  </p>
                </div>
                <div className="Fee_details">
                  <h1>
                    <strong>Transaction Amount</strong>
                  </h1>
                  <h3>:</h3>
                  <p>
                    <strong>₹53,100</strong>
                  </p>
                </div>
                <h1>
                  Thanks for your payment, your receipt is emailed to you. You
                  can also download here. Good day!
                </h1>
                <div className="Pay_Button">
                  <button
                    className="buttoN1"
                    type="button"
                    onClick={handleDownload}
                  >
                    Download Receipt
                  </button>
                </div>
              </div>
            </div>
            <div className="Image_Go">
              <a href="/">Go to Home</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessPayment;
