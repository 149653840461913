import "../../PythonLandingPages.css";
import { Helmet } from "react-helmet";
import Header from "../../../Header/Header";
import THeader from "../../../THeader/THeader";
import Form from "../../../Form/Form";
import Footer from "../../../Footer/Footer";
import image from "../../../assets/FS.svg";
import icon1 from "../../../assets/vscode.svg";
import icon2 from "../../../assets/html.svg";
import icon3 from "../../../assets/css.svg";
import icon4 from "../../../assets/js.svg";
import icon5 from "../../../assets/a.svg";
import icon6 from "../../../assets/react.svg";
import icon7 from "../../../assets/node.svg";
import icon8 from "../../../assets/express.svg";
import icon9 from "../../../assets/mongo.svg";
import icon10 from "../../../assets/sql.svg";
import image1 from "../../../assets/fullStack1.svg";
import { useRef } from "react";

const FullStackpage = () => {
  const text1 = "{ Codingeni }";
  const formRef = useRef(null);
  const handleScrollInto = () => {
    formRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div className="Main">
      <Helmet>
        <title>Full Stack Development Online Training Course in Chennai</title>
        <meta
          name="description"
          content="Codingeni offer full stack development course to enhance your knowledge and career prospects with our online training course in Chennai. Join today and invest in your success.
          "
        />
        <meta
          name="keyword"
          content="full stack developer course online in Chennai,best full stack developer course in Chennai, best full stack developer course online in Chennai, full stack online course in Chennai
          "
        />
        <link
          rel="canonical"
          href="https://www.codingeni.com/online-full-stack-development-training-course-Chennai"
        />
      </Helmet>
      <THeader />
      <Header />
      <section className="Section_1">
        <div className="Section_Contents">
          <div className="Mid_Text">
            <h1 className="gradient-animation">Bridge the _ gap;</h1>
            {/* <AnimatedText /> */}
            <h2>from graduates ------------- to job-ready</h2>
            <h3>
              With almost everything in the world relying on technology, wanting
              for techies, especially good coders, will never end. Coding is not
              theoretical; it’s practical. Do it to learn it.
            </h3>
          </div>
        </div>
      </section>
      <div className="Text_Holder section_2">
        <h2>{text1}</h2>
        <p>
          Welcome to Techready, a bridge program taking you to the career and
          industry you’re looking forward to with building some of the best and
          exciting products & projects, equipping you with real world,
          commercial product developments in artificial intelligence, deep
          learning, IoT and robotics. From training to fulltime commercial
          internships to jobs, techready gets you ready for the industry not
          just by writing on a board, but you will be working on live commercial
          projects, building disruptive products in deep learning or full stack
          or IoT embedded platform, whichever stream you choose
        </p>
        <p>
          Our approach to learning is <span>‘Build to Learn’</span> wherein
          every intern with us will be developing products in AI mentored,
          guided by our experienced developers and engineers. This gives you the
          edge to learn while to build with an unmatched experience of
          developing a full scale commercial product that’s sold to our
          customers.
        </p>
        <p>
          The difference is that you become an experienced AI developer during
          this Build to Learn Transform program with Techready and you’re
          industry ready matching the skills industry wants, thereby increasing
          the chances of you getting your dream AI job.
        </p>
      </div>
      <div className="Ai_Developement">
        <div className="Text_Holder">
          <h1>Fullstack Development</h1>
          <h3>In Chennai</h3>
          <p>
            This is the era of Front and Backend combined developers. Every
            company in IT todays recruits full stack developers for their
            products, applications, websites, IoT and more. Whether it’s a
            simple website or a complex application platform integrating
            heterogenous equipments together, full stack is the preferred one.{" "}
          </p>
          <div className="Apply_Now_button">
            <button className="Apply" onClick={handleScrollInto}>
              Click for Enquiry
            </button>
          </div>
        </div>
        <div className="Ai_Image">
          <img src={image1} alt="Ai_Develop" />
        </div>
      </div>
      <div className="Text_Holder">
        <h1>Fullstack Developer</h1>
        <p>
          Every enterprise and mission critical applications today are working
          with heterogeneous tech stack working together as full stack
          applications integrating multiple platforms, devices, and third-party
          apps together. The need for full stack applications have tremendously
          increased with these requirements. MERN stack, Angular, Node,
          PostgreSQL, Mongo, Microservices, APIs, and more are few of the
          essential tools for becoming a successful developer in the field of
          full stack. You will learn to code front end and backend making you a
          complete developer today’s companies wants. Learn from basics of
          coding to advance coding stages in full stack from experienced full
          stack developers with a track record of design, development and
          executing complex and mission critical projects.
        </p>
        <div className="Text_button_Holder">
          <h1>Few works of our Interns </h1>
          <div className="Apply_Now_button">
            <a
              href="https://www.youtube.com/playlist?list=PL6H5Bk4cZ3tDrXWaCB2aLzOmw2iyczLLl"
              target="_blank"
              rel="noreferrer"
            >
              <button className="Apply">Click Here</button>
            </a>
          </div>
        </div>
        <div className="Image_Holder_Landingpage">
          <div className="Animation_Holder_Landingpage">
            <div className="Circle_Animation_Landingpage"></div>
            <img src={image} alt="Computer Vision" />
          </div>
        </div>
      </div>
      <div className="Tools_Holder">
        <h1>Tools & Languages you will be trained on</h1>
        <div className="Tool_Container">
          <div className="Holder">
            <p>1.Visual Studio</p>
            <img src={icon1} alt="VS Code" />
          </div>
          <div className="Holder">
            <p>2.HTML</p>
            <img src={icon2} alt="HTML" />
          </div>
          <div className="Holder">
            <p>3.CSS</p>
            <img src={icon3} alt="CSS" />
          </div>
          <div className="Holder">
            <p>4.JavaScript</p>
            <img src={icon4} alt="JS" />
          </div>
          <div className="Holder">
            <p>5.Angular</p>
            <img src={icon5} alt="Angular" />
          </div>
          <div className="Holder">
            <p>6.REACT</p>
            <img src={icon6} alt="REACT" />
          </div>
          <div className="Holder">
            <p>7.NodeJS</p>
            <img src={icon7} alt="Node" />
          </div>
          <div className="Holder">
            <p>8.Express</p>
            <img src={icon8} alt="Express" />
          </div>
          <div className="Holder">
            <p>9.MongoDB</p>
            <img src={icon9} alt="Mongo" />
          </div>
          <div className="Holder">
            <p>10.PostgreSQL</p>
            <img src={icon10} alt="PostgreSQL" />
          </div>
        </div>
      </div>
      {/* <div className="Apply_button">
        <a href="/enroll">
          <button className="Apply">Enrol</button>
        </a>
      </div> */}

      <div ref={formRef} className="Form_1">
        <Form />
      </div>
      <Footer />
    </div>
  );
};

export default FullStackpage;
