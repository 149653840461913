import "./Fee.css";
import Header from "../Header/Header";
import THeader from "../THeader/THeader";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import FAQSection from "../FEE&FAQ/FAQ";
import Form from "../Form/Form";
import Footer from "../Footer/Footer";
import Animation from "../FEEFAQ_Animation/Animation";
import AnimationM from "../FEEFAQ_Animation_mobile/AnimationM";
import AOS from "aos";
import "aos/dist/aos.css";

const Feepage = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);
  return (
    <div className="Main">
      <Helmet>
        <title>Fees and Frequently Asked Questions (FAQs)</title>
        <meta
          name="description"
          content="Discover our fee structure and find answers to common questions in our FAQs section. Get all the information you need in one place.
          "
        />
        <meta
          name="keyword"
          content="Fees, FAQs, Codingeni
          "
        />
        <link rel="canonical" href="https://www.codingeni.com/Fees-and-FAQs" />
      </Helmet>
      <THeader />
      <Header />
      <div className="FEE_Section">
        <div className="How_It_Works">
          <div className="HIW_Heading">
            <h1>&#123; How it works &#125;</h1>
          </div>
          <div className="HIW_Image">
            <Animation />
          </div>
          <div className="HIW_Image_Mobile">
            <AnimationM />
          </div>
          <p>
            Knowledge is sacred, but it’s a value add too. We charge for the
            value add we bring for building up the core coding skills into you.
            You pay us for what you become – The Coder within this three months
            tenure and not for the training we do.
          </p>
        </div>
        <div className="DOP_Section">
          <div
            className="Details_Of_Program"
            data-aos="flip-up"
            data-aos-duration="2000"
          >
            <h1>/ - - - Details of the program - - - \</h1>
            <div className="Details">
              <div className="Point">
                <p>Selection process</p>
                <p>Aptitude & Quant test, Interview.</p>
              </div>
              <div className="L"></div>
              <div className="Point">
                <p>Mode of learning</p>
                <p>Developer-led live online program.</p>
              </div>
              <div className="L"></div>
              <div className="Point">
                <p>Duration</p>
                <p>03months – 144hrs, 2hrs/day Mon to Fri.</p>
              </div>
              <div className="L"></div>
              <div className="Point">
                <p>Eligibility</p>
                <p>
                  BE/BTech, ME/MTech, BCA, MCA, BSc Comp/IT, any Semester/Year.
                </p>
              </div>
              <div className="L"></div>
              <div className="Point">
                <p>Pre-requisite</p>
                <p>Laptop/Desktop with Camera & Mic, Linux/Win OS, Internet.</p>
              </div>
              <div className="L"></div>
              <div className="Point">
                <p>Fee</p>
                <p>INR 45000 + 18% GST for each program</p>
              </div>
              <div className="L"></div>
              <div className="Point">
                <p>Job Placement</p>
                <p>Upon successful completion of your training & internship , we assist you with Job placements from our network of companies we're associated with in India.</p>
              </div>
            </div>
          </div>
          {/* <div className="RefundP">
            <a href="/refund/policy" target="_blank" rel="noreferrer">
              <h6>Refund Policy</h6>
            </a>
          </div> */}
        </div>
        {/* <div className="Apply_button">
          <a href="/enroll">
            <button className="Apply">Enrol</button>
          </a>
        </div> */}
        <div className="FAQ_Section">
          <h1>&#123; FAQ &#125;</h1>
          <FAQSection />
        </div>
      </div>
      <Form />
      <Footer />
    </div>
  );
};

export default Feepage;
